import styled from '@emotion/styled';
import TopMenuArea from './TopMenuArea';
import BottomMenuArea from './BottomMenuArea';
import CustomScrollArea from '../../../_reusable/CustomScrollArea';

const NavMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  height: 100%;
  overflow: hidden;
  min-width: 240px;
  width: 240px;
  border-radius: 4px;
`;

const NavMenu = ({ project, status, onSave, onClose }) => {
  return (
    <NavMenuWrapper>
      <CustomScrollArea noHorizontal={true}>
        <TopMenuArea
          project={project}
          status={status}
          onSave={onSave}
          onClose={onClose}
        />
        <BottomMenuArea project={project} />
      </CustomScrollArea>
    </NavMenuWrapper>
  );
};

export default NavMenu;
