import styled from '@emotion/styled';
import Modal from 'react-modal';

import { ArrowLeftIcon, PlusIcon, Cross2Icon, CameraIcon, VideoIcon, TrashIcon, PlayIcon } from '@radix-ui/react-icons';
import {
  PhoneRecableCardImg,
  PhoneRecableCardMediaContainer,
  PhoneRecableNoImageText, PlayButton, VideoCard,
} from '../_reusable/PhoneRecable';
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { GlobalStateContext } from '../../providers/GlobalProvider';
import { getRecable } from '../../api/recable';
import { getPicture } from '../../api/picture';
import Masonry from 'react-masonry-css';
import Loader from '../_reusable/Loader';
import ReactPlayer from 'react-player';
import Webcam from 'react-webcam';
import { uploadBlob } from '../../api/uploadBlob';
import { createPicture, deletePicture } from '../../api/picture';
import { BACKGROUND_LIGHT, COLOR_RED, INTERACT_COLOR } from '../_reusable/theme';
import * as blobUtil from 'blob-util';
import './style.css'



const ButtonBase = styled.div`
  background-color: black;
  z-index: 7;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
  hsl(206 22% 7% / 20%) 0px 10px 20px -15px, 0 0 0 2px ${BACKGROUND_LIGHT};
`
const BackButton = styled(ButtonBase)`
  position: absolute;
  height: 45px;
  width: 45px;
  border-radius: 45px;
  top: 16px;
  left: 16px;
`

const NameContainerWrapper = styled.div`
  margin-top: -60px;
  position: relative;
  z-index: 4;
`

const NameContainer = styled.div`
  background-color: black;
  padding: 16px;
  color: white;
  letter-spacing: 1px;
  font-weight: bold;
`


const AddMediaButton = styled(ButtonBase)`
  position: fixed;
  z-index: 999;
  right: 16px;
  bottom: 16px; 
  height: 65px;
  width: 65px;
  border-radius: 65px;
`

const CloseCameraButton = styled(ButtonBase)`
  //position: absolute;
  height: 60px;
  width: 60px;
  border-radius: 60px;
  //top: 16px;
  //left: 16px;
  background-color: ${COLOR_RED};
`

const ControlContainer = styled.div`
  z-index: 10;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
const CaptureImageButton = styled(ButtonBase)`
  height: 60px;
  width: 60px;
  border-radius: 60px;
  background-color: white;
`

const DeleteButton = styled(ButtonBase)`
  height: 30px;
  z-index: 2;
  width: 30px;
  border-radius: 30px;
  background-color: red;
  color: white;
  position: absolute;
  bottom: 8px;
  left: 8px;
`

const PageContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  margin: -32px 0;
`

const SpacerBottom = styled.div`
  margin-bottom: ${({size}) => size}px;
`

function isImage(url) {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
}

const WebCamContainer = styled.div`
  position: absolute;
  top: 0;
  left: -16px; 
  right: -16px;
  bottom: 0;
  height: 100%;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  
  video {
    width: 100%
  }
`

const ActionButton = styled.div`
  position: relative;
  z-index: 99;
  padding: 8px 16px;
  background-color: ${({color}) => color};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const customModalStyles = {
  content: {
    zIndex: 1000,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'black',
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
    padding: 16
  },
};

const CAPTURE_OPTIONS = {
  audio: false,
  video: { facingMode: 'environment' },
};

const videoConstraints = {
  facingMode: 'environment'
}

Modal.setAppElement('#root');

function getSupportedMimeTypes(media, types, codecs) {
  const isSupported = MediaRecorder.isTypeSupported;
  const supported = [];
  types.forEach((type) => {
    const mimeType = `${media}/${type}`;
    codecs.forEach((codec) => [
      `${mimeType};codecs=${codec}`,
      `${mimeType};codecs=${codec.toUpperCase()}`,
      // /!\ false positive /!\
      // `${mimeType};codecs:${codec}`,
      // `${mimeType};codecs:${codec.toUpperCase()}`
    ].forEach(variation => {
      if(isSupported(variation))
        supported.push(variation);
    }));
    if (isSupported(mimeType))
      supported.push(mimeType);
  });
  return supported;
};

// Usage ------------------

const videoTypes = ["webm", "ogg", "mp4", "x-matroska"];
const codecs = ["should-not-be-supported","vp9", "vp9.0", "vp8", "vp8.0", "avc1", "av1", "h265", "h.265", "h264", "h.264", "opus", "pcm", "aac", "mpeg", "mp4a"];

const supportedVideos = getSupportedMimeTypes("video", videoTypes, codecs);

const CameraView = ({recableId, close, reload}) => {
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useContext(GlobalStateContext);
  const [blob, setBlob] = useState(null);
  const [video, setVideo] = useState(null)
  const [mediaType, setMediaType] = useState('image')
  const [capturing, setCapturing] = useState(false)
  const [recordedChunks, setRecordedChunks] = useState([]);
  const mediaRecorderRef = useRef(null);
  const webcamRef = useRef(null);
  const [error, setError] = useState(null);
  const [canStop, setCanStop] = useState('');
  const [mimeType, setMimeType] = useState('')

  useEffect(() => {
    setMimeType(getSupportedMimeTypes("video", videoTypes, codecs)[0])
  }, [])


  const capture = useCallback(
    () => {
      setMediaType('image')
      const imageSrc = webcamRef.current.getScreenshot();
      blobUtil.imgSrcToBlob(imageSrc.toString().replace(/^data:image\/jpg;base64,/, "")).then((res) => {
        setBlob(res)
      })
    },
    [webcamRef]
  );

  const handleStartCaptureClick = useCallback(() => {
    setCapturing(true);
    setMediaType('video')
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: mimeType
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const reset = useCallback(()  => {
    setBlob(null);
    setVideo(null);
    setRecordedChunks([]);
  }, [])

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
  }, [recordedChunks, mediaRecorderRef, setCapturing]);


  const loadVideo = useCallback(() => {
    if (recordedChunks.length){
      const blob = new Blob(recordedChunks, {
        type: mimeType
      });
      const url = URL.createObjectURL(blob);
      // const video = document.getElementById("video-replay");
      // video.src = url
      // console.log(video)
      setBlob(blob)
      setVideo(url)
    }
  }, [recordedChunks, capturing])

  useEffect(() => {
    loadVideo();
  }, [recordedChunks])

  useEffect(() => {
    reset();
  }, [reset])

  const uploadMedia = async () => {
    try {
      setIsLoading(true)
      const res = await uploadBlob({
        type: mediaType,
        data: blob,
        userId: user.id,
      })
      await createPicture({
        userId: user.id,
        recableId: recableId,
        picUrl: res.data.url_blob
      })
      await reload()
      setBlob(null);
      setVideo(null)
      setRecordedChunks([]);
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false);
      console.log(err)
    }
  }

  return <WebCamContainer>
    {error ? <div color={"white"}><p>Failed to get stream please reload and grant camera permission</p></div> :
     <>
       {isLoading && <LoaderContainer><Loader/></LoaderContainer>}
       {blob === null && video === null  ? (<>
         <Webcam
           audio={false}
           onUserMediaError={(err) => setError(err)}
           onUserMedia={() => {}}
           ref={webcamRef}
           screenshotFormat={'image/jpeg'}
           videoConstraints={videoConstraints}
           forceScreenshotSourceSize="true"
         />

         <ControlContainer>
           {/*<div style={{color: "white", marginRight: 10}}>*/}
           {/*  {canStop} {capturing ? 'true' : 'false'}*/}
           {/*</div>*/}
           <CloseCameraButton onClick={close}><Cross2Icon color={'white'}/></CloseCameraButton>
           <div style={{marginRight: 30}}/>
           {!capturing && <>
             <CaptureImageButton onClick={capture}><CameraIcon color={'black'} fontSize={40} fontWeight={'bold'}/></CaptureImageButton>
             <div style={{marginRight: 10}}/>
           </> }
           <CaptureImageButton onClick={() => !capturing ? handleStartCaptureClick() : handleStopCaptureClick()}>
             {!capturing ? <VideoIcon color={'black'} fontSize={40} fontWeight={'bold'}/> : <div style={{width: 10, height: 10, borderRight: 10, backgroundColor: 'red'}}/> }
           </CaptureImageButton>
         </ControlContainer>
       </>) : <>
         {mediaType === 'image' ? <img src={blobUtil.createObjectURL(blob)} style={{ maxWidth: '100%', maxHeight: '100%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }} /> :
           <ReactPlayer url={video} width={'100%'} playing={false} style={{backgroundColor: 'black', borderRadius: 5}}/>}
         <ControlContainer>
           <ActionButton onClick={uploadMedia} color={INTERACT_COLOR}>Upload</ActionButton>
           <div style={{marginRight: 10}}/>
           <ActionButton color={COLOR_RED} onClick={reset}>Cancel</ActionButton>
         </ControlContainer>
       </>}
     </>
    }
  </WebCamContainer>
}

export const PhoneRecableView = ({props, _recable=null}) => {
  const navigate = useNavigate();
  const [recable, setRecable] = useState(_recable);
  const {recableId} = useParams()
  const { user, currentLangId, tenant } = useContext(GlobalStateContext);
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingAction, setIsLoadingAction] = useState(false)
  const [error, setError] = useState(null);
  const [showCamera, setShowCamera] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedPic, setSelectedPic] = useState('')
  // const { mediaStream, permissionStatus } = useUserMedia(CAPTURE_OPTIONS);


  const loadTrainingImages = async (recable_id) => {
    return (await getPicture({
      recableId: recable_id,
    })).data
  }
  const updateTrainingImages = async () => {
    try {
      setIsLoadingAction(true)
      const trainingImages = await loadTrainingImages(recable.recable_id)
      setRecable({...recable, training: trainingImages});
      setIsLoadingAction(false)
    } catch (err) {
      console.log(err);
    }
  }
  const loadRecable = async () => {
    try {
      setIsLoading(true)
      setError(null)
      const res =  await getRecable({
        langId: currentLangId,
        userId: user.id,
        tenantId: tenant.tenant_id,
        recableId: recableId
      })
      const fetchedRecable = res.data.result
      fetchedRecable.training = await loadTrainingImages(fetchedRecable.recable_id)
      setRecable(fetchedRecable)
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      setError(err);
      console.log(err)
    }
  }

  const handleDeletePic = async () => {
    try {
      setIsLoadingAction(true)
      await deletePicture({ picId: selectedPic })
      setIsLoadingAction(false)
      setSelectedPic('')
      await updateTrainingImages();
      setShowModal(false)
    } catch (err){
      setError(err);
      setSelectedPic('');
      setIsLoadingAction(false);
      console.log(err)
    }
  }

  useEffect(() => {
    if (recable === null) {
      loadRecable();
    }
  }, [currentLangId, recable, tenant, user])

  if (isLoading) {
    return <Loader/>
  }

  return <PageContainer id={'pageContainer'}>
    <Modal
      isOpen={showModal || isLoadingAction}
      onRequestClose={() => {
        setShowModal(false);
        setSelectedPic('')
      }}
      style={customModalStyles}
      contentLabel="Example Modal"
    >
      {!isLoadingAction ? <>
          <p> Are you sure you want to delete training media</p>
          <div style={{marginBottom: 10}}/>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
            <ActionButton onClick={() => handleDeletePic()} color={INTERACT_COLOR}>Yes</ActionButton>
            <ActionButton color={COLOR_RED} onClick={() => {
            setShowModal(false);
            setSelectedPic('')
          }}>No</ActionButton>
        </div>
      </> : <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
        <Loader/>
      </div> }

    </Modal>
    {!showCamera && (<>
      <BackButton onClick={() => navigate('/')}><ArrowLeftIcon color={'white'}/></BackButton>
      {recable && (
        <>
          <PhoneRecableCardMediaContainer bigger={true}>
            {recable.urlpicture ? <PhoneRecableCardImg src={recable.urlpicture} /> :
              <PhoneRecableNoImageText>No recable cover</PhoneRecableNoImageText>}
          </PhoneRecableCardMediaContainer>
          <NameContainerWrapper>
            <NameContainer>
              <p>{recable.name}</p>
            </NameContainer>
          </NameContainerWrapper>

          <SpacerBottom size={40}/>
          <h3>Training media</h3>
          <Masonry breakpointCols={{default: 2, 450: 1}} className={'my-masonry-grid'} columnClassName={'my-masonry-grid_column'}>
            {recable.training.result.map(media => {
              if(isImage(media.pic_url)) {
                return (
                  <PhoneRecableCardMediaContainer key={media.pic_url} bigger={false} style={{backgroundColor: 'black', position: 'relative'}}>
                    <PhoneRecableCardImg src={media.pic_url}/>
                    <DeleteButton onClick={() => {
                      setSelectedPic(media.pic_id);
                      setShowModal(true);
                    }}><TrashIcon color={'white'}/></DeleteButton>
                  </PhoneRecableCardMediaContainer>
                )
              } else {
                return <div style={{position: 'relative'}} key={media.pic_id}>
                  <DeleteButton onClick={() => {
                    setSelectedPic(media.pic_id);
                    setShowModal(true);
                  }}><TrashIcon color={'white'}/></DeleteButton>
                  <VideoCard media={media}/>
                </div>
              }
            })}
          </Masonry>
        </>
      )}
      <AddMediaButton onClick={() => setShowCamera(true)}>
        <PlusIcon color={'white'} fontSize={24}/>
      </AddMediaButton>
    </>)}
    {showCamera && <CameraView recableId={recable.recable_id} close={() => setShowCamera(false)} reload={updateTrainingImages}/>}
  </PageContainer>
}

