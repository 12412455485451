import React from 'react';
import styled from '@emotion/styled';
import { COLOR, COLOR_LIGHT } from '../../../_reusable/theme';
import CustomButton from '../../../_reusable/CustomButton';
import { PlusIcon } from '@radix-ui/react-icons';
import SortCriterion from './SortCriterion';

const PopoverWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: ${COLOR};
`;

const Subtitle = styled.div`
  font-size: 12px;
  color: ${COLOR_LIGHT};
  width: 100%;
`;

const FilterAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 16px;
  padding-bottom: 16px;
`;

const SortPopover = React.forwardRef((props, ref) => {
  return (
    <PopoverWrapper ref={ref} {...props}>
      <Title>Sort</Title>
      <Subtitle>
        Sort article table. Multiple sort criteria will add
        up in effect from top to bottom.
      </Subtitle>
      <FilterAreaWrapper>
        <SortCriterion />
      </FilterAreaWrapper>
      <CustomButton width="88px">
        <PlusIcon />
        New sort
      </CustomButton>
    </PopoverWrapper>
  );
});

export default SortPopover;
