import styled from '@emotion/styled';
import { Cross1Icon, TrashIcon } from '@radix-ui/react-icons';
import React from 'react';
import { useContext } from 'react';
import { deleteRecable, getRecable } from '../../../../api/recable';
import { GlobalStateContext } from '../../../../providers/GlobalProvider';
import {
  projectArticles,
  ProjectDispatchContext,
  projectError,
  ProjectStateContext,
  updateEditMode,
} from '../../../../providers/ProjectProvider';
import CustomButton from '../../../_reusable/CustomButton';
import CustomDialog, { DialogClose } from '../../../_reusable/CustomDialog';
import { TextSubSection } from '../../../_reusable/styles';
import { INTERACT_COLOR, COLOR } from '../../../_reusable/theme';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${COLOR}
  font-size: 14px;
  gap: 16px;
`;

const TextArea = styled.div`
  margin-bottom: 32px;
  line-height: 1.5;
  white-space: wrap;
`;

const WrapperHorizontal = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
`;


const Trigger = React.forwardRef(
  ({ editMode, editModeGetRecableIds, ...props }, ref) => {
    const articleCount = editModeGetRecableIds.length;
    const buttonStyle = (() => {
      if (editMode)
        return {
          color: INTERACT_COLOR,
          background: 'rgba(46, 170, 220, 0.1)',
          hoverBackground: 'rgba(46, 170, 220, 0.2)',
          hoverColor: INTERACT_COLOR,
        };
      return {};
    })();

    return (
      <CustomButton {...buttonStyle} {...props} ref={ref}>
        <TrashIcon /> Delete {articleCount} article
        {articleCount > 1 ? 's' : ''}
      </CustomButton>
    );
  }
);

const Content = ({ editModeGetRecableIds }) => {
  const dispatch = useContext(ProjectDispatchContext);
  const articleCount = editModeGetRecableIds.length;
  const { currentLangId } = useContext(GlobalStateContext);
  const { id: projectId } = useContext(ProjectStateContext);

  const handleConfirmDeleteRecable = () => {
    const promises = [];
    for (const id of editModeGetRecableIds) {
      const promise = deleteRecable({
        recableId: id,
        projectId,
      });

      promises.push(promise);
    }

    Promise.all(promises)
      .then(() => {
        dispatch(updateEditMode(false));
      })
      .then(() => {
        return getRecable({
          langId: currentLangId,
          projectId,
        });
      })
      .then((res) => {
        dispatch(projectArticles(res.data.result));
      })
      .catch((error) => {
        dispatch(projectError(error));
      });
  };

  return (
    <ContentWrapper>
      <TextArea>
        You are about to delete{' '}
        <b>
          {articleCount} article{articleCount > 1 ? 's' : ''}
        </b>
        , this action cannot be undone! Press <TextSubSection>Delete</TextSubSection> to
        confirm.
      </TextArea>
      <WrapperHorizontal>
        <span>
          <CustomButton onClick={handleConfirmDeleteRecable}>
            <TrashIcon /> Delete
          </CustomButton>
        </span>
        <span>
          <DialogClose asChild>
            <CustomButton>
              <Cross1Icon /> Cancel
            </CustomButton>
          </DialogClose>
        </span>
      </WrapperHorizontal>
    </ContentWrapper>
  );
};

const DeleteArticles = () => {
  const { editMode, editModeGetRecableIds } =
    useContext(ProjectStateContext);

  return (
    <CustomDialog
      triggerElement={
        <Trigger
          editMode={editMode}
          editModeGetRecableIds={editModeGetRecableIds}
        />
      }
      contentElement={
        <Content editModeGetRecableIds={editModeGetRecableIds} />
      }
    />
  );
};

export default DeleteArticles;
