import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LogRocket from 'logrocket';
import styled from '@emotion/styled';
import ProjectBoard from './components/ProjectBoard';
import Project from './components/Project';
import ArticleProvider from './providers/ArticleProvider';
import GlobalProvider from './providers/GlobalProvider';
import SignIn from './components/Signin';
import ProtectedLayout from './routes/ProtectedLayout';
import TenantAdmin from './components/TenantAdmin';
import { useEffect, useState } from 'react';
import { INTERACT_BACKGROUND } from './components/_reusable/theme';
import { PhoneRecableList } from './components/PhoneRecableList';
import { PhoneRecableView } from './components/PhoneRecableView';

const AppWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;


const PhoneAppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
`

const PhoneAppContainer = styled.div`
  height: 100vh;
  padding: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const UnsupportedPageContent = styled.div`
  padding: 16px;
  border-radius: 8px;
  background-color: ${INTERACT_BACKGROUND};
`

LogRocket.init('5ssqo8/shaire-cockpit');

function App() {

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  return (
    <AppWrapper className="App">
      <GlobalProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/signin" element={<SignIn />} />
            <Route element={<ProtectedLayout showNav={windowSize.innerWidth > 600} />}>
              <Route path="/" element={windowSize.innerWidth > 600 ? <ProjectBoard /> : <PhoneRecableList />} />
              <Route
                path="project/:projectID"
                element={windowSize.innerWidth > 600 ?
                  <ArticleProvider>
                    <Project />
                  </ArticleProvider> : <PhoneAppWrapper>
                    <PhoneAppContainer>
                      <UnsupportedPageContent>
                        <p>Unsupported feature for phone size</p>
                      </UnsupportedPageContent>
                    </PhoneAppContainer>
                  </PhoneAppWrapper>
                }
              />
              <Route
                path='recable/:recableId'
                element={windowSize.innerWidth <= 600 ? <PhoneRecableView /> : <PhoneAppWrapper>
                  <PhoneAppContainer>
                    <UnsupportedPageContent>
                      <p>Unsupported feature for phone size</p>
                    </UnsupportedPageContent>
                  </PhoneAppContainer>
                </PhoneAppWrapper>}
              />
              <Route path="tenant" element={windowSize.innerWidth > 600 ? <TenantAdmin /> : <PhoneAppWrapper>
                <PhoneAppContainer>
                  <p>Unsupported feature for phone size</p>
                </PhoneAppContainer>
              </PhoneAppWrapper>} />
            </Route>
          </Routes>
        </BrowserRouter>
      </GlobalProvider>
    </AppWrapper>
  );
}

export default App;
