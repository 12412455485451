import React, { useContext, useEffect, useState } from 'react';
import { GlobalStateContext } from '../../../providers/GlobalProvider';
import { getRecable } from '../../../api/recable';
import { getPicture } from '../../../api/picture';
import TrainingSampleCard from './TrainingSampleCard';
import CustomButton from '../../_reusable/CustomButton';
import { ArrowDownIcon, ArrowUpIcon, EyeClosedIcon, EyeOpenIcon } from '@radix-ui/react-icons';
import { BACKGROUND_GREEN, BACKGROUND_LIGHT, COLOR_LIGHT, COLOR_GREEN } from '../../_reusable/theme';
import { WrapperHorizontalCustom, PageArea, TextSubSection, WrapperVerticalLeft } from '../../_reusable/styles';

const TrainingSampleBoard = () => {

  const { tenant, currentLangId } = useContext(GlobalStateContext)

  const [isVisible, setIsVisible] = useState(false)
  const [status, setStatus] = useState('init')
  const [isShowArticleWithoutTrainingMedia, setIsShowArticleWithoutTrainingMedia] = useState(false)

  const [listArticleAllTenant, setListArticleAllTenant] = useState([])
  const [objTrainingSample, setObjTrainingSample] = useState({})

  useEffect(() => {
    if (tenant?.tenant_id && ['init', 'loading'].includes(status)) {
      getRecable({ tenantId: tenant.tenant_id, langId: currentLangId })
        .then((resRecable) => {
          setListArticleAllTenant(resRecable.data.result);
          setStatus('loaded');
        })
        .catch((error) => {
          console.error(error);
          setStatus('error');
        });
    }
  }, [tenant, currentLangId, status])

  useEffect(() => {
    var objTrainingSampleTemp = {};
    var counter = 0;
    if (tenant?.tenant_id) {
      listArticleAllTenant.forEach(itemArticle => {
        getPicture({ recableId: itemArticle.recable_id })
          .then((resPictures) => {
            objTrainingSampleTemp[itemArticle.recable_id] = resPictures.data.result;
            counter++;
            if (counter === listArticleAllTenant.length)
              setObjTrainingSample(objTrainingSampleTemp);
          })
          .catch((error) => console.error(error));
      })
    }
  }, [listArticleAllTenant, setListArticleAllTenant, tenant])

  return (
    <PageArea>
      <TextSubSection>Training Image Board</TextSubSection>

      <WrapperHorizontalCustom>
        <CustomButton
          background={isVisible ? BACKGROUND_GREEN : BACKGROUND_LIGHT}
          color={isVisible ? COLOR_GREEN : COLOR_LIGHT}
          onClick={() => { setIsVisible(!isVisible) }}>
          {isVisible ? <ArrowUpIcon /> : <ArrowDownIcon />}Toggle Training Media Board
        </CustomButton>
        {isVisible &&
          <CustomButton
            background={isShowArticleWithoutTrainingMedia ? BACKGROUND_GREEN : BACKGROUND_LIGHT}
            color={isShowArticleWithoutTrainingMedia ? COLOR_GREEN : COLOR_LIGHT}
            onClick={() => { setIsShowArticleWithoutTrainingMedia(!isShowArticleWithoutTrainingMedia) }}>
            {isShowArticleWithoutTrainingMedia ? <EyeOpenIcon /> : <EyeClosedIcon />}Toggle Articles w/o Training Media
          </CustomButton>
        }
      </WrapperHorizontalCustom>

      {isVisible && listArticleAllTenant && objTrainingSample &&
        <WrapperVerticalLeft>
          {listArticleAllTenant.map((itemArticle) => {
            return (
              Object.keys(objTrainingSample).includes(`${itemArticle.recable_id}`) &&
              (isShowArticleWithoutTrainingMedia || objTrainingSample[itemArticle.recable_id].length > 0) &&
              <TrainingSampleCard
                article={itemArticle}
                trainingSample={objTrainingSample[itemArticle.recable_id]}
                setStatus={setStatus}
                key={itemArticle.recable_id} />
            );
          })}
        </WrapperVerticalLeft>
      }

    </PageArea>
  );
};

export default TrainingSampleBoard;
