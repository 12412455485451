import CustomDialog from '../../../../_reusable/CustomDialog';
import SynthButton from './SynthButton';
import SynthDialog from './SynthDialog';

const Synthesization = ({ project }) => {
  return (
    <CustomDialog
      triggerElement={<SynthButton />}
      contentElement={<SynthDialog project={project} />}
      size="100"
    />
  );
};

export default Synthesization;
