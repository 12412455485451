import { get, post } from './dataSource';

const URL_BASE_LANGUAGE = 'language';
const URL_BASE_TRANSLATE = 'translate';

export const getLanguages = async () => {
  return await get(URL_BASE_LANGUAGE);
};

export const translateArticle = async ({
  projectId,
  recableId,
  langId,
  langTargetId,
  userId,
}) => {
  return await post(
    URL_BASE_TRANSLATE,
    {},
    {
      project_id: projectId,
      recable_id: recableId,
      lang_id: langId,
      lang_target_id: langTargetId,
      user_id: userId,
    }
  );
};
