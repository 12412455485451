import React from 'react';
import { styled } from '@stitches/react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { BACKGROUND, COLOR, COLOR_LIGHT, INTERACT_COLOR } from './theme';

const StyledCheckbox = styled(CheckboxPrimitive.Root, {
  all: 'unset',
  backgroundColor: 'white',
  width: 16,
  height: 16,
  borderRadius: 4,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: `0 2px 10px ${BACKGROUND}`,
  border: `1px solid rgba(55, 53, 47, 0.2)`,
  '&:hover': { cursor: 'pointer' },
  '&:focus': { boxShadow: `0 0 0 2px ${COLOR_LIGHT}` },
  variants: {
    checked: {
      true: {
        backgroundColor: INTERACT_COLOR,
        border: `1px solid ${INTERACT_COLOR}`,
      },
    },
  },
});

const StyledIndicator = styled(CheckboxPrimitive.Indicator, {
  color: COLOR,
});

export const Checkbox = StyledCheckbox;
export const CheckboxIndicator = StyledIndicator;

// Your app...
const Flex = styled('div', {
  display: 'inline-flex',
  alignContent: 'center',
  alignItems: 'center',
});

const Label = styled('label', {
  color: COLOR,
  fontSize: 14,
  userSelect: 'none',
  paddingLeft: 10,
  lineHeight: 1.15,
  cursor: 'pointer',
});

export const CustomCheckbox = React.forwardRef(
  (
    {
      id,
      isChecked,
      onCheckChange,
      onClick,
      label,
      checkboxReplacementComponent,
      ...props
    },
    ref
  ) => {
    const handleCheckChange = (value) => {
      onCheckChange({ key: id, value });
    };

    return (
      <Flex {...props} ref={ref}>
        {checkboxReplacementComponent || (
          <Checkbox
            id={id}
            checked={isChecked}
            onCheckedChange={handleCheckChange}
          />
        )}
        {label && <Label htmlFor={id}>{label}</Label>}
      </Flex>
    );
  }
);

export default CustomCheckbox;
