import styled from '@emotion/styled';
import moment from 'moment';

import { useState } from 'react';
import { Pencil1Icon, TrashIcon } from '@radix-ui/react-icons';

import {
    COLOR,
    BACKGROUND_RED,
    HOVER_BACKGROUND_RED,
    COLOR_RED,
} from '../../_reusable/theme';
import CustomButton from '../../_reusable/CustomButton';

import PhonemeCreate from './PhonemeCreate';
import { deletePhoneme } from '../../../api/phoneme';

import { WrapperHorizontal, CardWrapper, WrapperVerticalLeft, WrapperVerticalAdmin, WrapperHorizontalCustom } from '../../_reusable/styles';

const TextSection = styled.div`
  color: ${COLOR};
  font-weight: 600;
  padding-bottom: 8px;
`;

const TextNormal = styled.div`
  color: ${COLOR};
  font-weight: 400;
`;

const PhonemeCard = ({ phoneme, setIsUpdateListPhoneme }) => {

    const getPrettifiedMoment = (date) => {
        return moment(
            date,
            moment.ISO_8601,
            true
        ).format('D MMMM, YYYY hh:ss');
    }

    const [isEdit, setIsEdit] = useState(false);

    const handleDeletePhoneme = () => {
        deletePhoneme({
            phonemeId: phoneme.phoneme_id
        }).then(() => {
            setIsEdit(false);
            setIsUpdateListPhoneme(true);
        }).catch((error) => {
            console.error(error);
        });
    }

    return (
        <CardWrapper id={phoneme.phoneme_id}>
            <WrapperHorizontal>
                <WrapperVerticalLeft>
                    <TextSection
                        contentEditable={false}
                        id="phonemeTextOriginal"
                    >
                        {`Text: ${phoneme.phoneme_text_original}`}
                    </TextSection>
                    <TextNormal
                        contentEditable={false}
                        id="model_training_status_id"
                    >
                        {`Phoneme: ${phoneme.phoneme_text_phoneme}`}
                    </TextNormal>
                    <WrapperHorizontalCustom>
                        <CustomButton
                            width='75px'
                            onClick={() => { setIsEdit(true) }}
                            disable={true}>
                            {<><Pencil1Icon />Edit</>}
                        </CustomButton>
                        <CustomButton
                            background={BACKGROUND_RED}
                            hover_background={HOVER_BACKGROUND_RED}
                            color={COLOR_RED}
                            width='75px'
                            onClick={() => { handleDeletePhoneme() }}
                            disable={true}>
                            {<><TrashIcon /><p>Delete</p></>}
                        </CustomButton>
                    </WrapperHorizontalCustom>
                </WrapperVerticalLeft>
                <WrapperVerticalAdmin>
                    <div>Created at: {getPrettifiedMoment(phoneme.createdat)}</div>
                    <div>Last modified at: {getPrettifiedMoment(phoneme.lastmodifiedat)}</div>
                </WrapperVerticalAdmin>
            </WrapperHorizontal>
            {isEdit &&
                <PhonemeCreate
                    setIsMode={setIsEdit}
                    isModeCreate={false}
                    setIsUpdateListPhoneme={setIsUpdateListPhoneme}
                    phonemeId={phoneme.phoneme_id}
                    phonemeTextOriginalInitial={phoneme.phoneme_text_original}
                    phonemeTextPhonemeInitial={phoneme.phoneme_text_phoneme}
                />
            }
        </CardWrapper >
    );
};

export default PhonemeCard;
