import { SpeakerQuietIcon } from '@radix-ui/react-icons';
import React from 'react';
import CustomButton from '../../../../_reusable/CustomButton';

const SynthButton = React.forwardRef((props, ref) => {
  return (
    <CustomButton ref={ref} {...props}>
      <SpeakerQuietIcon />
      Text to speech
    </CustomButton>
  );
});

export default SynthButton;
