import { useState, useContext } from 'react';
import { CheckIcon, EyeClosedIcon, EyeOpenIcon, RocketIcon } from '@radix-ui/react-icons';

import { GlobalStateContext } from '../../../providers/GlobalProvider';

import moment from 'moment';
import {
  BACKGROUND_GREEN,
  HOVER_BACKGROUND_GREEN,
} from '../../_reusable/theme';
import CustomButton from '../../_reusable/CustomButton';
import {
  WrapperHorizontalCustom,
  WrapperHorizontal,
  CardWrapper,
  TextNormal,
  TextSection,
  WrapperVerticalAdmin,
  WrapperVerticalLeft,
} from '../../_reusable/styles';
import { updateModel } from '../../../api/model';

const ModelCard = ({ model, setStatus }) => {

  const prettifiedMoment = moment(
    model.createdat,
    moment.ISO_8601,
    true
  ).format('D MMMM, YYYY hh:ss');

  const [isVisibleLog, setIsVisibleLog] = useState(false);

  const { tenant, user } = useContext(GlobalStateContext);

  const interpretTrainingStatus = (status_id) => {
    switch (status_id) {
      case 0:
        return 'Currently not in training';
      case 1:
        return 'Training ongoing';
      case 2:
        return 'Training successfully completed';
      default:
        return 'Cannot interpret training status';
    }
  }

  const interpretErrorStatus = (status_id) => {
    switch (status_id) {
      case 0:
        return 'No error encountered yet';
      case 1:
        return 'Stopped due to error';
      default:
        return 'Cannot interpret error status';
    }
  }

  const handleUpdateModel = () => {
    updateModel({ userId: user.id, modelId: model.model_id, tenantId: tenant.tenant_id })
      .then(() => { setStatus('loading') })
      .catch((error) => console.error(error));
  }

  return (
    <CardWrapper
      id={model.model_id}
    >
      <WrapperHorizontal>
        <WrapperVerticalLeft>
          <TextSection
            contentEditable={false}
            id="model_base"
          >
            {`Model ID: ${model.model_base}`}
          </TextSection>
          <TextNormal
            contentEditable={false}
            id="model_training_status_id"
          >
            {interpretTrainingStatus(model.model_training_status_id)}
          </TextNormal>
          <TextNormal
            contentEditable={false}
            id="model_error_status_id"
          >
            {interpretErrorStatus(model.model_error_status_id)}
          </TextNormal>
          <WrapperHorizontalCustom>
            <CustomButton
              onClick={() => { setIsVisibleLog(!isVisibleLog) }}
              disable={true}>
              {isVisibleLog ? <><EyeClosedIcon />Hide logs</> : <><EyeOpenIcon />Show logs</>}
            </CustomButton>
            {(!model.model_error_status_id) &&
              <CustomButton
                background={model.model_is_active ? BACKGROUND_GREEN : undefined}
                hover_background={model.model_is_active ? HOVER_BACKGROUND_GREEN : undefined}
                onClick={() => { handleUpdateModel(); }}
                disable={true}>
                {model.model_is_active ? <><CheckIcon />Activated</> : <><RocketIcon />Reactivate model</>}
              </CustomButton>
            }
          </WrapperHorizontalCustom>
          {isVisibleLog && JSON.parse(model.model_log).map((model_log) => {
            return (<>
              <TextNormal>
                <b>{Object.keys(model_log)[0]}: </b>
                {model_log[Object.keys(model_log)[0]]}
              </TextNormal>
            </>)
          })}
        </WrapperVerticalLeft>
        <WrapperVerticalAdmin>
          <div>{model.createdby_user_name}</div>
          <div>{prettifiedMoment}</div>
        </WrapperVerticalAdmin>
      </WrapperHorizontal>
    </CardWrapper >
  );
};

export default ModelCard;
