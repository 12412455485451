import CustomPopover from '../../../_reusable/CustomPopover';
import FilterButton from './FilterButton';
import FilterPopover from './FilterPopover';

const Filter = ({ options }) => {
  return (
    <CustomPopover
      triggerElement={<FilterButton />}
      contentElement={<FilterPopover options={options} />}
      hasCloseButton
    />
  );
};

export default Filter;
