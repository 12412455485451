import CustomPopover from '../../../_reusable/CustomPopover';
import CreateArticleDropdownTrigger from './CreateArticleDropdownTrigger';
import CreateArticleDropdownContent from './CreateArticleDropdownContent';
import CustomDialog from '../../../_reusable/CustomDialog';
import Article from '../../../Article';
import { useState } from 'react';
import { useContext } from 'react';
import {
  ArticleDispatchContext,
  isNewArticle,
} from '../../../../providers/ArticleProvider';

const CreateArticle = () => {
  const [open, setOpen] = useState(false);
  const articleDispatch = useContext(ArticleDispatchContext);
  const handleAddNewArticle = (event) => {
    setOpen(true);
    articleDispatch(isNewArticle(event.target.id));
  };

  const handleCloseArticle = () => {
    setOpen(false);
  };

  return (
    <>
      <CustomPopover
        side="bottom"
        align="start"
        triggerElement={<CreateArticleDropdownTrigger />}
        contentElement={
          <CreateArticleDropdownContent onAddNewArticle={handleAddNewArticle} />
        }
      />

      <CustomDialog
        open={open}
        onClose={handleCloseArticle}
        size="100"
        contentElement={<Article onClose={handleCloseArticle} />}
      />
    </>
  );
};

export default CreateArticle;
