import { useContext } from 'react';
import { ArticleStateContext } from '../../../../providers/ArticleProvider';
import { GlobalStateContext } from '../../../../providers/GlobalProvider';
import { ProjectStateContext } from '../../../../providers/ProjectProvider';
import CustomWebappLink from '../../../_reusable/CustomWebappLink';

const WebAppLink = () => {
  const { id: projectId } = useContext(ProjectStateContext);
  const { tenant, currentLangId } = useContext(GlobalStateContext);
  const { article } = useContext(ArticleStateContext);
  if (!article?.recable_id) return null;
  const webAppLink = `${tenant.tenant_domain}/${currentLangId}/project/${projectId}/recable/${article.recable_id}/recableType/${article.recable_type_name}`;

  return (
    <CustomWebappLink webAppLink={webAppLink} />
  );
};

export default WebAppLink;
