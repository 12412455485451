import styled from '@emotion/styled';
import { BACKGROUND, COLOR, HOVER_BACKGROUND } from './theme';

const CustomInput = styled.input`
  all: unset;
  display: flex;
  flex-grow: 1;
  background: ${(props) => props.background || BACKGROUND};
  padding-left: 12px;
  padding-right: 12px;
  transition: all 0.06s;
  color: ${(props) => props.color || COLOR};
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  height: 32px;
  min-width: 12px;
  type: text;
  width: ${(props) => props.width};
  &:hover {
    background: ${HOVER_BACKGROUND};
  }
`;

export default CustomInput;
