import { useContext } from 'react';
import { GlobalStateContext } from '../../providers/GlobalProvider';
import CustomWebappLink from '../_reusable/CustomWebappLink';

const WebAppLinkProject = ({ projectId }) => {
    const { tenant, currentLangId } = useContext(GlobalStateContext);
    const webAppLink = `${tenant.tenant_domain}/${currentLangId}/project/${projectId}`;

    return <CustomWebappLink webAppLink={webAppLink} />;
};

export default WebAppLinkProject;
