import styled from '@emotion/styled';
import { PersonIcon, BookmarkIcon, StarIcon } from '@radix-ui/react-icons';

const CellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ArticleTypeIconCell = ({ cell, row }) => {
  if (!cell.value) return null;
  const icon = (() => {
    switch (cell.value) {
      case 'topic':
        return <BookmarkIcon />;
      case 'artwork':
        return <StarIcon />;
      case 'creator':
        return <PersonIcon />;
      default:
        return null;
    }
  })();

  return (
    <CellWrapper>
      {icon}
      {row.original.recable_type_lang_name}
    </CellWrapper>
  );
};

export default ArticleTypeIconCell;
