import { post } from './dataSource';

const URL_BASE_TRANSLATE_PROJECT = 'translate_project';
const URL_BASE_SYNTHESIZE_PROJECT = 'synthesize_project';

export const translateProject = async ({
  projectId,
  langId,
  langTargetId,
  userId,
}) => {
  return await post(
    URL_BASE_TRANSLATE_PROJECT,
    {},
    {
      project_id: projectId,
      lang_id: langId,
      lang_target_id: langTargetId,
      user_id: userId,
    }
  );
};

export const synthesizeProject = async ({ projectId, langId, langTargetId, tenantId }) => {
  return await post(
    URL_BASE_SYNTHESIZE_PROJECT,
    {},
    { project_id: projectId, lang_id: langId, lang_target_id: langTargetId, tenant_id: tenantId }
  );
};