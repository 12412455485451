import styled from '@emotion/styled';
import { PauseIcon, PlayIcon, TrashIcon } from '@radix-ui/react-icons';
import { INTERACT_COLOR } from './theme';
import ReactPlayer from 'react-player';
import { useRef, useState } from 'react';

export const PhoneRecableCardWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
`

export const PhoneRecableCardMediaContainer = styled.div`
  min-height: 200px;
  max-height: ${({bigger}) => bigger ? '440px' : '330px'};
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: black;
  text-align: center;
  border-radius: 5px;
  overflow: hidden;
`

export const PhoneRecableCardImg = styled.img`
  max-width: 100%;
  max-height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  background-position: center;
`

export const PlayButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: black;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const PhoneRecableCarTrainingStatus = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: ${({active}) => active ? 'blue' : 'orange'};
  color: white;
  padding: 4px 8px;
  font-weight: bold;
  letter-spacing: 1px;
  border-radius: 30px;
  font-size: 10px;
`

export const PhoneRecableNoImageText = styled.p`
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: white
`

export const PhoneRecableNameContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 16px;
  color: white;
  z-index: 20;
`

export const PhoneRecableCardName = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 80px;
  max-width: 100%;
  margin: 0 auto;
`

export const PhoneRecableCard = ({recable, ...restProps}) => {
  return <PhoneRecableCardWrapper {...restProps}>
    <PhoneRecableCardMediaContainer bigger={false}>
      {recable.urlpicture ? <PhoneRecableCardImg src={recable.urlpicture}/> : <PhoneRecableNoImageText>No recable cover</PhoneRecableNoImageText>}
    </PhoneRecableCardMediaContainer>
    <PhoneRecableCarTrainingStatus active={recable.training.result.length > 0}>
      {recable.training.result.length} training media
    </PhoneRecableCarTrainingStatus>
    <PhoneRecableNameContainer>
      <PhoneRecableCardName>{recable.name}</PhoneRecableCardName>
    </PhoneRecableNameContainer>
  </PhoneRecableCardWrapper>
}

export const VideoCard = ({media}) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayClick = () => {
    setIsPlaying(old => !old);
  }


  return <div style={{position: 'relative'}} key={media.pic_url}>
    <PlayButton onClick={() => handlePlayClick()}>
      {isPlaying ? <PauseIcon color={INTERACT_COLOR}/> : <PlayIcon color={INTERACT_COLOR} />}
    </PlayButton>
    <ReactPlayer
      stopOnUnmount={true} pip={true} url={media.pic_url}
      key={media.pic_url} width={'100%'} loop={false}
      onStart={() => setIsPlaying(true)}
      onPause={() => setIsPlaying(false)}
      playing={isPlaying}
      style={{backgroundColor: 'black', borderRadius: 5}}/>
  </div>
}
