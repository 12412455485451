import CustomButton from '../../../_reusable/CustomButton';
import { PauseIcon, PlayIcon } from '@radix-ui/react-icons';
import { useState, useEffect } from 'react';

export default function AudioButton({ media }) {
  const player1 = media ? (
    <audio id="player" controls style={{display: 'none'}}>
      <source src={media} type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
  ) : null;

  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const player = document.getElementById('player');
    if (isPlaying) {
      player.play();
    } else {
      player.pause();
    }
  }, [isPlaying]);

  const handlePlayToggle = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <span>
      {player1}
      <CustomButton onClick={handlePlayToggle}>
        {isPlaying ? <PauseIcon /> : <PlayIcon />}
      </CustomButton>
    </span>
  );
}
