import React, { useContext } from 'react';
import styled from '@emotion/styled';
import {
  ImageIcon,
  PlusIcon,
  TextAlignLeftIcon,
  VideoIcon,
  SpeakerQuietIcon,
  LetterCaseCapitalizeIcon,
  FileTextIcon,
  CheckIcon,
} from '@radix-ui/react-icons';
import CustomButton from '../../../_reusable/CustomButton';
import  {
  DropdownMenuCheckboxItem,
  DropdownMenuItemIndicator,
  RightSlot,
} from '../../../_reusable/CustomDropdownMenu';
import {
  ArticleDispatchContext,
  ArticleStateContext,
  toggleProperty,
} from '../../../../providers/ArticleProvider';
import CustomScrollArea from '../../../_reusable/CustomScrollArea';
import {
  HOVER_BACKGROUND_INTERACT,
  INTERACT_BACKGROUND,
  INTERACT_COLOR,
} from '../../../_reusable/theme';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

const PROPERTY_ICONS = {
  text_standard: <LetterCaseCapitalizeIcon />,
  text_rich: <TextAlignLeftIcon />,
  text_interview: <FileTextIcon />,
  file_picture: <ImageIcon />,
  file_video: <VideoIcon />,
  file_audio: <SpeakerQuietIcon />,
};

const NewPropertyPopoverWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 200px;
  gap: 8px;
  width: 230px;
  margin-left: -5px;
  background: white;
  z-index: 8;
  position: relative;
`;

const Trigger = React.forwardRef((props, ref) => {
  return (
    <CustomButton
      ref={ref}
      {...props}
      color={INTERACT_COLOR}
      hoverColor={INTERACT_COLOR}
      background={INTERACT_BACKGROUND}
      hoverBackground={HOVER_BACKGROUND_INTERACT}
    >
      <PlusIcon />
      New property
    </CustomButton>
  );
});

const Content = React.forwardRef((props, ref) => {
  const dispatch = useContext(ArticleDispatchContext);
  const {
    currentProperties,
    availableProperties,
  } = useContext(ArticleStateContext);

  const handleToggleProperty = (event) => {
    const targetPropertyKeyCodename = event.target.id;
    dispatch(toggleProperty(targetPropertyKeyCodename));
  };

  return (
    <NewPropertyPopoverWrapper ref={ref} {...props}>
      <CustomScrollArea>
        {availableProperties.map((availableProp) => (
          <DropdownMenuCheckboxItem
            id={availableProp.recable_attribute_lang_name}
            key={availableProp.recable_attribute_lang_name}
            checked={
              currentProperties.findIndex(
                (currentProp) =>
                  currentProp.recable_attribute_lang_name ===
                  availableProp.recable_attribute_lang_name
              ) >= 0
            }
            // TODO: enable this
            // disabled={property.recable_attribute_is_obligatory}
            onSelect={handleToggleProperty}
          >
            <DropdownMenuItemIndicator>
              <CheckIcon />
            </DropdownMenuItemIndicator>
            {availableProp.recable_attribute_lang_name}
            <RightSlot>
              {PROPERTY_ICONS[availableProp.recable_attribute_field_type]}
            </RightSlot>
          </DropdownMenuCheckboxItem>
        ))}
      </CustomScrollArea>
    </NewPropertyPopoverWrapper>
  );
});

const NewProperty = () => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <Trigger/>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content className="DropdownMenuContent" sideOffset={5} side={"top"} alignOffset={"start"}>
          <Content/>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};



export default NewProperty;
