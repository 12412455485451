
export const SIZE_GAP_SMALL = '4px';
export const SIZE_GAP_MEDIUM = '8px';
export const SIZE_GAP_LARGE = '16px';
export const SIZE_PADDING_SMALL = SIZE_GAP_SMALL;
export const SIZE_PADDING_MEDIUM = SIZE_GAP_MEDIUM;
export const SIZE_PADDING_LARGE = SIZE_GAP_LARGE;
export const SIZE_MARGIN_SMALL = SIZE_PADDING_SMALL;
export const SIZE_MARGIN_MEDIUM = SIZE_PADDING_MEDIUM;
export const SIZE_MARGIN_LARGE = SIZE_PADDING_LARGE;
export const SIZE_FONT_SMALL = '12';
export const SIZE_FONT_MEDIUM = '14';
export const SIZE_FONT_LARGE = '18';
export const SIZE_FONT_WEIGHT_SMALL = '400';
export const SIZE_FONT_WEIGHT_MEDIUM = '600';
export const SIZE_FONT_WEIGHT_LARGE = '800';
export const SIZE_BORDER_RADIUS = '4px'

export const HOVER_BACKGROUND = 'rgba(55,53,47,0.08)';
export const BACKGROUND = 'rgba(55,53,47,0.04)';
export const BACKGROUND_LIGHT = 'rgba(55,53,47,0.02)';
export const COLOR = 'rgb(55,53,47)';
export const COLOR_LIGHT = 'rgba(55,53,47, 0.6)';
export const COLOR_LIGHT_2 = 'rgba(55,53,47,0.2)';
export const COLOR_LIGHT_3 = 'rgba(55,53,47,0.1)';
export const BORDER_COLOR = 'rgba(55,53,47,0.1)';
export const SHAIRE_COLOR = 'rgb(165, 208, 227)';

export const INTERACT_COLOR = 'rgb(46, 170, 220)';
export const INTERACT_BACKGROUND = 'rgba(46, 170, 220, 0.1)';
export const HOVER_BACKGROUND_INTERACT = 'rgba(46, 170, 220, 0.2)';

export const BACKGROUND_GREEN = 'rgb(219, 237, 219)';
export const HOVER_BACKGROUND_GREEN = 'rgba(219, 237, 219, 0.5)';
export const COLOR_GREEN = 'green';

export const BACKGROUND_RED = 'rgb(255, 226, 221)';
export const HOVER_BACKGROUND_RED = 'rgba(255, 226, 221, 0.5)';
export const COLOR_RED = '#EB5757';

export const HEIGHT_MEDIA_STANDARD_PIXEL = '105px';
