import React from 'react';
import CustomButton from '../../_reusable/CustomButton';

import {
  INTERACT_COLOR,
  INTERACT_BACKGROUND,
  HOVER_BACKGROUND_INTERACT,
} from '../../_reusable/theme';

const Trigger = React.forwardRef((props, ref) => {
  return (
    <div>
      <CustomButton
        color={INTERACT_COLOR}
        hoverColor={INTERACT_COLOR}
        background={INTERACT_BACKGROUND}
        hoverBackground={HOVER_BACKGROUND_INTERACT}
        {...props}
        ref={ref}
      >
        Change password
      </CustomButton>
    </div>
  );
});

export default Trigger;
