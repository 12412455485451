import styled from '@emotion/styled';
import { useRef, useState, useEffect } from 'react';
import { BlockPicker } from 'react-color';
import CustomCheckbox from '../_reusable/CustomCheckbox';
import CustomTooltip from '../_reusable/CustomTooltip';
import { WrapperHorizontalCustom, Tooltip, WrapperPicker, WrapperVerticalLeft } from '../_reusable/styles';
import { BACKGROUND } from '../_reusable/theme';

const BoxColorPreview = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;    
    background: ${(props) => props.background || 'white'};
    width: 16px;
    height: 16px;
    border-radius: 4px;
    box-shadow: 0 2px 10px ${BACKGROUND};
    border: 1px solid rgba(55, 53, 47, 0.2);
    &:hover {
        cursor: pointer;
    }
`;

const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

const ColorSetting = ({ param, onColorChange }) => {
  const [showPicker, setShowPicker] = useState(false);
  const pickerRef = useRef();

  const handleTogglePicker = () => {
    setShowPicker((prev) => !prev);
  };

  const handleColorChange = ({ hex: value }) => {
    onColorChange({ key: param.key, value });
  };

  useOnClickOutside(pickerRef, () => handleTogglePicker());

  return (
    <WrapperHorizontalCustom>
      <CustomTooltip
        key={param.key}
        triggerElement={
          <CustomCheckbox
            id={param.key}
            label={param.label}
            checkboxReplacementComponent={
              <BoxColorPreview
                background={param.value}
                onClick={handleTogglePicker}
              />
            }
          />
        }
        contentElement={
          <Tooltip>
            <WrapperVerticalLeft>
              {param.description}{param.link.map((link) => <img style={{ width: '100px' }} src={link} key={link} />)}
            </WrapperVerticalLeft>
          </Tooltip>}
      />
      {showPicker && (
        <WrapperPicker ref={pickerRef}>
          {param.label}
          <BlockPicker
            color={param.value}
            triangle="hide"
            onChangeComplete={handleColorChange}
          />
        </WrapperPicker>
      )}
    </WrapperHorizontalCustom>
  );
};

export default ColorSetting;
