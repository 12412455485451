import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';

import ProjectCard from './ProjectCard';
import { PlusIcon } from '@radix-ui/react-icons';
import {
  GlobalDispatchContext,
  GlobalStateContext,
  removeProjectById,
  updateProjectById,
} from '../../providers/GlobalProvider';
import CustomDialog from '../_reusable/CustomDialog';
import CustomButton from '../_reusable/CustomButton';
import EditProjectCard from './EditProjectCard';


const ScreenTitle = styled.h1``;

const ProjectArea = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const TopWrapperHorizontal = styled.div`
  display: flex;
  gap: 8px;
`;

const ProjectBoard = () => {
  const { availableProjects } = useContext(GlobalStateContext);
  const [projectCreated, setProjectCreated] = useState(undefined);

  const globalDispatch = useContext(GlobalDispatchContext);
  const [showCreateProject, setShowCreateProject] = useState(false);

  const handleToggleCreateProject = () => {
    setShowCreateProject((prev) => !prev);
  };

  const handleProjectDeleted = (deletedProjectId) => {
    globalDispatch(removeProjectById(deletedProjectId));
  };

  const handleProjectUpdated = (updatedProject) => {
    globalDispatch(updateProjectById(updatedProject));
  };

  return (
    <>
      <ScreenTitle>Overview</ScreenTitle>
      <TopWrapperHorizontal>
        <CustomButton onClick={handleToggleCreateProject}>
          <PlusIcon />
          New project
        </CustomButton>
      </TopWrapperHorizontal>

      <CustomDialog
        open={showCreateProject}
        onClose={handleToggleCreateProject}
        size="100"
        contentElement={
          <EditProjectCard
            project={projectCreated}
            onProjectUpdate={(project) => setProjectCreated(project)}
            onClose={handleToggleCreateProject}
          />
        }
      />

      <ProjectArea>
        {availableProjects.map((project) => {
          return (
            <ProjectCard
              key={project.project_id}
              project={project}
              onProjectDeleted={handleProjectDeleted}
              onProjectUpdated={handleProjectUpdated}
            />
          );
        })}
      </ProjectArea>
    </>
  );
};

export default ProjectBoard;
