import CustomDialog from '../../../_reusable/CustomDialog';
import TranslationButton from './TranslationButton';
import TranslationDialog from './TranslationDialog';

const Translation = ({ buttonStyle }) => {
  return (
    <CustomDialog
      triggerElement={<TranslationButton style={buttonStyle} />}
      contentElement={<TranslationDialog />}
      size="100"
    />
  );
};

export default Translation;
