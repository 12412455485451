import styled from '@emotion/styled';

const Img = styled.img`
  height: 80px;
  width: 80px;
  object-fit: cover;
`;

export default function ImageCell({ cell }) {
  if (!cell.value) return null;
  return <Img key={`${cell.row.id}-${cell.value}`} src={cell.value} />;
}
