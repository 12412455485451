import React from 'react';
import styled from '@emotion/styled';
import { CheckIcon, Cross1Icon, SymbolIcon } from '@radix-ui/react-icons';
import CustomButton from '../../../_reusable/CustomButton';
import {
  BACKGROUND_GREEN,
  BACKGROUND_RED,
  COLOR_GREEN,
  COLOR_RED,
  HOVER_BACKGROUND_GREEN,
  HOVER_BACKGROUND_RED,
} from '../../../_reusable/theme';
// import WebAppLink from './WebAppLink';
// import Settings from './Settings';

const TopMenuAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const ConfirmButtons = ({ project, status, onSave, onClose }) => {
  const saveButtonText = (() => {
    if (status === null) {
      return (
        <>
          {!project ? (
            <>
              <CheckIcon />
              Create
            </>
          ) : (
            'Updated'
          )}
        </>
      );
    } else if (status === 'saving') {
      return (
        <>
          <SymbolIcon /> {!!project ? 'Creating' : 'Saving'}
        </>
      );
    } else if (status === 'error') {
      return (
        <>
          <Cross1Icon /> Try again
        </>
      );
    } else {
      return (
        <>
          <CheckIcon /> {!project ? 'Create' : 'Save'}
        </>
      );
    }
  })();

  return (
    <>
      <CustomButton
        onClick={onSave}
        color={COLOR_GREEN}
        hoverColor={COLOR_GREEN}
        background={BACKGROUND_GREEN}
        hoverBackground={HOVER_BACKGROUND_GREEN}
        disabled={status === null}
      >
        {saveButtonText}
      </CustomButton>
      <CustomButton
        onClick={onClose}
        color={COLOR_RED}
        hoverColor={COLOR_RED}
        background={BACKGROUND_RED}
        hoverBackground={HOVER_BACKGROUND_RED}
      >
        <Cross1Icon />
        Cancel
      </CustomButton>
    </>
  );
};

const TopMenuArea = ({ project, status, onSave, onClose }) => {
  return (
    <TopMenuAreaWrapper>
      <ConfirmButtons
        project={project}
        status={status}
        onSave={onSave}
        onClose={onClose}
      />
      {/* <WebAppLink /> */}
      {/* <Settings /> */}
    </TopMenuAreaWrapper>
  );
};

export default TopMenuArea;
