import styled from '@emotion/styled';
import {
  BACKGROUND,
  BACKGROUND_LIGHT,
  SIZE_FONT_MEDIUM,
  SIZE_FONT_WEIGHT_MEDIUM,
  COLOR,
  COLOR_LIGHT_2,
  HOVER_BACKGROUND,
} from './theme';

const CustomButton = styled.button`
  all: unset;
  display: inline-flex;
  background: ${(props) => {
    if (props.disabled) return BACKGROUND_LIGHT;
    return props.background || BACKGROUND;
  }};
  gap: 4px;
  margin-top: ${(props) => props.margin || null};
  margin-bottom: ${(props) => props.margin || null};
  padding: 6px 12px;
  position: relative;
  transition: all 0.06s;
  cursor: inherit;
  align-items: center;
  justify-content: ${(props) => props.justifyContent || 'left'};
  color: ${(props) => {
    if (props.disabled) return COLOR_LIGHT_2;
    return props.color || COLOR;
  }};
    
  font-family: inherit;
  font-weight: ${SIZE_FONT_WEIGHT_MEDIUM};
  font-size: ${SIZE_FONT_MEDIUM};
  border-radius: 4px;
  width: ${(props) => props.width || null};

  &:hover {
    background: ${(props) => {
    if (props.disabled) return BACKGROUND_LIGHT;
    return props.hoverBackground || HOVER_BACKGROUND;
  }};
    color: ${(props) => {
    if (props.disabled) return COLOR_LIGHT_2;
    return props.hoverColor || COLOR;
  }}
  }
`;

export default CustomButton;
