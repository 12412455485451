import styled from '@emotion/styled';
import { useEffect } from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { ProjectDispatchContext } from '../../../../providers/ProjectProvider';
import CustomInput from '../../../_reusable/CustomInput';
import { BORDER_COLOR, COLOR } from '../../../_reusable/theme';

const FilterCriterionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  height: 32px;
  min-width: 120px;
  border-radius: 4px;
  border: 1px solid ${BORDER_COLOR};
  align-items: center;
  font-size: 14px;
`;

const CustomSelect = styled.select`
  background-color: white;
  border: none;
  border-radius: 0;
  height: 100%;
  width: 50%;
  padding-left: 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 14px;
  font-weight: 400;
  color: ${COLOR}
  cursor: pointer;
`;

const FilterCriterion = ({ options }) => {
  const dispatch = useContext(ProjectDispatchContext);
  const [filter, setFilter] = useState([]);
  const handleSelect = (event) => {
    setFilter([event.target.value, '']);
  };
  const handleInput = (event) => {
    setFilter((prev) => [prev[0], event.target.value]);
  };

  useEffect(() => {
    dispatch({ type: 'setFilters', payload: filter });
  }, [filter, dispatch]);

  return (
    <FilterCriterionWrapper>
      <CustomSelect onChange={handleSelect} defaultValue={"name"}>
        {options.map((option) => (
          <option
            key={option.recable_attribute_id}
            value={option.recable_attribute_lang_name}
            id={option.recable_attribute_lang_name}
          >
            {option.recable_attribute_codename}
          </option>
        ))}
      </CustomSelect>
      <CustomInput
        placeholder="Type filter value here"
        onChange={handleInput}
      />
    </FilterCriterionWrapper>
  );
};

export default FilterCriterion;
