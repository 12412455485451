import React, { useState } from 'react';
import { styled, keyframes } from '@stitches/react';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { BACKGROUND, COLOR, COLOR_LIGHT } from './theme';

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const StyledContent = styled(DropdownMenuPrimitive.Content, {
  display: 'flex',
  flexDirection: 'column',
  width: 230,
  maxHeight: '80vh',
  overflowY: 'scroll',
  backgroundColor: 'white',
  borderRadius: 4,
  padding: 4,
  boxShadow:
    '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
  '@media (prefers-reduced-motion: no-preference)': {
    animationDuration: '200ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    animationFillMode: 'forwards',
    willChange: 'transform, opacity',
    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
});

const StyledSubContent = styled(DropdownMenuPrimitive.SubContent, {
  display: 'flex',
  flexDirection: 'column',
  width: 20,
  maxHeight: '80vh',
  overflowY: 'scroll',
  backgroundColor: 'white',
  borderRadius: 4,
  padding: 4,
  boxShadow:
    '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
  '@media (prefers-reduced-motion: no-preference)': {
    animationDuration: '200ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    animationFillMode: 'forwards',
    willChange: 'transform, opacity',
    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
});

const itemStyles = {
  all: 'unset',
  display: 'flex',
  flexGrow: '1',
  fontSize: 14,
  fontWeight: 400,
  lineHeight: 1,
  color: COLOR,
  borderRadius: 4,
  gap: 8,
  alignItems: 'center',
  height: 32,
  minHeight: 32,
  padding: '0 4px',
  position: 'relative',
  paddingLeft: 24,
  userSelect: 'none',
  cursor: 'default',

  '&[data-disabled]': {
    color: COLOR_LIGHT,
    pointerEvents: 'none',
  },

  '&:focus': {
    backgroundColor: BACKGROUND,
  },
};

const StyledItem = styled(DropdownMenuPrimitive.Item, { ...itemStyles });
const StyledDropdownMenuSubTrigger = styled(DropdownMenuPrimitive.SubTrigger, {
  ...itemStyles,
});
const StyledCheckboxItem = styled(DropdownMenuPrimitive.CheckboxItem, {
  ...itemStyles,
});
const StyledItemIndicator = styled(DropdownMenuPrimitive.ItemIndicator, {
  position: 'absolute',
  left: 0,
  width: 25,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const RightSlot = styled('div', {
  marginLeft: 'auto',
  paddingLeft: 20,
  color: 'inherit',
  '[data-disabled] &': { color: COLOR_LIGHT },
});

export const DropdownMenu = DropdownMenuPrimitive.Root;
export const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;
export const DropdownMenuContent = StyledContent;
export const DropdownMenuItem = StyledItem;
export const DropdownMenuCheckboxItem = StyledCheckboxItem;
export const DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup;
export const DropdownMenuItemIndicator = StyledItemIndicator;
export const DropdownMenuSub = DropdownMenuPrimitive.Sub;
export const DropdownMenuSubTrigger = StyledDropdownMenuSubTrigger;
export const DropdownMenuSubContent = StyledSubContent;

export const CustomDropdownMenu = ({
  triggerElement,
  contentElement,
  side,
  align,
  noTransform=false}) => {
  const [open, setOpen] = useState(false);
  const handleToggleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <DropdownMenu open={open}>
      <DropdownMenuTrigger asChild onClick={handleToggleOpen}>
        {triggerElement}
      </DropdownMenuTrigger>

      <DropdownMenuContent
        asChild
        loop
        onCloseAutoFocus={handleClose}
        onEscapeKeyDown={handleClose}
        onPointerDownOutside={handleClose}
        onFocusOutside={handleClose}
        onInteractOutside={handleClose}
        side={side}
        align={align}
        alignOffset={-150}
        sideOffset={3}
      >
        {contentElement}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default CustomDropdownMenu;
