import { get, post, put, remove } from './dataSource';

const URL_BASE_MODEL = 'model';
const URL_BASE_INFERENCE = 'inference';

export const getModels = async ({ tenantId }) => {
  return await get(URL_BASE_MODEL, {
    tenant_id: tenantId,
  });
};

export const createModel = async ({ userId, tenantId, data }) => {
  return await post(URL_BASE_MODEL, data, {
    user_id: userId,
    tenant_id: tenantId
  });
};

export const updateModel = async ({ userId, tenantId, modelId }) => {
  return await put(`${URL_BASE_MODEL}/${modelId}`, {}, {
    user_id: userId,
    tenant_id: tenantId,
  });
};

export const removeModel = async ({ modelId }) => {
  return await remove(URL_BASE_MODEL, {}, {
    model_id: modelId
  });
};

export const createInferenceUrl = async ({ tenantId, urlBody }) => {
  return await post(
    URL_BASE_INFERENCE,
    { url: urlBody },
    {
      tenant_id: tenantId,
      type_img: 'url',
    }
  );
};

export const createInferenceBlob = async ({ tenantId, data }) => {
  return await post(
    URL_BASE_INFERENCE,
    data,
    {
      tenant_id: tenantId,
      type_img: 'binary',
    },
    {
      'Content-Type': data.type,
    }
  );
};