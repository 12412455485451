import { get, post, put, remove } from "./dataSource";

const URL_BASE_PROJECT = 'project';

export const getProjects = async ({ userId, tenantId, langId }) => {
    return await get(URL_BASE_PROJECT, {
      lang_id: langId,
      user_id: userId,
      tenant_id: tenantId,
    });
  };
  
  export const createProject = async ({ langId, userId, tenantId, data }) => {
    return await post(URL_BASE_PROJECT, data, {
      lang_id: langId,
      user_id: userId,
      tenant_id: tenantId,
    });
  };
  
  export const updateProject = async ({ userId, projectId, data }) => {
    return await put(`${URL_BASE_PROJECT}/${projectId}`, data, {
      user_id: userId,
    });
  };
  
  export const getProject = async ({ projectId, userId, tenantId }) => {
    return await get(`${URL_BASE_PROJECT}/${projectId}`, {
      user_id: userId,
      tenant_id: tenantId,
    });
  };
  
  export const deleteProject = async ({ projectId }) => {
    return await remove(`${URL_BASE_PROJECT}/${projectId}`);
  };