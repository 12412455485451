import React, { useContext, useEffect, useState } from 'react';
import {
    GlobalStateContext,
} from '../../../providers/GlobalProvider';
import {
    BACKGROUND_GREEN,
    BACKGROUND_LIGHT,
    COLOR_GREEN,
    COLOR_LIGHT,
    HOVER_BACKGROUND_GREEN,
} from '../../_reusable/theme';
import CustomButton from '../../_reusable/CustomButton';
import { PlusIcon, ArrowUpIcon, ArrowDownIcon, UpdateIcon } from '@radix-ui/react-icons';
import { getPhoneme } from '../../../api/phoneme';
import PhonemeCard from './PhonemeCard';
import PhonemeCreate from './PhonemeCreate';
import { PageArea, WrapperHorizontalCustom, TextSubSection, TextNormal, } from '../../_reusable/styles';

const Phoneme = () => {
    const { tenant, user } = useContext(GlobalStateContext);
    const [listPhoneme, setListPhoneme] = useState(undefined);

    const [isVisible, setIsVisible] = useState(false)
    const [isCreate, setIsCreate] = useState(false);
    const [isUpdateListPhoneme, setIsUpdateListPhoneme] = useState(true);

    const handleLoadPhoneme = () => {
        getPhoneme({ tenantId: tenant.tenant_id, userId: user.id })
          .then((res) => setListPhoneme(res.data.result))
          .catch((error) => {
              console.error(error);
          });
    }

    useEffect(() => {
        if (tenant?.tenant_id && isUpdateListPhoneme) {
            handleLoadPhoneme();
            setIsUpdateListPhoneme(false);
        }
    }, [tenant, user, isCreate, isUpdateListPhoneme, listPhoneme])

    return (
      <PageArea>
          <TextSubSection>Phoneme area</TextSubSection>
          <WrapperHorizontalCustom>
              <CustomButton
                background={isVisible ? BACKGROUND_GREEN : BACKGROUND_LIGHT}
                color={isVisible ? COLOR_GREEN : COLOR_LIGHT}
                onClick={() => setIsVisible(!isVisible)}>
                  {(listPhoneme !== undefined) ? (isVisible ? <ArrowUpIcon /> : <ArrowDownIcon />) : <UpdateIcon />}
                  {(listPhoneme !== undefined) ? 'Toggle Phoneme Board' : 'Loading Phoneme Board'}
              </CustomButton>
          </WrapperHorizontalCustom>
          {(isVisible) && <>
              {isCreate
                && <PhonemeCreate
                  setIsUpdateListPhoneme={setIsUpdateListPhoneme}
                  setIsMode={setIsCreate}
                  isModeCreate={true}
                />}
              {(!isCreate) && <CustomButton
                background={BACKGROUND_GREEN}
                hover_background={HOVER_BACKGROUND_GREEN}
                color={COLOR_GREEN}
                width='75px'
                onClick={() => setIsCreate(true)}
                disable={true}>
                  <PlusIcon />Create
              </CustomButton>}
              {listPhoneme?.map((itemPhoneme) =>
                <PhonemeCard
                  phoneme={itemPhoneme}
                  key={itemPhoneme.phoneme_id}
                  setIsUpdateListPhoneme={setIsUpdateListPhoneme}
                />
              )}
          </>}
      </PageArea>
    );
};

export default Phoneme;
