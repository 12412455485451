import CustomDialog from '../../_reusable/CustomDialog';
import Trigger from './Trigger';
import Content from './Content';

const ChangePassword = () => {
  return (
    <CustomDialog
      triggerElement={<Trigger />}
      contentElement={<Content />}
    />
  );
}
export default ChangePassword;