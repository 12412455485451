import styled from '@emotion/styled';
import { useContext, useState } from 'react';
import { translateProject } from '../../../api/api';
import { GlobalStateContext } from '../../../providers/GlobalProvider';
import CustomButton from '../../_reusable/CustomButton';
import CustomDialog from '../../_reusable/CustomDialog';
import { TextSection } from '../../_reusable/styles';
import { COLOR } from '../../_reusable/theme';

const MessageContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${COLOR}
  font-size: 14px;
  gap: 16px;
`;

const TextArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  gap: 16px;
  line-height: 1.5;
`;

const WrapperHorizontal = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: center;
`;

const MessageContent = ({
  translateStatus,
  onClose,
  onTranslate,
}) => {

  return (
    <MessageContentWrapper>
      <TextArea>
        <TextSection>Project successfully created</TextSection>
        <>
          {
            translateStatus === 'error' ?
              "Unfortunately, the translation has not worked out. Please contact the administrator." :
              (
                translateStatus === 'success' ?
                  "Successfully translated! Please press Close." :
                  "The project is currently not translated yet. Do you want to perform the translation right now? It won't take long."
              )
          }
        </>
      </TextArea>
      <WrapperHorizontal>
        <>
          {translateStatus === 'success' ? (
            <>
              <CustomButton onClick={onClose}>Close</CustomButton>
            </>
          ) : (
            <>
              <CustomButton onClick={onTranslate}>
                {translateStatus === 'loading' ? 'Please wait...' : 'Translate'}
              </CustomButton>
              <CustomButton onClick={onClose}>Skip</CustomButton>
            </>
          )}
        </>
      </WrapperHorizontal>
    </MessageContentWrapper>
  );
};

const MessageDialogProject = ({ projectId, isShowMessageDialog, setIsShowMessageDialog }) => {
  const { user, currentLangId } = useContext(GlobalStateContext);
  const [translateStatus, setTranslateStatus] = useState('initial');

  const handleCloseMessageDialog = () => {
    setIsShowMessageDialog(false);
  };

  const handleTranslateProject = () => {
    if (translateStatus === 'success') {
      handleCloseMessageDialog();
    } else {
      setTranslateStatus('loading');
      translateProject({
        projectId: projectId,
        langId: currentLangId,
        userId: user.id,
      })
        .then(() => {
          setTranslateStatus('success');
        })
        .catch((error) => {
          setTranslateStatus('error');
          console.error(error);
        });
    }
  };

  return (
    <CustomDialog
      open={isShowMessageDialog}
      onClose={handleCloseMessageDialog}
      contentElement={
        <MessageContent
          translateStatus={translateStatus}
          onTranslate={handleTranslateProject}
          onClose={handleCloseMessageDialog}
        />
      }
    />
  );
};

export default MessageDialogProject;
