import React, { useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import { CheckIcon, Cross1Icon, SymbolIcon } from '@radix-ui/react-icons';
import CustomButton from '../../_reusable/CustomButton';
import {
  ArticleDispatchContext,
  ArticleStateContext,
  articleSuccess,
  articleError,
  clearStatus,
  updateRecableAsNew,
  updateRecable,
  updateRecableStatus,
} from '../../../providers/ArticleProvider';
import {
  createArticle,
  createRecableInfo,
  getRecable,
  updateRecableInfo,
} from '../../../api/recable';
import { GlobalStateContext } from '../../../providers/GlobalProvider';
import {
  appendProjectArticle,
  updateProjectArticleById,
  ProjectDispatchContext,
  ProjectStateContext,
} from '../../../providers/ProjectProvider';
import MessageDialog from './MessageDialog';
import {
  BACKGROUND_GREEN,
  BACKGROUND_RED,
  COLOR_GREEN,
  COLOR_RED,
  HOVER_BACKGROUND_GREEN,
  HOVER_BACKGROUND_RED,
} from '../../_reusable/theme';
import WebAppLink from './WebAppLink';
import Settings from './Settings';

const TopMenuAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const ConfirmButtons = ({ onClose }) => {
  const dispatch = useContext(ArticleDispatchContext);
  const projectDispatch = useContext(ProjectDispatchContext);
  const { info: project } = useContext(ProjectStateContext);
  const { user, currentLangId } = useContext(GlobalStateContext);
  const { currentProperties, isNew, articleTypeId, article, status } =
    useContext(ArticleStateContext);

  useEffect(() => dispatch(clearStatus()), [dispatch]);

  const handleSaveRecable = () => {
    dispatch(updateRecableStatus('saving'));
    if (isNew) {
      const recableAttributeIdValueList = currentProperties.map((property) => {
        return {
          recable_attribute_id: property.recable_attribute_id,
          value: property.recable_info_text || '',
        };
      });

      createArticle({
        data: {
          recable_attribute_id_value_list: recableAttributeIdValueList,
        },
        langId: currentLangId,
        userId: user.id,
        projectId: project.project_id,
        articleTypeId,
      }).then(
        (res) => getRecable({
          recableId: res.data.recable_id,
          langId: currentLangId,
          projectId: project.project_id,
        }).then(
          (res) => {
            dispatch(updateRecableAsNew(res.data.result));
            projectDispatch(appendProjectArticle(res.data.result));
          }
        )
      ).then(
        () => {
          dispatch(
            articleSuccess(
              'You have successfully generated a new object! This object is currently only available in the language chosen. You can press “Translate” to have the name and description translated or skip this step and perform it yourself later.'
            )
          );
        }
      ).catch(
        (error) => {
          dispatch(
            articleError(
              `Unfortunately, we could not create the object as you wanted. The following error message was thrown: ${error.message}`
            )
          );
        }
      );
    } else {
      const promises = [];
      for (const property of currentProperties) {
        let promise = null;
        if (!property.recable_info_id) {
          promise = createRecableInfo({
            data: {
              recable_attribute_id: property.recable_attribute_id,
              value: property.recable_info_text,
            },
            langId: currentLangId,
            projectId: article.project_id,
            recableId: article.recable_id,
            userId: user.id,
          });
        } else {
          let associationData;
          if (
            ![null, undefined].includes(
              property.recable_info_enhancement_recable_reference
            )
          ) {
            associationData = {
              column: 'recable_info_enhancement_recable_reference',
              value:
                property.recable_info_enhancement_recable_reference.toString(),
              type: 'numeric',
            };
          }

          promise = updateRecableInfo({
            data: {
              column_value_type_list: [
                associationData,
                {
                  column: 'recable_info_text',
                  value: property.recable_info_text,
                  type: 'string',
                },
              ],
            },
            userId: user.id,
            recableInfoId: property.recable_info_id,
          });
        }
        promises.push(promise);
      }
      Promise.all(promises)
        .then(() => {
          dispatch(articleSuccess('Article saved successfully.'));
        })
        .then(() =>
          getRecable({
            recableId: article.recable_id,
            langId: currentLangId,
            projectId: article.project_id,
          })
        )
        .then((res) => {
          const updatedArticle = res.data.result;
          projectDispatch(updateProjectArticleById(updatedArticle));
          dispatch(updateRecable(updatedArticle));
        })
        .catch((error) => {
          dispatch(
            articleError(
              `Unfortunately, we could not create the object as you wanted. The following error message was thrown: ${error.message}`
            )
          );
        });
    }
  }

  const saveButtonText = (() => {
    if (status === null) {
      return <>Up to date.</>;
    } else if (status === 'saving') {
      return (
        <>
          <SymbolIcon /> Saving
        </>
      );
    } else if (status === 'error') {
      return (
        <>
          <Cross1Icon /> Try again
        </>
      );
    } else {
      return (
        <>
          <CheckIcon />
          {isNew ? 'Create' : 'Save'}
        </>
      );
    }
  })();

  return (
    <>
      <CustomButton
        onClick={handleSaveRecable}
        color={COLOR_GREEN}
        hoverColor={COLOR_GREEN}
        background={BACKGROUND_GREEN}
        hoverBackground={HOVER_BACKGROUND_GREEN}
        disabled={status === null}
      >
        {saveButtonText}
      </CustomButton>
      <CustomButton
        onClick={onClose}
        color={COLOR_RED}
        hoverColor={COLOR_RED}
        background={BACKGROUND_RED}
        hoverBackground={HOVER_BACKGROUND_RED}
      >
        <Cross1Icon />
        Cancel
      </CustomButton>

      <MessageDialog />
    </>
  );
};

const TopMenuArea = ({ onClose }) => {
  return (
    <TopMenuAreaWrapper >
      <ConfirmButtons onClose={onClose} />
      <WebAppLink />
      <Settings />
    </TopMenuAreaWrapper>
  );
};

export default TopMenuArea;
