import React, { useContext } from 'react';
import styled from '@emotion/styled';
import Translation from './Translation';
import Synthesization from './Synthesization';
import QRCodeView from '../../../_reusable/CustomQRCode';
import { COLOR_LIGHT } from '../../../_reusable/theme';
import { GlobalStateContext } from '../../../../providers/GlobalProvider';
import { WrapperVerticalLeft } from '../../../_reusable/styles';

const BottomMenuAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  gap: 8px;
  width: 100%;
`;

const Separator = styled.div`
  margin-top: 3px;
  height: 3px;
  width: 100%;
  background-color: ${({ color }) => color || COLOR_LIGHT};
`;

const BottomMenuArea = ({ project }) => {

  const { tenant, currentLangId } = useContext(GlobalStateContext);

  if (!project) return null;

  const webAppLink = `${tenant.tenant_domain}/${currentLangId}/project/${project.project_id}`

  return (
    <BottomMenuAreaWrapper>
      <Translation project={project} />
      <Synthesization project={project} />
      <Separator />
      <QRCodeView webAppLink={webAppLink} urlpicture={project.project_pic_url} />
    </BottomMenuAreaWrapper>
  );
};

export default BottomMenuArea;
