import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { ChevronDownIcon, PlusIcon } from '@radix-ui/react-icons';
import { customAlphabet } from 'nanoid';
import {
  ArticleDispatchContext,
  ArticleStateContext,
  updateProperty,
} from '../../../../providers/ArticleProvider';
import CustomButton from '../../../_reusable/CustomButton';
import CustomSelect, {
  CustomSelectItem,
  SelectIcon,
  SelectValue,
  SelectViewport,
} from '../../../_reusable/CustomSelect';
import CustomInput from '../../../_reusable/CustomInput';
import { useCallback } from 'react';

const nanoid = customAlphabet('1234567890abcdef', 12);

const SPEAKER_OPTIONS = [
  { label: 'Speaker A', value: '0' },
  { label: 'Speaker B', value: '1' },
];

const InterviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;
const SelectIconWrapper = styled.span`
  margin-left: auto;
`;

const InterviewLineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex-wrap: nowrap;
`;

const InterviewLineSpeakerSelectTrigger = React.forwardRef((props, ref) => {
  return (
    <CustomButton ref={ref} {...props} width="120px">
      <SelectValue />
      <SelectIcon asChild>
        <SelectIconWrapper>
          <ChevronDownIcon />
        </SelectIconWrapper>
      </SelectIcon>
    </CustomButton>
  );
});

const InterviewLineSpeakerSelectContent = React.forwardRef((props, ref) => {
  return (
    <SelectViewport ref={ref} {...props}>
      {SPEAKER_OPTIONS.map((option) => (
        <CustomSelectItem
          key={option.value}
          label={option.label}
          value={option.value}
        />
      ))}
    </SelectViewport>
  );
});

const InterviewLine = ({ line, onLineChange }) => {
  const handleSelect = (speakerId) => {
    onLineChange({ line, speakerId });
  };

  const handleChange = (event) => {
    onLineChange({ line, speakerText: event.target.value });
  };

  return (
    <InterviewLineWrapper>
      <CustomSelect
        defaultValue={line.speaker_id}
        value={line.speaker_id}
        triggerElement={<InterviewLineSpeakerSelectTrigger />}
        contentElement={<InterviewLineSpeakerSelectContent />}
        onSelect={handleSelect}
        contentSize={140}
      />
      <CustomInput value={line.speaker_text} onChange={handleChange} />
    </InterviewLineWrapper>
  );
};

const Interview = ({ property }) => {
  const dispatch = useContext(ArticleDispatchContext);
  const interview = property.recable_info_text;

  const handleAddInterviewLine = () => {
    dispatch(
      updateProperty({
        ...property,
        recable_info_text: [
          ...(interview?.value || []),
          {
            id: nanoid(),
            speaker_id: '0',
            speaker_text: '',
          },
          {
            id: nanoid(),
            speaker_id: '1',
            speaker_text: '',
          },
        ],
      })
    );
  };

  const updateInterviewValue = useCallback(
    ({ interviewValue, line, speakerId, speakerText }) =>
      interviewValue.map((l) => {
        if (l.id === line.id) {
          if (speakerId)
            return {
              ...line,
              speaker_id: speakerId,
            };
          if (![null, undefined].includes(speakerText))
            return {
              ...line,
              speaker_text: speakerText,
            };
        }
        return l;
      }),
    []
  );

  const handleLineChange = ({ line, speakerId, speakerText }) => {
    const interviewValue = [...interview?.value] || [];
    const updatedInterviewValue = updateInterviewValue({
      interviewValue,
      line,
      speakerId,
      speakerText,
    });

    dispatch(
      updateProperty({
        ...property,
        recable_info_text: updatedInterviewValue,
      })
    );
  };

  return (
    <InterviewWrapper>
      {(interview?.value || []).map((line) => (
        <InterviewLine
          key={line.id}
          line={line}
          onLineChange={handleLineChange}
        />
      ))}
      <CustomButton width="94px" onClick={handleAddInterviewLine}>
        <PlusIcon />
        New line
      </CustomButton>
    </InterviewWrapper>
  );
};

export default Interview;
