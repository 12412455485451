import { useContext } from 'react';
import { useReducer, createContext, useEffect } from 'react';
import { recable } from '../api';
import { GlobalStateContext } from './GlobalProvider';

const initialState = {
  articleType: 'artwork',
  articleTypeId: 1,
  articleTypeIdLookup: {
    artwork: 1,
    creator: 2,
    topic: 3,
  },
  article: null, // recable-GET result
  availableProperties: [],
  currentProperties: [], // detail of current properties pulled from server + any changes, // TODO: refactor currentProperties so that this is not needed
  isNew: false,
  translationLangId: 1, // German
  allProperties: [], // all available properties in general
  status: null,
  message: '',
};

export const isNewArticle = (articleType) => ({
  type: 'isNewArticle',
  payload: articleType,
});

export const updateRecable = (article) => ({
  type: 'updateRecable',
  payload: article,
});

export const updateRecableAsNew = (article) => ({
  type: 'updateRecableAsNew',
  payload: article,
});

export const updateNewToOld = () => ({
  type: 'updateNewToOld',
})

export const getAllProperties = (properties) => ({
  type: 'allProperties',
  payload: properties,
});

export const updateProperty = (property) => ({
  type: 'updateProperty',
  payload: property,
});

export const toggleProperty = (propertyKeyCodename) => ({
  type: 'toggleProperty',
  payload: propertyKeyCodename,
});

export const articleError = (error) => ({
  type: 'articleError',
  payload: error,
});

export const articleSuccess = (message) => ({
  type: 'articleSuccess',
  payload: message,
});

export const updateRecableStatus = (status) => ({
  type: 'updateRecableStatus',
  payload: status,
});

export const clearStatus = () => ({
  type: 'clearStatus',
});

export const updateTranslationLangId = (langId) => ({
  type: 'updateTranslationLangId',
  payload: langId,
});

export const updateCurrentProperties = (properties) => ({
  type: 'updateCurrentProperties',
  payload: properties,
});

export const updateAvailableProperties = (properties) => ({
  type: 'updateAvailableProperties',
  payload: properties,
});

const reducer = (state, action) => {
  const newState = { ...state };
  switch (action.type) {
    case 'allProperties':
      newState.allProperties = action.payload;
      return newState;

    case 'updateProperty':
      newState.currentProperties = [...state.currentProperties].map(
        (property) => {
          if (
            property.recable_attribute_id === action.payload.recable_attribute_id
          ) {
            return action.payload;
          }
          return property;
        }
      );
      newState.status = 'unsaved';
      return newState;

    case 'updateCurrentProperties':
      newState.currentProperties = action.payload;
      return newState;

    case 'toggleProperty':
      const targetPropertyIndex = state.currentProperties.findIndex(
        (property) => property.recable_attribute_lang_name === action.payload
      );

      if (targetPropertyIndex > -1) {
        const clone = [...state.currentProperties];
        clone.splice(targetPropertyIndex, 1);
        newState.currentProperties = clone;
      } else {
        const newProperty = newState.availableProperties.find(
          (property) =>
            property.recable_attribute_lang_name === action.payload
        );

        newState.currentProperties = [newProperty, ...state.currentProperties];
      }
      return newState;

    case 'updateTranslationLangId':
      newState.translationLangId = action.payload;
      return newState;

    case 'updateRecable':
      newState.article = action.payload;
      newState.isNew = false;
      newState.articleType = action.payload.recable_type_name;
      newState.articleTypeId =
        initialState.articleTypeIdLookup[newState.articleType];
      return newState;

    case 'updateRecableAsNew':
      newState.article = action.payload;
      newState.status = 'created';
      newState.articleType = action.payload.recable_type_name;
      return newState;

    case 'updateNewToOld':
      newState.isNew = false;
      return newState;

    case 'isNewArticle':
      newState.isNew = true;
      newState.articleType = action.payload || initialState.articleType;
      newState.articleTypeId =
        initialState.articleTypeIdLookup[newState.articleType];

      newState.availableProperties =
        newState.allProperties.find(
          (propertyGroup) =>
            propertyGroup.recableTypeId === newState.articleTypeId
        )?.properties || [];

      newState.status = initialState.status;
      newState.message = initialState.message;
      newState.article = initialState.article;
      return newState;

    case 'updateAvailableProperties':
      newState.availableProperties = action.payload;
      return newState;

    case 'articleError':
      newState.message = action.payload;
      newState.status = 'error';
      return newState;

    case 'articleSuccess':
      newState.message = action.payload;
      newState.status = 'success';
      return newState;

    case 'updateRecableStatus':
      newState.status = action.payload;
      return newState;

    case 'clearStatus':
      newState.message = '';
      newState.status = null;
      return newState;

    default:
      return newState;
  }
};

export const ArticleStateContext = createContext(initialState);
export const ArticleDispatchContext = createContext(() => { });

const ArticleProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { currentLangId, availableRecableTypes } =
    useContext(GlobalStateContext);
  const { allProperties, availableProperties, article, articleTypeId, isNew } =
    state;

  useEffect(() => {
    if (availableRecableTypes.length) {
      recable.getRecableAttribute({ langId: currentLangId }).then((res) => {
        const allProperties = res.data.result;
        const propertiesByRecableType = availableRecableTypes.map(
          (recableType) => {
            const recableTypeId = recableType.recable_type_id;
            const recableTypeName = recableType.recable_type_lang_name;
            const properties = allProperties.filter(
              (prop) => prop.recable_attribute_recable_type_id === recableTypeId
            );
            return {
              recableTypeId,
              recableTypeName,
              properties,
            };
          }
        );
        dispatch(getAllProperties(propertiesByRecableType));
      });
    }
  }, [dispatch, availableRecableTypes, currentLangId]);

  useEffect(() => {
    if (article) {
      recable
        .getRecableInfo({
          recableId: article.recable_id,
          langId: currentLangId,
          projectId: article.project_id,
        })
        .then((res) => {
          dispatch(updateCurrentProperties(res.data.result));
        })
        .catch((error) => console.error(error));
    }
  }, [dispatch, article, currentLangId]);

  useEffect(() => {
    if (allProperties.length && articleTypeId) {
      const availableProperties = allProperties.find(
        (propertyGroup) => propertyGroup.recableTypeId === articleTypeId
      ).properties;
      dispatch(updateAvailableProperties(availableProperties));
    }
  }, [dispatch, articleTypeId, allProperties]);

  useEffect(() => {
    if (isNew) {
      const newCurrentProperties = availableProperties.filter(
        (property) => {
          let res = property.recable_attribute_is_obligatory;
          if (articleTypeId === initialState.articleTypeIdLookup.artwork) {
            res =res || (property.recable_attribute_id === 3 || property.recable_attribute_id === 4 || property.recable_attribute_id === 6)
          }
          return res;
        }
      );
      dispatch(updateCurrentProperties(newCurrentProperties));
    }
  }, [dispatch, isNew, articleTypeId, availableProperties]);

  return (
    <ArticleDispatchContext.Provider value={dispatch}>
      <ArticleStateContext.Provider value={state}>
        {children}
      </ArticleStateContext.Provider>
    </ArticleDispatchContext.Provider>
  );
};

export default ArticleProvider;
