import React, { useMemo, useContext, useState } from 'react';
import { CheckIcon, SymbolIcon, ArrowUpIcon, ArrowDownIcon } from '@radix-ui/react-icons';
import styled from '@emotion/styled';

import {
  HOVER_BACKGROUND_INTERACT,
  INTERACT_BACKGROUND,
  INTERACT_COLOR,
  BACKGROUND_GREEN,
  BACKGROUND_LIGHT,
  COLOR_GREEN,
  COLOR_LIGHT,
  SIZE_MARGIN_LARGE,
} from '../../_reusable/theme';
import CustomCheckbox from '../../_reusable/CustomCheckbox';
import CustomButton from '../../_reusable/CustomButton';
import CustomTooltip from '../../_reusable/CustomTooltip';

import {
  GlobalDispatchContext,
  GlobalStateContext,
  updateTenantInfo,
} from '../../../providers/GlobalProvider';
import { tenant as tenantAPI } from '../../../api';

import ColorSetting from '../ColorSetting';
import { PageArea, TextSection, TextSubSection, WrapperHorizontalCustom, WrapperHorizontal, WrapperVerticalLeft } from '../../_reusable/styles';

const Tooltip = styled.div`
border - radius: 4px;
padding: 12px;
background: rgba(0, 0, 0, 0.6);
color: white;
font - size: 12px;
font - weight: 500;
width: 240px;
`;

const TenantSettings = () => {
  const { tenant, user } = useContext(GlobalStateContext);
  const groupedParameters = useMemo(() => {
    const grouped = {};
    tenant?.parameters?.map((param) => {
      if (grouped[param.type]) {
        grouped[param.type] = [...grouped[param.type], param];
      } else {
        grouped[param.type] = [param];
      }
    });
    return grouped;
  }, [tenant]);

  const globalDispatch = useContext(GlobalDispatchContext);

  const [isVisible, setIsVisible] = useState(false)
  const [status, setStatus] = useState('idle');

  const handleCheckboxChange = ({ key, value }) => {
    setStatus('unsaved');
    const stringValue = (() => (value === true ? 'True' : 'False'))();
    globalDispatch(
      updateTenantInfo({
        propertyKey: 'parameters',
        propertyValue: [...tenant.parameters].map((param) => {
          if (param.key === key) return { ...param, value: stringValue };
          return param;
        }),
      })
    );
  };

  const handleColorChange = ({ key, value }) => {
    setStatus('unsaved');
    globalDispatch(
      updateTenantInfo({
        propertyKey: 'parameters',
        propertyValue: [...tenant.parameters].map((param) => {
          if (param.key === key) return { ...param, value };
          return param;
        }),
      })
    );
  };

  const handleSave = () => {
    setStatus('saving');
    tenantAPI
      .updateTenant({
        tenantId: tenant.tenant_id,
        userId: user.id,
        data: [...tenant.parameters].map((param) => ({
          type: param.type,
          value: param.value,
          key: param.key,
        })),
      })
      .then(() => {
        setStatus('saved');
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleReset = () => {
    setStatus('resetting');
    tenantAPI
      .updateTenant({
        tenantId: tenant.tenant_id,
        userId: user.id,
        data: [...tenant.parameters].map((param) => ({
          type: param.type,
          value: param.default,
          key: param.key,
        })),
      })
      .then(() => {
        setStatus('resetted');
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const saveButtonText = (() => {
    switch (status) {
      case 'saving':
        return (
          <>
            <SymbolIcon /> Saving
          </>
        );
      case 'saved':
        return (
          <>
            <CheckIcon /> Saved!
          </>
        );
      default:
        return <>Save</>;
    }
  })();

  const resetButtonText = (() => {
    switch (status) {
      case 'resetting':
        return (
          <>
            <SymbolIcon /> Resetting
          </>
        );
      case 'resetted':
        return (
          <>
            <CheckIcon /> Reset!
          </>
        );
      default:
        return <>Reset</>;
    }
  })();

  if (!tenant) {
    return <TextSubSection>Loading</TextSubSection>
  }

  return (
    <>
      <PageArea>
        <TextSubSection>Design Parameter Board</TextSubSection>
        <WrapperHorizontalCustom>
          <CustomButton
            background={isVisible ? BACKGROUND_GREEN : BACKGROUND_LIGHT}
            color={isVisible ? COLOR_GREEN : COLOR_LIGHT}
            onClick={() => { setIsVisible(!isVisible) }}>
            {isVisible ? <ArrowUpIcon /> : <ArrowDownIcon />}Toggle Parameter Board
          </CustomButton>
        </WrapperHorizontalCustom>
        {isVisible && <>
          <WrapperVerticalLeft style={{ marginTop: SIZE_MARGIN_LARGE }}>
            <TextSection>On/Off Settings (make sure to press 'Save' to effectuate changes)</TextSection>
            <WrapperVerticalLeft>
              {groupedParameters && groupedParameters['boolean'] && groupedParameters['boolean'].map((param) => (
                <CustomTooltip
                  key={param.key}
                  triggerElement={
                    <CustomCheckbox
                      id={param.key}
                      isChecked={param.value === 'True'}
                      label={param.label}
                      onCheckChange={handleCheckboxChange}
                    />
                  }
                  contentElement={<Tooltip>{param.description}</Tooltip>}
                />
              ))}
            </WrapperVerticalLeft>
          </WrapperVerticalLeft>

          <WrapperVerticalLeft style={{ marginTop: SIZE_MARGIN_LARGE }}>
            <TextSection>Color Settings (make sure to press 'Save' to effectuate changes)</TextSection>
            <WrapperVerticalLeft>
              {groupedParameters && groupedParameters['color'] && groupedParameters['color'].map((param) => (
                <ColorSetting
                  key={param.key}
                  param={param}
                  onColorChange={handleColorChange}
                />
              ))}
            </WrapperVerticalLeft>
          </WrapperVerticalLeft>

          <WrapperVerticalLeft style={{ marginTop: SIZE_MARGIN_LARGE }}>
            <TextSection>Save your edits</TextSection>
            <WrapperHorizontal>
              <CustomButton
                color={INTERACT_COLOR}
                hoverColor={INTERACT_COLOR}
                background={INTERACT_BACKGROUND}
                hoverBackground={HOVER_BACKGROUND_INTERACT}
                disabled={['idle', 'saving', 'resetting', 'resetted', 'saved'].includes(status)}
                onClick={handleSave}
              >
                {saveButtonText}
              </CustomButton>
              <CustomButton
                color={INTERACT_COLOR}
                hoverColor={INTERACT_COLOR}
                background={INTERACT_BACKGROUND}
                hoverBackground={HOVER_BACKGROUND_INTERACT}
                disabled={['resetted'].includes(status)}
                onClick={handleReset}
              >
                {resetButtonText}
              </CustomButton>
            </WrapperHorizontal>
          </WrapperVerticalLeft>
        </>}
      </PageArea>
    </>
  );
};

export default TenantSettings;
