import { GlobeIcon } from '@radix-ui/react-icons';
import React from 'react';
import CustomButton from '../../../_reusable/CustomButton';

const TranslationButton = React.forwardRef((props, ref) => {
  return (
    <CustomButton ref={ref} {...props}>
      <GlobeIcon />
      Translation
    </CustomButton>
  );
});

export default TranslationButton;
