import styled from '@emotion/styled';
import {
    SIZE_GAP_SMALL,
    SIZE_GAP_MEDIUM,
    SIZE_PADDING_SMALL,
    SIZE_PADDING_MEDIUM,
    SIZE_PADDING_LARGE,
    SIZE_MARGIN_MEDIUM,
    SIZE_MARGIN_LARGE,
    SIZE_FONT_SMALL,
    SIZE_FONT_MEDIUM,
    SIZE_FONT_LARGE,
    SIZE_FONT_WEIGHT_SMALL,
    SIZE_FONT_WEIGHT_MEDIUM,
    SIZE_BORDER_RADIUS,
    COLOR,
    BACKGROUND,
    BACKGROUND_RED,
    COLOR_LIGHT,
    COLOR_RED,
    HEIGHT_MEDIA_STANDARD_PIXEL,
    HOVER_BACKGROUND} from './theme';

/* ------ TEMPLATE ------ */
const TEMPLATE_WRAPPER_VERTICAL = `
    display: flex;
    flex-direction: column;
    gap: ${SIZE_GAP_MEDIUM};
`

const TEMPLATE_WRAPPER_VERTICAL_STRETCH = `
    ${TEMPLATE_WRAPPER_VERTICAL}
    flex-grow: 1;
`;

const TEMPLATE_WRAPPER_HORIZONTAL = `
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
`
/*const TEMPLATE_WRAPPER_HORIZONTAL_STRETCH = `
    ${TEMPLATE_WRAPPER_HORIZONTAL}
    flex-grow: 1;
`;*/

// img
const Image = styled.img`
  height: ${HEIGHT_MEDIA_STANDARD_PIXEL};
  width: ${HEIGHT_MEDIA_STANDARD_PIXEL};
  object-fit: cover;
  cursor: pointer;
`;

// h*
const TextPageHeaderColor = styled.h1`
    color: ${COLOR};
`;

// div-text

const TextSection = styled.div`
    color: ${COLOR};
    font-size: ${SIZE_FONT_LARGE};
    font-weight: ${SIZE_FONT_WEIGHT_MEDIUM};
    padding-bottom: ${SIZE_PADDING_SMALL};
`;

const TextSubSection = styled.div`
    color: ${COLOR};
    font-size: ${SIZE_FONT_MEDIUM};
    font-weight: ${SIZE_FONT_WEIGHT_MEDIUM};
    padding-bottom: ${SIZE_PADDING_SMALL};
`;

const TextNormal = styled.div`
    color: ${COLOR};
    font-size: ${SIZE_FONT_SMALL};
    font-weight: ${SIZE_FONT_WEIGHT_SMALL};
`;

// input
const FileInput = styled.input`
  display: none;
`;

// div

const PageArea = styled.div`
    margin-top: ${SIZE_MARGIN_LARGE};
    margin-bottom: ${SIZE_MARGIN_MEDIUM};
`;

const ImageArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${SIZE_GAP_MEDIUM};
  flex-wrap: wrap;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${SIZE_GAP_MEDIUM};
`;

const WrapperHorizontal = styled.div`
    ${TEMPLATE_WRAPPER_HORIZONTAL}
    padding: ${SIZE_PADDING_MEDIUM}';
`;

const WrapperHorizontalCustom = styled.div`
    ${TEMPLATE_WRAPPER_HORIZONTAL}
    flex-wrap: wrap;
    justify-content: left;
    align-items: left;
    gap: 10px;
    padding: ${SIZE_PADDING_SMALL};
    font-size: ${SIZE_FONT_MEDIUM};
    color: ${COLOR};
`;

const WrapperVerticalCenter = styled.div`
    ${TEMPLATE_WRAPPER_VERTICAL_STRETCH}
    flex-wrap: wrap;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
    padding: ${SIZE_PADDING_SMALL};
`;

const WrapperVerticalLeft = styled.div`
    ${TEMPLATE_WRAPPER_VERTICAL}
    text-align: left;
    align-items: left;
`;

const WrapperVerticalLeftStretch = styled.div`
    ${TEMPLATE_WRAPPER_VERTICAL_STRETCH}
    text-align: left;
    align-items: left;
    justify-content: space-between;
`;

const WrapperVerticalAdmin = styled.div`
    ${TEMPLATE_WRAPPER_VERTICAL_STRETCH}
    text-align: justify; /* For Edge */
    text-align: right;
    align-items: right;
    color: ${COLOR_LIGHT};
`;

const WrapperPicker = styled.div`
    display: flex;
    flex-direction: column;  
    position: absolute;
    gap: ${SIZE_GAP_MEDIUM};
    left: ${SIZE_FONT_WEIGHT_SMALL};
    border: 1px solid ${BACKGROUND};
`;

const MediaPlaceholder = styled.div`
    background: ${BACKGROUND_RED};
    width: ${HEIGHT_MEDIA_STANDARD_PIXEL};
    height: ${HEIGHT_MEDIA_STANDARD_PIXEL};
    display: flex;
    text-align: center;
    align-items: center;
    font-weight: ${SIZE_FONT_WEIGHT_MEDIUM};
    color: ${COLOR_RED};
`;

const Tooltip = styled.div`
    width: 240px;    
    padding: ${SIZE_PADDING_LARGE};
    border-radius: ${SIZE_BORDER_RADIUS};
    background: rgba(0, 0, 0, 0.6);
    color: white;
    font-size: ${SIZE_FONT_SMALL};
    font-weight: ${SIZE_FONT_WEIGHT_MEDIUM};
`;

const CardWrapper = styled.div`
    ${TEMPLATE_WRAPPER_VERTICAL}
    padding: ${SIZE_PADDING_LARGE};
    background: ${(props) => props.background || 'white'};
    overflow: hidden;
    font-size: ${SIZE_FONT_MEDIUM};

    &:hover {
    background: ${(props) => props.hover_background || BACKGROUND};
    border-radius: ${SIZE_GAP_SMALL};
    }
`;

const UploadFileLabel = styled.label`
  all: unset;
  display: inline-flex;
  //width: 120px;
  background: ${(props) => props.background || BACKGROUND};
  gap: ${SIZE_GAP_SMALL};
  transition: all 0.06s;
  cursor: pointer;
  align-items: center;
  justify-content: left;
  color: ${(props) => props.color || COLOR};
  font-family: inherit;
  font-weight: ${SIZE_FONT_WEIGHT_MEDIUM};
  font-size: ${SIZE_FONT_MEDIUM};
  border-radius: ${SIZE_BORDER_RADIUS};
  padding: 8px 12px;
  width: ${(props) => props.width || null};
  &:hover {
    background: ${HOVER_BACKGROUND};
  }
`;



export {
    Image,
    TextPageHeaderColor,
    TextSection,
    TextSubSection,
    TextNormal,
    FileInput,
    ContentWrapper,
    PageArea,
    ImageArea,
    WrapperHorizontal,
    WrapperHorizontalCustom,
    WrapperVerticalCenter,
    WrapperVerticalLeft,
    WrapperVerticalLeftStretch,
    WrapperVerticalAdmin,
    WrapperPicker,
    MediaPlaceholder,
    Tooltip,
    CardWrapper,
    UploadFileLabel,
};
