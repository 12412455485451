import styled from '@emotion/styled';
import { useContext, useEffect } from 'react';
import {
  ArticleDispatchContext,
  updateRecable,
} from '../../providers/ArticleProvider';
import EditArea from './EditArea';
import NavMenu from './NavMenu';

const DialogWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  height: 100%;
`;

// has article when it is an existing article
const Article = ({ article, onClose }) => {
  const dispatch = useContext(ArticleDispatchContext);

  useEffect(() => {
    if (article) dispatch(updateRecable(article));
  }, [article, dispatch]);

  return (
    <DialogWrapper>
      <NavMenu onClose={onClose} urlpicture={article?.urlpicture || ''} />
      <EditArea />
    </DialogWrapper>
  );
};

export default Article;
