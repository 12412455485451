import { useEffect, useState, useContext } from 'react';
import { recable } from '../../../../../api';
import { getRecable } from '../../../../../api/recable';
import {
  ArticleDispatchContext,
  ArticleStateContext,
  updateCurrentProperties,
} from '../../../../../providers/ArticleProvider';
import { GlobalStateContext } from '../../../../../providers/GlobalProvider';
import { ProjectStateContext } from '../../../../../providers/ProjectProvider';

import CustomSelect from '../../../../_reusable/CustomSelect';
import ArticleLinkButton from './ArticleLinkButton';
import ArticleLinkSelect from './ArticleLinkSelect';

const ArticleLink = ({ option }) => {
  const property = option.associatedRecableInfo;
  const { article, currentProperties } = useContext(ArticleStateContext);
  const { user, tenant, currentLangId } = useContext(GlobalStateContext);
  const articleDispatch = useContext(ArticleDispatchContext);
  const { id: projectId } = useContext(ProjectStateContext);
  const [options, setOptions] = useState([]);
  const [linkedRecableId, setLinkedRecableId] = useState();

  useEffect(() => {
    const targetProperty = currentProperties.find(
      (p) => p.recable_attribute_id === property.recable_attribute_id
    );
    if (
      Boolean(targetProperty?.recable_info_enhancement_recable_reference >= 0)
    ) {
      setLinkedRecableId(
        targetProperty.recable_info_enhancement_recable_reference
      );
    }
  }, [currentProperties, setLinkedRecableId, property.recable_attribute_id]);

  useEffect(() => {
    if (
      tenant.tenant_id &&
      user.id &&
      projectId &&
      option.associatedRecableInfo
    ) {
      getRecable({
        langId: currentLangId,
        userId: user.id,
        tenantId: tenant.tenant_id,
      })
        .then((res) => {
          const allArticles = res.data.result;
          const linkableArticles = allArticles.filter(
            (article) =>
              article.recable_type_id ===
              option.associatedRecableInfo
                .recable_attribute_associated_recable_type_id &&
              article.project_id === parseInt(projectId, 10)
          );
          setOptions(linkableArticles);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [option, currentLangId, tenant, user, setOptions, projectId]);

  const handleArticleLink = (recableId) => {
    setLinkedRecableId(recableId);
    const getRecable = options.find(
      (article) => article.recable_id === recableId
    );

    const data = {
      recable_attribute_id: property.recable_attribute_id,
      value: getRecable.name || '',
      column_value_type_list: [
        {
          column: 'recable_info_enhancement_recable_reference',
          value: getRecable.recable_id.toString(),
          type: 'numeric',
        },
      ],
    };

    recable
      .createRecableInfo({
        data,
        projectId,
        langId: currentLangId,
        recableId: article.recable_id,
        userId: user.id,
      })
      .then(() =>
        recable.getRecableInfo({
          recableId: article.recable_id,
          langId: currentLangId,
          projectId,
        })
      )
      .then((res) => {
        articleDispatch(updateCurrentProperties(res.data.result));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <CustomSelect
      value={linkedRecableId}
      triggerElement={
        <ArticleLinkButton
          value={linkedRecableId}
          option={option}
          options={options}
        />
      }
      onSelect={handleArticleLink}
      contentElement={<ArticleLinkSelect options={options} />}
      contentSize={200}
    />
  );
};

export default ArticleLink;
