import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import ChipCell from './Table/Cells/Chip';
import Table from './Table/Table';
import DateCell from './Table/Cells/Date';
import TopBar from './TopBar';
import {
  newProjectId,
  ProjectDispatchContext,
  ProjectStateContext,
  updateeditModeGetRecableIds,
} from '../../providers/ProjectProvider';
import Article from '../Article';
import CustomDialog from '../_reusable/CustomDialog';
import ImageCell from './Table/Cells/Image';
import CustomCheckbox from '../_reusable/CustomCheckbox';
import ArticleTypeIconCell from './Table/Cells/ArticleTypeIcon';
import ReadOnlyRichText from '../RichText/ReadOnlyRichText';
import Paragraph from './Table/Cells/Paragraph';

const ScreenTitle = styled.h1`
  padding-left: 4px;
`;

const EmptyTableMessage = styled.div`
  margin-top: 32px;
  font-size: 14px;
  font-weight: 600;
  color: inherit;
`;

const DEFAULT_ARTICLE_COLUMNS = [
  {
    Header: 'Type',
    accessor: 'recable_type_name',
    width: 90,
    Cell: ({ cell, row }) => <ArticleTypeIconCell cell={cell} row={row} />,
  },
  {
    Header: 'Cover',
    accessor: 'urlpicture',
    width: 120,
    Cell: ({ cell }) => <ImageCell cell={cell} />,
  },
  { Header: 'Name', accessor: 'name', width: 240 },
  { Header: 'Description', accessor: 'descriptionshort', width: 240,
    Cell: ({ cell }) => <Paragraph cell={cell} />,
  },
  {
    Header: 'Last modified at',
    accessor: 'lastmodifiedat',
    width: 200,
    Cell: ({ cell }) => <DateCell cell={cell} />,
  },
  {
    Header: 'Last translated at',
    accessor: 'lasttranslatedat',
    width: 200,
    Cell: ({ cell }) => <DateCell cell={cell} />,
  },
  {
    Header: 'Translated languages',
    accessor: 'lasttranslated_num_lang',
    Cell: ({ cell }) => <ChipCell cell={cell} />,
    width: 240,
  },
];

const Project = () => {
  const { projectID } = useParams();
  const dispatch = useContext(ProjectDispatchContext);
  const {
    articles: allArticles,
    info,
    editMode,
    editModeGetRecableIds,
    status,
    filters,
  } = useContext(ProjectStateContext);
  const [selectedArticle, setSelectedArticle] = useState();

  const articles = useMemo(() => {
    if (!filters || !filters[1]) {
      return allArticles;
    }
    const [filterKey, filterValue] = filters;
    return allArticles.filter((article) =>
      article[filterKey]?.toLowerCase().includes(filterValue.trim().toLowerCase())
    );
  }, [allArticles, filters]);

  const handleArticleCheckChange = useCallback(
    ({ key }) => {
      dispatch(updateeditModeGetRecableIds(key));
    },
    [dispatch]
  );

  const CHECKBOX_COLUMN = useMemo(
    () => ({
      Header: <span>&nbsp;</span>,
      accessor: 'ui_select',
      width: 20,
      Cell: ({ cell }) => {
        const article = cell.row.original;
        const isChecked = editModeGetRecableIds.includes(article.recable_id);
        return (
          <CustomCheckbox
            id={article.recable_id}
            isChecked={isChecked}
            onCheckChange={handleArticleCheckChange}
          />
        );
      },
    }),
    [editModeGetRecableIds, handleArticleCheckChange]
  );

  const articleColumns = useMemo(() => {
    if (editMode) return [CHECKBOX_COLUMN, ...DEFAULT_ARTICLE_COLUMNS];
    return DEFAULT_ARTICLE_COLUMNS;
  }, [editMode, CHECKBOX_COLUMN]);

  const table = {
    columns: articleColumns,
    data: articles,
  };

  useEffect(() => {
    if (projectID) {
      dispatch(newProjectId(projectID));
    }
  }, [dispatch, projectID]);

  const handleOpenArticle = ({ event, rowValue }) => {
    const { id: cellId } = event.target;
    if (!cellId) {
      // maybe read-only text
      setSelectedArticle(rowValue);
    } else if (editMode) {
      handleArticleCheckChange({
        key: rowValue.recable_id,
      });
    } else {
      setSelectedArticle(rowValue);
    }
  };

  const handleCloseArticle = () => {
    setSelectedArticle(null);
  };

  const tablePlaceholderMessage = useMemo(() => {
    if (!status) return 'Project is loading...';
    if (!articles.length) return 'Project has no articles yet.';
    return 'Project loaded successfully.';
  }, [status, articles]);

  return (
    <>
      <ScreenTitle>{info?.project_name}</ScreenTitle>
      <TopBar />
      {table.data.length ? (
        <Table
          columns={table.columns}
          data={table.data}
          onRowEvent={handleOpenArticle}
        />
      ) : (
        <EmptyTableMessage>{tablePlaceholderMessage}</EmptyTableMessage>
      )}
      <CustomDialog
        open={Boolean(selectedArticle)}
        onClose={handleCloseArticle}
        size="100"
        contentElement={
          <Article article={selectedArticle} onClose={handleCloseArticle} />
        }
      />
    </>
  );
};

export default Project;
