import * as Tooltip from '@radix-ui/react-tooltip';

export default ({ triggerElement, contentElement }) => (
  <Tooltip.Provider delayDuration={20} skipDelayDuration={600}>
    <Tooltip.Root>
      <Tooltip.Trigger asChild>{triggerElement}</Tooltip.Trigger>
      <Tooltip.Content asChild side="left" align="center" sideOffset={6}>
        {contentElement}
      </Tooltip.Content>
    </Tooltip.Root>
  </Tooltip.Provider>
);
