import styled from '@emotion/styled';

import { useContext, useRef, useState } from 'react';
import { CheckIcon } from '@radix-ui/react-icons';

import CustomButton from '../../_reusable/CustomButton';
import CustomInput from '../../_reusable/CustomInput';
import {
    HOVER_BACKGROUND,
    BACKGROUND_GREEN,
    BACKGROUND_RED,
    HOVER_BACKGROUND_RED,
    HOVER_BACKGROUND_GREEN,
    COLOR_RED,
    COLOR_GREEN,
} from '../../_reusable/theme';

import { GlobalStateContext } from '../../../providers/GlobalProvider';
import { createPhoneme, updatePhoneme } from '../../../api/phoneme';
import { TextSubSection, CardWrapper } from '../../_reusable/styles';
import { AZURE_SPEECH_SERVICE_KEYS } from '../../../api/dataSource';
import * as sdk from 'microsoft-cognitiveservices-speech-sdk';


const PhonemeCard = ({ setIsMode, isModeCreate, setIsUpdateListPhoneme, phonemeId, phonemeTextOriginalInitial = '', phonemeTextPhonemeInitial = '' }) => {

    const { tenant, user, currentLangId, availableLanguages } = useContext(GlobalStateContext);
    const [audioPhoneme, setAudioPhoneme] = useState();
    const [errors, setErrors] = useState({phonemeText: '', phonemeOriginal: "", record: ""});
    const formPhoneme = useRef();

    const validatePhoneme = (phonemePh, phonemeText) => {
        setErrors({phonemeText: '', phonemeOriginal: "", record: ""});
        if (phonemePh.trim().length === 0) {
            setErrors(old => ({...old, phonemeText: "Please enter a valid phoneme"}));
        }

        if (phonemeText.trim().length === 0) {
            setErrors(old => ({...old, phonemeOriginal: "Please enter a original text"}));
        }

        if (errors.phonemeText !== "" || errors.phonemeOriginal !== "") {
            return;
        }

        const speechConfig = sdk.SpeechConfig.fromSubscription(
          AZURE_SPEECH_SERVICE_KEYS.dev.key1,
          AZURE_SPEECH_SERVICE_KEYS.dev.location
        );

        speechConfig.speechSynthesisOutputFormat = sdk.SpeechSynthesisOutputFormat.Audio16Khz32KBitRateMonoMp3;

        const currentLang = availableLanguages.filter(lang => lang.lang_id === currentLangId)[0];

        const voice_name = currentLang?.lang_azure_neuralvoice0
        const prosody_rate = currentLang?.lang_azure_pitch0
        const pitch = currentLang?.lang_azure_rate0

        const phoneme = `<phoneme alphabet="ipa" ph="${phonemePh.trim()}">${phonemeText.trim()}</phoneme>`
        const speech_xml_inner = `<voice name="${voice_name}"><prosody rate="${prosody_rate}%" pitch="${pitch}%">${phoneme}</prosody></voice>`
        const ssml_string = `<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="en-US">${speech_xml_inner}</speak>`

        console.log(ssml_string)
        const synthesizer = new sdk.SpeechSynthesizer(speechConfig, null);
        synthesizer.speakSsmlAsync(
          ssml_string,
          result => {
              // Interact with the audio ArrayBuffer data
              if (result.reason === sdk.ResultReason.SynthesizingAudioCompleted) {
                  console.log(JSON.stringify(result));
                  const audioData = result.audioData;
                  const blob = new Blob([audioData]);
                  setAudioPhoneme(URL.createObjectURL(blob));
                  console.log(audioData)
                  console.log(`Audio data byte size: ${audioData.byteLength}.`)
              }
              else {
                  setErrors(old => ({...old, record: "Speech synthesis canceled, please provide a valid phoneme"}));
                  console.error("Speech synthesis canceled, " + result.errorDetails +
                    "\nDid you set the speech resource key and region values?" + result.reason + " reason");
              }

              synthesizer.close();
          },
          error => {
              console.log(error);
              synthesizer.close();
          });
    }

    const handleCreatePhoneme = () => {
        const { phonemeTextOriginal, phonemeTextPhoneme } = formPhoneme.current;
        validatePhoneme(phonemeTextPhoneme.value, phonemeTextOriginal.value);
        createPhoneme({
            tenantId: tenant.tenant_id,
            userId: user.id,
            data: {
                phoneme_text_original: phonemeTextOriginal.value.trim(),
                phoneme_text_phoneme: phonemeTextPhoneme.value.trim(),
            }
        }).then(() => {
            setIsMode(false);
            setIsUpdateListPhoneme(true);
        })
            .catch((error) => {
                console.error(error);
            });
    }

    const handleSubmitPhoneme = (event) => {
        event.preventDefault();
        const { phonemeTextOriginal, phonemeTextPhoneme } = formPhoneme.current;
        if (isModeCreate) {
            validatePhoneme(phonemeTextPhoneme.value, phonemeTextOriginal.value);
        } else {
            updatePhoneme({
                phonemeId: phonemeId,
                userId: user.id,
                data: {
                    phoneme_text_original: phonemeTextOriginal.value,
                    phoneme_text_phoneme: phonemeTextPhoneme.value,
                }
            }).then(() => {
                setIsMode(false);
                setIsUpdateListPhoneme(true);
            })
                .catch((error) => {
                    console.error(error);
                });
        }
    }

    const handleCancelPhoneme = () => {
        setIsMode(false);
    }

    return (
        <CardWrapper background={HOVER_BACKGROUND_GREEN}>
            <TextSubSection>{isModeCreate ? 'Create new phoneme' : 'Edit phoneme'}</TextSubSection>
            {errors.record && <p style={{marginBottom: 10, color: COLOR_RED}}>{errors.record}</p>}
            <form ref={formPhoneme} style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', padding: '5px', gap: '8px' }}>
                <div style={{flex: 1}}>
                    {errors.phonemeOriginal && <p style={{marginBottom: 10, color: COLOR_RED}}>{errors.phonemeOriginal}</p> }
                   <div style={{display: 'flex', alignItems: "center"}}>
                       Word or phrase: <CustomInput name="phonemeTextOriginal" defaultValue={phonemeTextOriginalInitial} />
                   </div>
                </div>
                <div style={{flex: 1}}>
                    {errors.phonemeText && <p style={{marginBottom: 10, color: COLOR_RED}}>{errors.phonemeText}</p>}
                    <div style={{display: 'flex', alignItems: "center"}}>
                        Phoneme: <CustomInput name="phonemeTextPhoneme" defaultValue={phonemeTextPhonemeInitial} />
                    </div>
                </div>
                <CustomButton
                    background={BACKGROUND_GREEN}
                    hover_background={HOVER_BACKGROUND_GREEN}
                    color={COLOR_GREEN}
                    width='75px'
                    onClick={handleSubmitPhoneme}
                    disable={true}>
                    {<><CheckIcon />{isModeCreate ? "Validate" : "Save"}</>}
                </CustomButton>
                {!isModeCreate && <CustomButton
                  background={BACKGROUND_RED}
                  hover_background={HOVER_BACKGROUND_RED}
                  color={COLOR_RED}
                  width='75px'
                  onClick={handleCancelPhoneme}
                  disable={true}>
                    {<><CheckIcon />Cancel</>}
                </CustomButton>}
            </form>
            {isModeCreate && audioPhoneme && <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                <audio controls src={audioPhoneme} />
                <div style={{display: "flex", gap: 5}}>
                    <CustomButton
                      background={BACKGROUND_GREEN}
                      hover_background={HOVER_BACKGROUND_GREEN}
                      color={COLOR_GREEN}
                      width='75px'
                      onClick={handleCreatePhoneme}
                      disable={true}>
                        {<><CheckIcon />Save</>}
                    </CustomButton>
                    <CustomButton
                      background={BACKGROUND_RED}
                      hover_background={HOVER_BACKGROUND_RED}
                      color={COLOR_RED}
                      width='75px'
                      onClick={handleCancelPhoneme}
                      disable={true}>
                        {<><CheckIcon />Cancel</>}
                    </CustomButton>
                </div>
            </div>}
        </CardWrapper >
    );
};

export default PhonemeCard;
