import React, { useEffect, useContext, useState } from 'react';
import styled from '@emotion/styled';
import CreateArticle from './CreateArticle';
import EditMode from './EditMode';
import Filter from './Filter';
import Sort from './Sort';
import { recable as recableAPI } from '../../../api';
import { GlobalStateContext } from '../../../providers/GlobalProvider';

const TopBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const TopBar = () => {
  const [options, setOptions] = useState([]);
  const { currentLangId } = useContext(GlobalStateContext);
  useEffect(() => {
    recableAPI
      .getRecableAttribute({ langId: currentLangId })
      .then((res) => {
        setOptions(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [currentLangId, setOptions]);

  return (
    <TopBarWrapper>
      <span>
        <CreateArticle />
      </span>
      <span>
        <Filter options={options} />
      </span>
      {/* <span>
        <Sort />
      </span> */}
      <EditMode />
    </TopBarWrapper>
  );
};

export default TopBar;
