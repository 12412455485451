import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { ArticleStateContext } from '../../../providers/ArticleProvider';
import { GlobalStateContext } from '../../../providers/GlobalProvider';
import { ProjectStateContext } from '../../../providers/ProjectProvider';
import Translation from './Translation';
import Synthesization from './Synthesization';
import QRCodeView from '../../_reusable/CustomQRCode';
import { COLOR_LIGHT } from '../../_reusable/theme';

const BottomMenuAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  gap: 8px;
  width: 100%;
`;

const Separator = styled.div`
  margin-top: 3px;
  height: 3px;
  width: 100%;
  background-color: ${({ color }) => color || COLOR_LIGHT};
`;

const BottomMenuArea = ({ urlpicture = '' }) => {
  const { isNew } = useContext(ArticleStateContext);

  const { id: projectId } = useContext(ProjectStateContext);
  const { tenant, currentLangId } = useContext(GlobalStateContext);
  const { article } = useContext(ArticleStateContext);
  const webappLinkBasisProject = `${tenant.tenant_domain}/${currentLangId}/project/${projectId}`
  const webAppLink = article?.recable_id ?
    `${webappLinkBasisProject}/recable/${article.recable_id}/recableType/${article.recable_type_name}`
    : webappLinkBasisProject;

  return (
    <BottomMenuAreaWrapper>
      {!isNew && (
        <>
          <Translation />
          <Synthesization />
          <Separator />
          <QRCodeView webAppLink={webAppLink} urlpicture={urlpicture} />
        </>
      )}
    </BottomMenuAreaWrapper>
  );
};

export default BottomMenuArea;
