import React from 'react';
import { styled } from '@stitches/react';
import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';
import { BACKGROUND, COLOR_LIGHT, HOVER_BACKGROUND } from './theme';

const SCROLLBAR_SIZE = 10;

const StyledScrollArea = styled(ScrollAreaPrimitive.Root, {
  borderRadius: 4,
  overflow: 'hidden',
  background: 'white',
});

const StyledViewport = styled(ScrollAreaPrimitive.Viewport, {
  width: '100%',
  height: '100%',
  borderRadius: 'inherit',
});

const StyledScrollbar = styled(ScrollAreaPrimitive.Scrollbar, {
  display: 'flex',
  // ensures no selection
  userSelect: 'none',
  // disable browser handling of all panning and zooming gestures on touch devices
  touchAction: 'none',
  padding: 2,
  background: BACKGROUND,
  transition: 'background 60ms ease-out',
  '&:hover': { background: HOVER_BACKGROUND },
  '&[data-orientation="vertical"]': { width: SCROLLBAR_SIZE },
  '&[data-orientation="horizontal"]': {
    flexDirection: 'column',
    height: SCROLLBAR_SIZE,
  },
});

const StyledThumb = styled(ScrollAreaPrimitive.Thumb, {
  flex: 1,
  background: COLOR_LIGHT,
  borderRadius: SCROLLBAR_SIZE,
  // increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    minWidth: 44,
    minHeight: 44,
  },
});

const StyledCorner = styled(ScrollAreaPrimitive.Corner, {
  background: BACKGROUND,
});

export const ScrollArea = StyledScrollArea;
export const ScrollAreaViewport = StyledViewport;
export const ScrollAreaScrollbar = StyledScrollbar;
export const ScrollAreaThumb = StyledThumb;
export const ScrollAreaCorner = StyledCorner;

const CustomScrollArea = ({ children, noHorizontal=false }) => (
  <ScrollArea>
    <ScrollAreaViewport>{children}</ScrollAreaViewport>
    <ScrollAreaScrollbar orientation="vertical">
      <ScrollAreaThumb />
    </ScrollAreaScrollbar>
    {!noHorizontal && <ScrollAreaScrollbar orientation='horizontal'>
      <ScrollAreaThumb />
    </ScrollAreaScrollbar>}
    <ScrollAreaCorner />
  </ScrollArea>
);

export default CustomScrollArea;
