import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { COLOR } from '../../../_reusable/theme';
import {
  ArticleDispatchContext,
  ArticleStateContext,
  updateRecable,
} from '../../../../providers/ArticleProvider';
import CustomTooltip from '../../../_reusable/CustomTooltip';
import CustomCheckbox from '../../../_reusable/CustomCheckbox';
import { recable as recableAPI, tenant } from '../../../../api';
import { GlobalStateContext } from '../../../../providers/GlobalProvider';
import { ProjectStateContext } from '../../../../providers/ProjectProvider';

const DialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: ${COLOR};
`;

const OptionArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 8px;
`;

const Tooltip = styled.div`
  border-radius: 4px;
  padding: 12px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  font-size: 12px;
  font-weight: 500;
  width: 240px;
`;

const Content = React.forwardRef((props, ref) => {
  const { article } = useContext(ArticleStateContext);
  const articleDispatch = useContext(ArticleDispatchContext);
  const { user, tenant, currentLangId } = useContext(GlobalStateContext);
  const { id: projectId } = useContext(ProjectStateContext);

  const handleToggleSetting = ({ key, value }) => {
    recableAPI
      .updateRecable({
        recableId: article.recable_id,
        userId: user.id,
        data: {
          [key]: value,
        },
      })
      .then(() =>
        recableAPI.getRecable({
          recableId: article.recable_id,
          langId: currentLangId,
          projectId,
          tenantId: tenant.tenant_id,
        })
      )
      .then((res) => {
        articleDispatch(updateRecable(res.data.result));
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <DialogWrapper {...props} ref={ref}>
      <Title>Settings</Title>
      <OptionArea>
        {article?.parameters?.parameter.map((param) => {
          return (
            <CustomTooltip
              key={param.key}
              triggerElement={
                <CustomCheckbox
                  id={param.key}
                  isChecked={param.value === 'True'}
                  label={param.label}
                  onCheckChange={handleToggleSetting}
                />
              }
              contentElement={<Tooltip>{param.description}</Tooltip>}
            />
          );
        })}
      </OptionArea>
    </DialogWrapper>
  );
});

export default Content;
