import styled from '@emotion/styled';
import { useState } from 'react';
import { BACKGROUND_LIGHT, SHAIRE_COLOR } from '../_reusable/theme';
import ForgotPassword from './ForgotPassword';
import SignInEmailPassword from './SignInEmailPassword';

const ScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const SignInCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 80px;
  border-radius: 4px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px, 0 0 0 2px ${BACKGROUND_LIGHT};
  background: white;
  width: 240px;
  height: 400px;
`;

const Title = styled.div`
  color: ${SHAIRE_COLOR};
  font-size: 24px;
  font-weight: 800;
  display: flex;
  justify-content: center;
`;

const SignIn = () => {
  const [isForgot, setIsForgot] = useState(false);
  return (
    <ScreenWrapper>
      <SignInCard>
        <Title>Liara.</Title>
        {isForgot ?
          <ForgotPassword setIsForgot={setIsForgot} />
          :
          <SignInEmailPassword setIsForgot={setIsForgot} />
        }
      </SignInCard>
    </ScreenWrapper>
  );
};

export default SignIn;
