import { styled } from '@stitches/react';
import { CheckIcon } from '@radix-ui/react-icons';
import * as SelectPrimitive from '@radix-ui/react-select';
import { BACKGROUND, COLOR, COLOR_LIGHT } from './theme';

const StyledViewport = styled(SelectPrimitive.Viewport, {
  padding: 5,
});

const StyledContent = styled(SelectPrimitive.Content, {
  overflow: 'hidden',
  width: 240,
  backgroundColor: 'white',
  borderRadius: 4,
  boxShadow:
    '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
  variants: {
    size: {
      140: {
        width: 140,
      },
      200: {
        width: 200,
      },
      240: {
        width: 240,
      },
    },
  },
});

const StyledItem = styled(SelectPrimitive.Item, {
  all: 'unset',
  display: 'flex',
  fontSize: 14,
  fontWeight: 400,
  lineHeight: 1,
  color: COLOR,
  borderRadius: 4,
  alignItems: 'center',
  alignContent: 'center',
  height: 32,
  padding: '0 32px 0 24px',
  position: 'relative',
  userSelect: 'none',
  cursor: 'pointer',

  '&[data-disabled]': {
    color: COLOR_LIGHT,
    pointerEvents: 'none',
  },

  '&:focus': {
    backgroundColor: BACKGROUND,
    color: COLOR,
  },
});

const StyledItemIndicator = styled(SelectPrimitive.ItemIndicator, {
  position: 'absolute',
  left: 0,
  width: 25,
  display: 'inline-flex',
  alignItems: 'center',
  gap: 8,
});

export const Select = SelectPrimitive.Root;
export const SelectTrigger = SelectPrimitive.Trigger;
export const SelectValue = SelectPrimitive.Value;
export const SelectIcon = SelectPrimitive.Icon;
export const SelectContent = StyledContent;
export const SelectViewport = StyledViewport;
export const SelectGroup = SelectPrimitive.Group;
export const SelectItem = StyledItem;
export const SelectItemText = SelectPrimitive.ItemText;
export const SelectItemIndicator = StyledItemIndicator;

export const CustomSelectItem = ({ label, value, icon, ...props }) => {
  return (
    <SelectItem value={value} {...props}>
      <SelectItemText>
        {icon} {label}
      </SelectItemText>
      <SelectItemIndicator>
        <CheckIcon />
      </SelectItemIndicator>
    </SelectItem>
  );
};

const CustomSelect = ({
  value,
  triggerElement,
  contentElement,
  onSelect,
  contentSize,
  disabled,
}) => {
  return (
    <Select value={value} onValueChange={onSelect}>
      <SelectTrigger asChild disabled={disabled}>
        {triggerElement}
      </SelectTrigger>
      <SelectContent size={contentSize}>{contentElement}</SelectContent>
    </Select>
  );
};

export default CustomSelect;
