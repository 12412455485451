import React from 'react';
import { styled } from '@mui/system';
import muiChip from '@mui/material/Chip';

const CustomChip = styled(muiChip)({
  marginTop: '2px',
  marginRight: '2px',
  borderRadius: '4px',
  transition: 'background 100ms, color 100ms',
  fontWeight: '600',
  color: 'rgba(55, 53, 47, 0.8)',
});

const vibeColor = {
  purple: '#EAE6FF',
  default: 'inherit',
};

const vibeTextColor = {
  purple: '#403294',
  default: 'inherit',
};

const Chip = ({ text, vibe }) => {
  const label = text.toString().toUpperCase();
  return (
    <CustomChip
      label={label}
      size="small"
      sx={{
        backgroundColor: vibeColor[vibe || 'inherit'],
        color: vibeTextColor[vibe || 'inherit'],
      }}
    />
  );
};

export default function ChipCell({ cell, vibe }) {
  if (!cell.value) return null;
  return (
    <Chip key={`${cell.row.id}-${cell.value}`} text={cell.value} vibe={vibe} />
  );
}
