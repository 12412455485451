import styled from '@emotion/styled';
import { BORDER_COLOR } from '../../../_reusable/theme';

const SortCriterionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  height: 32px;
  min-width: 120px;
  border-radius: 4px;
  border: 1px solid ${BORDER_COLOR};
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 14px;
`;

const SortCriterion = () => {
  return <SortCriterionWrapper>Sort</SortCriterionWrapper>;
};

export default SortCriterion;
