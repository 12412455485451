import { useContext } from 'react';
import { PlusIcon, TrashIcon } from '@radix-ui/react-icons';

import CustomButton from '../../_reusable/CustomButton';
import { BACKGROUND_GREEN, COLOR_GREEN, HEIGHT_MEDIA_STANDARD_PIXEL } from '../../_reusable/theme';
import {
    CardWrapper,
    TextSection,
    TextNormal,
    Image,
    MediaPlaceholder,
    WrapperHorizontal,
    WrapperVerticalLeft,
    WrapperVerticalLeftStretch,
    UploadFileLabel,
    FileInput
} from '../../_reusable/styles';

import { GlobalStateContext } from '../../../providers/GlobalProvider';
import { createPicture, deletePicture } from '../../../api/picture';
import { deleteBlob, uploadBlob } from '../../../api/uploadBlob';


const FileButton = ({ type, typeText, onChange }) => {
    const typeAccept = (() => {
        switch (type) {
            case 'file_video':
                return 'video/*';
            case 'file_audio':
                return 'audio/*';
            case 'file_picture':
                return 'image/*';
            default:
                return 'image/*';
        }
    })();

    return (
        <UploadFileLabel width={'200px'} background={BACKGROUND_GREEN} color={COLOR_GREEN} htmlFor={type}>
            <PlusIcon />
            Upload training {typeText}
            <FileInput
                type="file"
                id={type}
                hidden
                onChange={onChange}
                accept={typeAccept}
            />
        </UploadFileLabel>
    );
};

const isVideoType = (src) => {
    return ['mp4', 'mov'].includes(src.slice(src.length - 3, src.length));
};

const ElementMedia = ({ url, alt }) => {
    return isVideoType(url) ? (
        <video width="140" height="105" controls>
            <source src={url} type="video/mp4"></source>
        </video>
    ) : (
        <img
            src={url}
            alt={alt}
            style={{
                imgRatio: '1',
                maxHeight: `${HEIGHT_MEDIA_STANDARD_PIXEL}`,
                maxWidth: `calc(var(--max-height, ${HEIGHT_MEDIA_STANDARD_PIXEL}) * var(--img-ratio, 1))`,
            }}
        />
    );
};

const ElementMediaWithDelete = ({ url, id, handleDelete = () => { } }) => {
    return (
        <WrapperVerticalLeftStretch>
            <ElementMedia url={url} alt={`media_${id}`} />
            <CustomButton onClick={handleDelete}>
                <TrashIcon /> Delete
            </CustomButton>
        </WrapperVerticalLeftStretch>
    );
};

const TrainingSampleCard = ({ article, trainingSample, setStatus }) => {

    const { user } = useContext(GlobalStateContext);

    const handleFileChangeImage = (event) => {
        handleFileChange('image', event);
    };

    const handleFileChangeVideo = (event) => {
        handleFileChange('video', event);
    };

    const handleFileChange = (typeMedia, event) => {
        const [file] = event.target.files;
        uploadBlob({
            type: typeMedia,
            data: file,
            userId: user.id,
        })
            .then((res) => {
                createPicture({
                    userId: user.id,
                    recableId: article.recable_id,
                    picUrl: res.data.url_blob,
                }).then(() => {
                    setStatus('loading');
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleDeletePicture = (pic_id, urlBLOB) => {
        deletePicture({ picId: pic_id })
            .then(() => {
                setStatus('loading');
                deleteBlob({
                    urlBlob: urlBLOB,
                    userId: user.id,
                });
            })
            .catch((error) => {
                console.error(error);
                setStatus('loading')
            });
    }

    return (
        <CardWrapper>
            <TextSection>{article.name}</TextSection>
            <WrapperHorizontal>
                <FileButton typeText={'image'} onChange={handleFileChangeImage} />
                <FileButton typeText={'video'} onChange={handleFileChangeVideo} />
            </WrapperHorizontal>
            <WrapperHorizontal>
                <WrapperVerticalLeft style={{ flexGrow: 0 }}>
                    <TextNormal>Profile Picture</TextNormal>
                    {
                        article.urlpicture
                            ? <Image src={article.urlpicture} />
                            : <MediaPlaceholder>
                                No profile picture available
                            </MediaPlaceholder>
                    }
                </WrapperVerticalLeft>
                <WrapperVerticalLeft>
                    <TextNormal>Training Media</TextNormal>
                    <WrapperHorizontal>
                        {
                            trainingSample.length > 0 ? trainingSample.map((itemTrainingSample) => {
                                return <ElementMediaWithDelete
                                    url={itemTrainingSample.pic_url}
                                    id={`${itemTrainingSample.recable_id}_${itemTrainingSample.pic_id}`}
                                    key={`${itemTrainingSample.recable_id}_${itemTrainingSample.pic_id}`}
                                    handleDelete={() => handleDeletePicture(itemTrainingSample.pic_id, itemTrainingSample.pic_url)}
                                />
                            }) : <MediaPlaceholder>
                                No training media yet
                            </MediaPlaceholder>
                        }
                    </WrapperHorizontal>
                </WrapperVerticalLeft>
            </WrapperHorizontal>
        </CardWrapper>
    );
};

export default TrainingSampleCard;
