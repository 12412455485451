import styled from '@emotion/styled';
import { CheckIcon, Cross2Icon, SymbolIcon } from '@radix-ui/react-icons';
import { useContext, useState, useMemo, useEffect } from 'react';
import { GlobalStateContext } from '../../../../providers/GlobalProvider';
import CustomButton from '../../../_reusable/CustomButton';
import CustomCheckbox from '../../../_reusable/CustomCheckbox';

const AreaWrapper = styled.div``;

const LanguageSelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 32px;
  margin-bottom: 32px;
`;

const CheckboxLineWrapper = styled.div`
  display: flex;
  width: 280px;
`;

const LanguageSelectArea = ({ project, apiCall }) => {
  const { availableLanguages, user, currentLangId, tenant } =
    useContext(GlobalStateContext);

  // checkState item: { lang_id, lang_name, checked, translationStatus }
  const [checkState, setCheckState] = useState([]);
  const [isAllChecked, setIsAllChecked] = useState(false);

  const selectedLangIds = useMemo(
    () =>
      checkState.filter(({ checked }) => checked).map(({ lang_id }) => lang_id),
    [checkState]
  );

  useEffect(() => {
    if (availableLanguages) {
      setCheckState(
        availableLanguages.map(({ lang_id, lang_name, lang_azure_abbrev }) => ({
          lang_id,
          lang_name,
          key: `${lang_id}-${lang_azure_abbrev}`,
          checked: false,
        }))
      );
    }
  }, [availableLanguages]);

  useEffect(() => {
    setIsAllChecked(selectedLangIds.length === availableLanguages.length);
  }, [selectedLangIds, availableLanguages, setIsAllChecked]);

  const handleLangToggle = ({ key }) => {
    setCheckState((prev) => {
      const updated = [...prev].map((lang) =>
        lang.lang_id === key ? { ...lang, checked: !lang.checked } : lang
      );
      return updated;
    });
  };

  const handleCheckAllToggle = () => {
    setCheckState(
      availableLanguages.map(({ lang_id, lang_name }) => ({
        lang_id,
        lang_name,
        checked: !isAllChecked,
      }))
    );
  };

  const handleSubmit = () => {
    for (const langTargetId of selectedLangIds) {
      setCheckState((prev) => {
        const clone = [...prev];
        return clone.map((lang) => {
          if (lang.lang_id === langTargetId)
            return {
              ...lang,
              translationStatus: 'loading',
            };
          return lang;
        });
      });
      apiCall({
        projectId: project.project_id,
        langId: currentLangId,
        langTargetId: langTargetId,
        userId: user.id,
        tenantId: tenant.tenant_id,
      })
        .then(() => {
          setCheckState((prev) => {
            const clone = [...prev];
            return clone.map((lang) => {
              if (lang.lang_id === langTargetId)
                return {
                  ...lang,
                  translationStatus: 'success',
                };
              return lang;
            });
          });
        })
        .catch((error) => {
          setCheckState((prev) => {
            const clone = [...prev];
            return clone.map((lang) => {
              if (lang.lang_id === langTargetId)
                return {
                  ...lang,
                  translationStatus: 'error',
                };
              return lang;
            });
          });
          console.error(error);
        });
    }
  };

  return (
    <AreaWrapper>
      <CustomCheckbox
        id="select-all"
        label="Select all"
        isChecked={isAllChecked}
        onCheckChange={handleCheckAllToggle}
      />
      <LanguageSelectWrapper>
        {checkState.map((lang) => {
          const checkboxReplacementComponent = (() => {
            switch (lang.translationStatus) {
              case 'loading':
                return <SymbolIcon />;
              case 'success':
                return <CheckIcon />;
              case 'error':
                return <Cross2Icon />;
              default:
                return null;
            }
          })();

          return (
            <CheckboxLineWrapper key={lang.key}>
              <CustomCheckbox
                key={lang.id}
                id={lang.lang_id}
                isChecked={lang.checked}
                label={lang.lang_name}
                onCheckChange={handleLangToggle}
                checkboxReplacementComponent={checkboxReplacementComponent}
              />
            </CheckboxLineWrapper>
          );
        })}
      </LanguageSelectWrapper>

      <CustomButton onClick={handleSubmit}>Submit</CustomButton>
    </AreaWrapper>
  );
};

export default LanguageSelectArea;
