import styled from '@emotion/styled';
import { PersonIcon, BookmarkIcon, StarIcon } from '@radix-ui/react-icons';
import React, { useContext, useMemo } from 'react';
import {
  GlobalStateContext,
  updateAvailableRecableTypes,
} from '../../../../providers/GlobalProvider';
import { HOVER_BACKGROUND } from '../../../_reusable/theme';

const ArticleTypePopoverWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Option = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  font-weight: 500;
  border-radius: 4px;
  padding: 4px 8px;
  height: 24px;
  align-items: center;
  font-size: 14px;
  cursor: default;

  &:hover {
    background: ${(props) => props.hoverBackground || HOVER_BACKGROUND};
  }
`;

const CreateArticleDropdownContent = React.forwardRef(
  ({ onAddNewArticle, ...props }, ref) => {
    const { availableRecableTypes } = useContext(GlobalStateContext);

    const recableTypesWithIcon = useMemo(
      () =>
        availableRecableTypes.map((type) => {
          const value = (() => {
            switch (type.recable_type_id) {
              case 1:
                return 'artwork';
              case 2:
                return 'creator';
              case 3:
                return 'topic';
              default:
                return 'artwork';
            }
          })();

          const icon = (() => {
            switch (type.recable_type_id) {
              case 1:
                return <StarIcon />;
              case 2:
                return <PersonIcon />;
              case 3:
                return <BookmarkIcon />;
              default:
                return null;
            }
          })();

          return {
            type_id: type.recable_type_id,
            value,
            label: type.recable_type_lang_name,
            icon,
          };
        }),
      [availableRecableTypes]
    );

    return (
      <ArticleTypePopoverWrapper ref={ref} {...props}>
        {recableTypesWithIcon.map((type) => (
          <Option key={type.value} id={type.value} onClick={onAddNewArticle}>
            {type.icon}
            {type.label}
          </Option>
        ))}
      </ArticleTypePopoverWrapper>
    );
  }
);

export default CreateArticleDropdownContent;
