import { post } from './dataSource';

const URL_BASE_SYNTHESIZE = 'synthesize'

export const synthesize = async ({ projectId, recableId, langId, userId, tenantId }) => {
  return await post(
    URL_BASE_SYNTHESIZE,
    {},
    {
      project_id: projectId,
      recable_id: recableId,
      lang_id: langId,
      user_id: userId,
      tenant_id: tenantId,
    }
  );
};
