
import styled from '@emotion/styled';

const Image = styled.img`
width: 240px;
height: 240px;
object-fit: cover;
`;

const Video = styled.video`
max-height: 240px;
width: 360px;
object-fit: cover;
`;

const Audio = styled.audio`
width: 360p`;

const CustomMediaDisplay = ({ media, typeText }) => {
    if (!media) return null;

    const component = (() => {
        if (typeText === 'image') {
            return <Image src={media} alt={`Uploaded ${typeText}`} />;
        } else if (typeText === 'video') {
            return <Video src={media} alt={`Uploaded ${typeText}`} controls />;
        } else if (typeText === 'audio') {
            return (
                <Audio controls>
                    <source src={media} alt={`Uploaded ${typeText}`} />
                </Audio>
            );
        }
        return null;
    })();

    return component;
};

export default CustomMediaDisplay;