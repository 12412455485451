import { CameraIcon, PlusIcon, TrashIcon } from '@radix-ui/react-icons';
import React, { useContext, useEffect, useState } from 'react';
import { ArticleStateContext } from '../../../../providers/ArticleProvider';
import { GlobalStateContext } from '../../../../providers/GlobalProvider';
import CustomButton from '../../../_reusable/CustomButton';
import CustomDialog from '../../../_reusable/CustomDialog';
import {
  createPicture,
  getPicture,
  deletePicture,
} from '../../../../api/picture';
import {
  uploadBlob,
  deleteBlob,
} from '../../../../api/uploadBlob';
import {
  HOVER_BACKGROUND_INTERACT,
  INTERACT_BACKGROUND,
  INTERACT_COLOR,
} from '../../../_reusable/theme';
import { WrapperHorizontal, ContentWrapper, FileInput, ImageArea, UploadFileLabel } from '../../../_reusable/styles';

const FileButton = ({ type, typeText, onChange }) => {
  const typeAccept = (() => {
    switch (type) {
      case 'file_video':
        return 'video/*';
      case 'file_audio':
        return 'audio/*';
      case 'file_picture':
        return 'image/*';
      default:
        return 'image/*';
    }
  })();

  return (
    <UploadFileLabel htmlFor={type}>
      <PlusIcon />
      Upload {typeText}
      <FileInput
        type="file"
        id={type}
        hidden
        onChange={onChange}
        accept={typeAccept}
      />
    </UploadFileLabel>
  );
};

const Trigger = React.forwardRef((props, ref) => {
  return (
    <CustomButton
      ref={ref}
      {...props}
      color={INTERACT_COLOR}
      hoverColor={INTERACT_COLOR}
      background={INTERACT_BACKGROUND}
      hoverBackground={HOVER_BACKGROUND_INTERACT}
    >
      <CameraIcon /> Training Media Upload
    </CustomButton>
  );
});

const NewTrainingMedia = () => {
  const { user } = useContext(GlobalStateContext);
  const { article } = useContext(ArticleStateContext);

  const [trainingSamples, settrainingSamples] = useState([]);

  const handleFileChangeImage = (event) => {
    handleFileChange('image', event);
  };

  const handleFileChangeVideo = (event) => {
    handleFileChange('video', event);
  };

  const handleFileChange = (typeMedia, event) => {
    const [file] = event.target.files;
    uploadBlob({
      type: typeMedia,
      data: file,
      userId: user.id,
    })
      .then((res) => {
        createPicture({
          userId: user.id,
          recableId: article.recable_id,
          picUrl: res.data.url_blob,
        }).then(() => {
          updateSampleMedia();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFileDelete = (picID, urlBLOB) => {
    deletePicture({
      picId: picID,
    })
      .then(() => {
        updateSampleMedia();
        deleteBlob({
          urlBlob: urlBLOB,
          userId: user.id,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateSampleMedia = () => {
    getPicture({ recableId: article.recable_id })
      .then((res) => {
        settrainingSamples(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (article) updateSampleMedia();
  }, [article]);

  const ElementMedia = (trainingSample, isVideo, onClick = () => { }) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          paddingBottom: '16px',
        }}
      >
        {isVideo ? (
          <video width="160" height="120" controls>
            <source src={trainingSample.pic_url} type="video/mp4"></source>
          </video>
        ) : (
          <img
            src={trainingSample.pic_url}
            alt={`pic_${trainingSample.recable_id}_${trainingSample.pic_id}`}
            style={{
              imgRatio: '1',
              maxHeight: '160px',
              maxWidth: 'calc(var(--max-height, 160px) * var(--img-ratio, 1))',
            }}
          />
        )}
        <CustomButton id={trainingSample.pic_id} onClick={onClick}>
          <TrashIcon /> Delete
        </CustomButton>
      </div>
    );
  };

  const isVideoType = (filename) => {
    return ['mp4', 'mov'].includes(filename.slice(filename.length - 3, filename.length));
  };

  const Content = React.forwardRef((props, ref) => {
    return (
      <ContentWrapper ref={ref} {...props}>
        <h4>Upload new media</h4>
        <WrapperHorizontal>
          <FileButton typeText={'image'} onChange={handleFileChangeImage} />
          <FileButton typeText={'video'} onChange={handleFileChangeVideo} />
        </WrapperHorizontal>

        <h4>List of training images</h4>

        <ImageArea>
          {trainingSamples ? (
            trainingSamples
              .filter((trainingSample) => !isVideoType(trainingSample.pic_url))
              .map((trainingSample) =>
                ElementMedia(trainingSample, false, () =>
                  handleFileDelete(trainingSample.pic_id, trainingSample.pic_url)
                )
              )
          ) : (
            <p>None available</p>
          )}
        </ImageArea>
        <h4>List of training videos</h4>
        <ImageArea>
          {trainingSamples ? (
            trainingSamples
              .filter((trainingSample) => isVideoType(trainingSample.pic_url))
              .map((trainingSample) =>
                ElementMedia(trainingSample, true, () =>
                  handleFileDelete(trainingSample.pic_id, trainingSample.pic_url)
                )
              )
          ) : (
            <p>None available</p>
          )}
        </ImageArea>
      </ContentWrapper>
    );
  });

  return (
    <CustomDialog triggerElement={<Trigger />} contentElement={<Content />} />
  );
};

export default NewTrainingMedia;
