import { get, put } from './dataSource';

const URL_BASE_TENANT = 'tenant'

export const getTenant = async (tenantId) => {
  return await get(`${URL_BASE_TENANT}/${tenantId}`);
};

export const updateTenant = async ({ tenantId, userId, data }) => {
  return await put(
    `${URL_BASE_TENANT}/${tenantId}`,
    {
      tenant_key_value_list: data,
    },
    {
      user_id: userId,
    }
  );
};
