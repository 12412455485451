import styled from '@emotion/styled';
import { COLOR } from '../../../../_reusable/theme';
import LanguageSelectArea from '../LanguageSelectArea';
import { synthesizeProject } from '../../../../../api/api';

const DialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 100%;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: ${COLOR};
`;

const SynthDialog = ({ project }) => {
  return (
    <DialogWrapper>
      <Title>Text to speech - Select target languages</Title>
      <LanguageSelectArea project={project} apiCall={synthesizeProject} />
    </DialogWrapper>
  );
};

export default SynthDialog;
