import * as PopoverPrimitive from '@radix-ui/react-popover';
import { styled, keyframes } from '@stitches/react';
import { Cross2Icon } from '@radix-ui/react-icons';
import { BACKGROUND, BORDER_COLOR, HOVER_BACKGROUND } from './theme';

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const StyledContent = styled(PopoverPrimitive.Content, {
  zIndex: 100,
  borderRadius: 4,
  padding: '8px',
  backgroundColor: 'white',
  wordWrap: 'break-word',
  boxShadow:
    'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  '@media (prefers-reduced-motion: no-preference)': {
    animationDuration: '200ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    animationFillMode: 'forwards',
    willChange: 'transform, opacity',
    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
  '&:focus': {
    boxShadow: `hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px, 0 0 0 2px ${BACKGROUND}`,
  },
});

const StyledArrow = styled(PopoverPrimitive.Arrow, {
  fill: 'white',
});

const StyledClose = styled(PopoverPrimitive.Close, {
  all: 'unset',
  fontFamily: 'inherit',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: 5,
  right: 5,
  padding: 4,
  borderRadius: 2,
  cursor: 'pointer',

  '&:hover': { backgroundColor: HOVER_BACKGROUND },
  '&:focus': { boxShadow: `0 0 0 2px ${BORDER_COLOR}` },
});

export const Popover = PopoverPrimitive.Root;
export const PopoverTrigger = PopoverPrimitive.Trigger;
export const PopoverContent = StyledContent;
export const PopoverArrow = StyledArrow;
export const PopoverClose = StyledClose;

const CustomPopover = ({ triggerElement, contentElement, side, align, hasCloseButton }) => {
  return (
    <Popover>
      <PopoverTrigger asChild>{triggerElement}</PopoverTrigger>
      <PopoverContent
        alignOffset={20}
        side={side || 'bottom'}
        align={align || 'center'}
      >
        {contentElement}
        {hasCloseButton && (
          <PopoverClose asChild aria-label="Close">
            <Cross2Icon />
          </PopoverClose>
        )}
        <PopoverArrow />
      </PopoverContent>
    </Popover>
  );
};

export default CustomPopover;
