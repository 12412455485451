export default (string) => {
  let json;
  if (string) {
    try {
      json = JSON.parse(string);
      if (!Array.isArray(json)) {
        if (typeof json === 'number') {
          return [
            {
              type: 'paragraph',
              children: [{ text: JSON.stringify(json) }],
            },
          ];
        }
        return [
          {
            type: 'paragraph',
            children: [{ text: json }],
          },
        ];
      }
      return json;
    } catch (error) {
      json = string;
      if (typeof json === 'string') {
        return [
          {
            type: 'paragraph',
            children: [{ text: json }],
          },
        ];
      }

      if (typeof json === 'number') {
        return [
          {
            type: 'paragraph',
            children: [{ text: JSON.stringify(json) }],
          },
        ];
      }

      return [
        {
          type: 'paragraph',
          children: [{ text: JSON.stringify(json) }],
        },
      ];
    }
  }
  return [
    {
      type: 'paragraph',
      children: [{ text: '' }],
    },
  ];
};
