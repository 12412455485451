import styled from '@emotion/styled';
import { Cross2Icon } from '@radix-ui/react-icons';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signIn } from '../../api/user';
import {
    getUserInfo,
    GlobalDispatchContext,
} from '../../providers/GlobalProvider';
import CustomButton from '../_reusable/CustomButton';
import CustomInput from '../_reusable/CustomInput';
import { WrapperVerticalCenter } from '../_reusable/styles';
import { COLOR, SHAIRE_COLOR, COLOR_LIGHT } from '../_reusable/theme';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 64px;
  gap: 12px;
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: 600;
  color: ${COLOR};
`;

const Message = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  color: burgundy;
  font-size: 14px;
  font-weight: 400;
  height: 24px;
`;

const ControlAreaWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  margin-top: 40px;
`;

const SignInEmailPassword = ({ setIsForgot }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [status, setStatus] = useState('');
    const globalDispatch = useContext(GlobalDispatchContext);
    const navigate = useNavigate();

    const buttonText = (() => {
        if (status === 'loading') {
            return 'Signing in...';
        } else if (status === 'error') {
            return 'Try again';
        } else if (status === 'success') {
            return 'Redirecting...';
        } else if (status === '') {
            return 'Sign in';
        } else {
            return 'Status unknown';
        }
    })();

    const handleFormChange = () => {
        errorMessage && setErrorMessage('');
        status && setStatus('');
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const { email, password } = event.target.elements;
        const data = {
            user_email: email.value,
            user_password: password.value,
        };
        setStatus('loading');
        signIn({ data })
            .then((res) => {
                globalDispatch(getUserInfo(res.data));
                localStorage.setItem('userInfo', JSON.stringify(res.data));
                setStatus('success');
                navigate('/');
            })
            .catch((error) => {
                setStatus('error');
                setErrorMessage(error.response.data.message);
            });
    };

    return (
        <Form onSubmit={handleSubmit} onChange={handleFormChange}>
            <Label htmlFor="email">Email</Label>
            <CustomInput id="email" name="email" type="email" autoFocus />
            <Label htmlFor="password">Password</Label>
            <CustomInput id="password" name="password" type="password" />
            <Message>
                {errorMessage && <Cross2Icon color="red" />}
                {errorMessage}
            </Message>
            <ControlAreaWrapper>
                <WrapperVerticalCenter>
                    <CustomButton
                        type="submit"
                        background={SHAIRE_COLOR}
                        color="white"
                        justifyContent="center"
                    >
                        {buttonText}
                    </CustomButton>
                    <CustomButton
                        background={COLOR_LIGHT}
                        color={SHAIRE_COLOR}
                        justifyContent="center"
                        onClick={() => setIsForgot(true)}
                    >Forgot password</CustomButton>
                </WrapperVerticalCenter>
            </ControlAreaWrapper>
        </Form>)
};

export default SignInEmailPassword;
