import { Cross2Icon } from '@radix-ui/react-icons';
import { styled, keyframes } from '@stitches/react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { BORDER_COLOR, HOVER_BACKGROUND } from './theme';

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.98)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

const StyledContent = styled(DialogPrimitive.Content, {
  zIndex: 10,
  backgroundColor: 'white',
  borderRadius: 4,
  boxShadow:
    'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  position: 'fixed',
  top: '50%',
  left: '50%',
  width: '400px',
  minHeight: '160px',
  transform: 'translate(-50%, -50%)',
  padding: 25,
  opacity: 1,
  '&:focus': { outline: 'none' },
  variants: {
    size: {
      100: {
        width: '80vw',
        height: '80vh',
      },
      90: {
        width: '60vw',
        height: '60vh',
      }
    },
  },
});

const StyledClose = styled(DialogPrimitive.Close, {
  all: 'unset',
  fontFamily: 'inherit',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: 5,
  right: 5,
  padding: 4,
  borderRadius: 2,
  cursor: 'pointer',

  '&:hover': { backgroundColor: HOVER_BACKGROUND },
  '&:focus': { boxShadow: `0 0 0 2px ${BORDER_COLOR}` },
});

export const Dialog = DialogPrimitive.Root;
export const DialogTrigger = DialogPrimitive.Trigger;
export const DialogContent = StyledContent;
export const DialogClose = StyledClose;

const CustomDialog = ({
  triggerElement,
  contentElement,
  open,
  onClose,
  size,
  style = {}
}) => {
  if (!Boolean(triggerElement) && open !== undefined) {
    return (
      <Dialog open={!Boolean(triggerElement) && open} style={{ position: "relative", backgroundColor: "red" }}>
        <div style={{ position: "relative" }}>
          <DialogTrigger asChild>{triggerElement}</DialogTrigger>
          <DialogContent
            onEscapeKeyDown={onClose}
            onInteractOutside={onClose}
            size={size}
            style={{ ...style }}
          >
            {contentElement}
            <DialogClose asChild aria-label="Close" onClick={onClose}>
              <Cross2Icon />
            </DialogClose>
          </DialogContent>
        </div>

      </Dialog>
    );
  }

  return (
    <Dialog style={{ position: "relative", backgroundColor: "red" }}>
      <DialogTrigger asChild>{triggerElement}</DialogTrigger>
      <DialogContent
        onEscapeKeyDown={onClose}
        onInteractOutside={onClose}
        size={size}
        style={{ ...style }}
      >
        {contentElement}
        <DialogClose asChild aria-label="Close" onClick={onClose}>
          <Cross2Icon />
        </DialogClose>
      </DialogContent>
    </Dialog>
  );
};

export default CustomDialog;
