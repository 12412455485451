import { get, post, put, remove } from './dataSource';

const URL_BASE_RECABLE_TYPE = 'recable_type'
const URL_BASE_RECABLE_ATTRIBUTE = 'recable_attribute'
const URL_BASE_RECABLE = 'recable'
const URL_BASE_RECABLE_INFO = 'recable_info'

export const getRecableTypes = async ({ langId }) => {
  return await get(URL_BASE_RECABLE_TYPE, { lang_id: langId });
};

export const getRecableAttribute = async ({
  langId,
  articleTypeId,
  recableId,
  projectId, // pass this along with recableId
  // to receive attributes that have not been used
  // TODO: remove this
}) => {
  return await get(URL_BASE_RECABLE_ATTRIBUTE, {
    lang_id: langId,
    recable_type_id: articleTypeId,
    recable_id: recableId,
    project_id: projectId,
  });
};


export const getRecable = async ({
  recableId,
  langId,
  projectId,
  tenantId,
}) => {
  const url = recableId ? `${URL_BASE_RECABLE}/${recableId}` : URL_BASE_RECABLE;
  return await get(url, {
    lang_id: langId,
    project_id: projectId,
    tenant_id: tenantId,
  });
};

export const updateRecable = async ({ recableId, userId, data }) => {
  return await put(`${URL_BASE_RECABLE}/${recableId}`, data, { user_id: userId });
};

export const createArticle = async ({
  data,
  langId,
  projectId,
  userId,
  articleTypeId,
}) => {
  return await post(URL_BASE_RECABLE, data, {
    lang_id: langId,
    project_id: projectId,
    user_id: userId,
    recable_type_id: articleTypeId,
  });
};

export const deleteRecable = async ({ recableId, projectId }) => {
  return await remove(`${URL_BASE_RECABLE}/${recableId}`, {
    project_id: projectId,
  });
};


export const updateRecableInfo = async ({
  recableInfoId,
  data,
  userId,
}) => {
  return await put(`${URL_BASE_RECABLE_INFO}/${recableInfoId}`, data, {
    user_id: userId,
  });
};

export const createRecableInfo = async ({
  data,
  langId,
  projectId,
  recableId,
  userId,
}) => {
  return await post(URL_BASE_RECABLE_INFO, data, {
    lang_id: langId,
    project_id: projectId,
    recable_id: recableId,
    user_id: userId,
  });
};

export const getRecableInfo = async ({ recableId, langId, projectId }) => {
  return await get(URL_BASE_RECABLE_INFO, {
    lang_id: langId,
    project_id: projectId,
    recable_id: recableId,
  });
};

export const deleteRecableInfo = async ({ recableInfoId, recableId, projectId, recableAttributeId }) => {
  return await remove(recableInfoId ? `${URL_BASE_RECABLE_INFO}/${recableInfoId}` : URL_BASE_RECABLE_INFO, {
    recable_attribute_id: recableAttributeId,
    project_id: projectId,
    recable_id: recableId,
  });
};