import { useReducer, createContext, useEffect, useContext } from 'react';
import { getProject } from '../api/project';
import { getRecable } from '../api/recable';
import { GlobalStateContext } from './GlobalProvider';

const initialState = {
  id: null,
  info: null,
  parameters: [],
  articles: [],
  status: null,
  editMode: false,
  editModeGetRecableIds: [],
  message: '',
  filters: null,
};

const reducer = (state, action) => {
  const newState = { ...state };
  switch (action.type) {
    case 'updateEditMode':
      newState.editMode = action.payload;
      newState.editModeGetRecableIds = [];
      return newState;

    case 'updateeditModeGetRecableIds':
      const recableId = action.payload;
      if (!state.editModeGetRecableIds.includes(recableId)) {
        newState.editModeGetRecableIds = [
          ...state.editModeGetRecableIds,
          recableId,
        ];
      } else {
        newState.editModeGetRecableIds = [
          ...state.editModeGetRecableIds,
        ].filter((id) => id !== recableId);
      }
      return newState;

    case 'newProjectId':
      newState.id = action.payload;
      return newState;

    case 'projectInfo':
      newState.info = action.payload.result;
      newState.parameters = action.payload.result_parameter.parameter;
      return newState;

    case 'projectArticles':
      newState.articles = action.payload;
      newState.status = 'success';
      return newState;

    case 'appendProjectArticle':
      newState.articles = [...state.articles, action.payload];
      return newState;

    case 'updateProjectArticle':
      newState.articles = [...state.articles].map((article) => {
        if (article.recable_id === action.payload.recable_id)
          return action.payload;
        return article;
      });
      return newState;

    case 'projectError':
      newState.status = 'error';
      newState.message = action.payload.message;
      return newState;

    case 'setFilters':
      newState.filters = action.payload;
      return newState;

    default:
      return newState;
  }
};

export const updateEditMode = (editMode) => ({
  type: 'updateEditMode',
  payload: editMode,
});

export const updateeditModeGetRecableIds = (recableId) => ({
  type: 'updateeditModeGetRecableIds',
  payload: recableId,
});

export const newProjectId = (id) => ({
  type: 'newProjectId',
  payload: id,
});

export const projectInfo = (info) => ({
  type: 'projectInfo',
  payload: info,
});

export const projectArticles = (articles) => ({
  type: 'projectArticles',
  payload: articles,
});

export const appendProjectArticle = (article) => ({
  type: 'appendProjectArticle',
  payload: article,
});

export const updateProjectArticleById = (article) => ({
  type: 'updateProjectArticle',
  payload: article,
});

export const projectError = (error) => ({
  type: 'projectError',
  payload: error,
});

export const ProjectStateContext = createContext(initialState);
export const ProjectDispatchContext = createContext(() => { });

const ProjectProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { user, currentLangId } = useContext(GlobalStateContext);
  const { id: projectId } = state;

  useEffect(() => {
    if (projectId && user) {
      getProject({
        langId: currentLangId,
        userId: user.id,
        projectId,
      })
        .then((res) => {
          dispatch(projectInfo(res.data));
        })
        .catch((error) => {
          dispatch(projectError(error));
        });

      getRecable({
        langId: currentLangId,
        projectId,
      })
        .then((res) => {
          dispatch(projectArticles(res.data.result));
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status === 404) {
            dispatch(projectArticles(error.response.data.result));
          } else {
            dispatch(projectError(error));
          }
        });
    }
  }, [dispatch, projectId, user, currentLangId]);

  return (
    <ProjectDispatchContext.Provider value={dispatch}>
      <ProjectStateContext.Provider value={state}>
        {children}
      </ProjectStateContext.Provider>
    </ProjectDispatchContext.Provider>
  );
};

export default ProjectProvider;
