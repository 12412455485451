import styled from '@emotion/styled';
import { Link1Icon } from '@radix-ui/react-icons';
import React from 'react';
import { useContext } from 'react';
import { ArticleStateContext } from '../../../../providers/ArticleProvider';
import CustomButton from '../../../_reusable/CustomButton';
import CustomDialog from '../../../_reusable/CustomDialog';
import {
  HOVER_BACKGROUND_INTERACT,
  INTERACT_BACKGROUND,
  INTERACT_COLOR,
} from '../../../_reusable/theme';
import ArticleLink from './ArticleLink';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 200px;
  gap: 8px;
  background: white;
  overflow-y: scroll;
`;

const Trigger = React.forwardRef((props, ref) => {
  return (
    <CustomButton
      ref={ref}
      {...props}
      color={INTERACT_COLOR}
      hoverColor={INTERACT_COLOR}
      background={INTERACT_BACKGROUND}
      hoverBackground={HOVER_BACKGROUND_INTERACT}
    >
      <Link1Icon />
      Link data
    </CustomButton>
  );
});

const Content = React.forwardRef((props, ref) => {
  const { availableProperties } = useContext(ArticleStateContext);

  const associateOptions = availableProperties
    .map((property) => {
      const {
        recable_attribute_lang_name: label,
        recable_attribute_associated_recable_type_id,
        recable_attribute_id: articleTypeKeyId,
      } = property;

      const isLinkable = Boolean(
        ![null, undefined].includes(recable_attribute_associated_recable_type_id)
      );

      return {
        label,
        isLinkable,
        articleTypeKeyId,
        associatedRecableInfo: availableProperties.find(
          (p) => p.recable_attribute_id === articleTypeKeyId
        ),
      };
    })
    .filter((property) => property.isLinkable);

  return (
    <ContentWrapper ref={ref} {...props}>
      {associateOptions.map((option) => (
        <ArticleLink key={option.articleTypeKeyId} option={option} />
      ))}
    </ContentWrapper>
  );
});

const Associate = () => {
  return (
    <CustomDialog triggerElement={<Trigger />} contentElement={<Content />} style={{ position: "fixed", left: 24, top: "auto", transform: "none", zIndex: 1000 }} />
  );
};

export default Associate;
