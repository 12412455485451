import React, { useContext } from 'react';
import styled from '@emotion/styled';
import {
  HamburgerMenuIcon,
  PersonIcon,
  BookmarkIcon,
  StarIcon,
} from '@radix-ui/react-icons';
import CustomButton from '../../_reusable/CustomButton';
import { ArticleStateContext } from '../../../providers/ArticleProvider';
import CustomScrollArea from '../../_reusable/CustomScrollArea';
import { GlobalStateContext } from '../../../providers/GlobalProvider';
import { useMemo } from 'react';
import { COLOR_LIGHT } from '../../_reusable/theme';
import NewProperty from './NewProperty';
import Associate from './Associate';
import NewTrainingMedia from './NewTrainingMedia';

const MidMenuAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-bottom: 8px;
  gap: 8px;
  width: 100%;
`;

const PropertiesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ArticleTypeTittle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  font-size: 14px;
  font-weight: 600;
  color: ${COLOR_LIGHT};
  justify-content: center;
`;

const MidMenuArea = () => {
  const { articleType, currentProperties } = useContext(ArticleStateContext);
  const { availableRecableTypes } = useContext(GlobalStateContext);

  const currentRecableTypeId = (() => {
    switch (articleType) {
      case 'artwork':
        return 1;
      case 'creator':
        return 2;
      case 'topic':
        return 3;
      default:
        return undefined;
    }
  })();

  const currentRecableTypeIcon = (() => {
    switch (articleType) {
      case 'artwork':
        return <StarIcon />;
      case 'creator':
        return <PersonIcon />;
      case 'topic':
        return <BookmarkIcon />;
      default:
        return <StarIcon />
    }
  })();

  const recableTypeWithIcon = useMemo(() => {
    const currentRecableType = availableRecableTypes.find(
      (type) => type.recable_type_id === currentRecableTypeId
    );

    return {
      type_id: currentRecableTypeId,
      label: currentRecableType?.recable_type_lang_name,
      value: currentRecableType?.recable_type_id,
      icon: currentRecableTypeIcon,
    };
  }, [currentRecableTypeId, currentRecableTypeIcon, availableRecableTypes]);

  return (
    <MidMenuAreaWrapper>
      <ArticleTypeTittle>
        {recableTypeWithIcon.icon} {recableTypeWithIcon.label}
      </ArticleTypeTittle>
      <div>
        <PropertiesWrapper>
          {currentProperties.map((property) => (
            <CustomButton
              key={property.recable_attribute_id}
              width="216px"
            >
              <HamburgerMenuIcon />
              {property.recable_attribute_lang_name}
            </CustomButton>
          ))}
        </PropertiesWrapper>
      </div>
      <NewProperty />
      <Associate />
      <NewTrainingMedia />
    </MidMenuAreaWrapper>
  );
};

export default MidMenuArea;
