import styled from '@emotion/styled';
import ReadOnlyRichText from '../../../_reusable/ReadOnlyRichText';

const ParagraphWrapper = styled.div`
  display: flex;
  margin-top: -14px; // offset with default padding of richtext editor
`;
const Paragraph = ({ cell, onClick }) => {
  return (
    <ParagraphWrapper onClick={onClick}>
      <ReadOnlyRichText jsonString={cell.value} />
    </ParagraphWrapper>
  );
};

export default Paragraph;
