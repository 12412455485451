import CustomDialog from '../../../../_reusable/CustomDialog';
import TranslationButton from './TranslationButton';
import TranslationDialog from './TranslationDialog';

const Translation = ({ project }) => {
  return (
    <CustomDialog
      triggerElement={<TranslationButton />}
      contentElement={<TranslationDialog project={project} />}
      size="100"
    />
  );
};

export default Translation;
