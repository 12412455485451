import { useReducer, createContext, useEffect } from 'react';
import { getProjects } from '../api/project';
import { getRecableTypes } from '../api/recable';
import { getTenant } from '../api/tenant';
import { getLanguages } from '../api/translate';
import { user as userAPI } from '../api';

const initialState = {
  // TODO: api this
  user: null,
  tenant: null,
  currentLangId: 1, // German
  availableProjects: [],
  availableLanguages: [],
  availableRecableTypes: [],
  status: null,
  message: '',
};

const reducer = (state, action) => {
  const newState = { ...state };
  switch (action.type) {
    case 'updateCurrentLangId':
      newState.currentLangId = action.payload;
      return newState;

    case 'userInfo':
      newState.user = {
        id: action.payload.user_id,
        username: action.payload.user_name,
        user_email: action.payload.user_email,
      };
      newState.tenant = {
        id: action.payload.tenant_id,
        name: action.payload.tenant_name,
      };
      return newState;

    case 'getTenantInfo':
      newState.tenant = action.payload;
      return newState;

    case 'updateTenantInfo':
      newState.tenant = {
        ...state.tenant,
        [action.payload.propertyKey]: action.payload.propertyValue,
      };
      return newState;

    case 'clearUserInfo':
      newState.user = null;
      newState.tenant = null;
      return newState;

    case 'availableLanguages':
      newState.availableLanguages = action.payload;
      return newState;

    case 'availableRecableTypes':
      newState.availableRecableTypes = action.payload;
      return newState;

    case 'availableProjects':
      const { result, result_parameter } = action.payload;
      newState.availableProjects = result.map((project) => ({
        ...project,
        parameters: result_parameter.find(
          (projectParams) => projectParams.project_id === project.project_id
        ).parameter,
      }));

      return newState;

    case 'addProjectToTop':
      newState.availableProjects = [action.payload, ...state.availableProjects];
      return newState;

    case 'updateProjectById':
      let updated = {};
      // This checks for whether action.payload is from server or from saved project
      // TODO: remove this check. It's a hack
      if (action.payload.result) {
        updated = {
          ...action.payload.result,
          parameters: action.payload.result_parameter.parameter,
        };
      } else {
        updated = {
          ...action.payload,
        };
      }
      newState.availableProjects = [...state.availableProjects].map(
        (project) => {
          if (project.project_id === updated.project_id) return updated;
          return project;
        }
      );
      return newState;

    case 'removeProjectById':
      newState.availableProjects = [...state.availableProjects].filter(
        (project) => project.project_id !== action.payload
      );
      return newState;

    case 'globalError':
      newState.status = 'error';
      newState.message = action.payload.message;
      return newState;

    default:
      return newState;
  }
};

export const updateCurrentLangId = (langId) => ({
  type: 'updateCurrentLangId',
  payload: langId,
});

export const getUserInfo = (userInfo) => ({
  type: 'userInfo',
  payload: userInfo,
});

export const getTenantInfo = (tenantInfo) => ({
  type: 'getTenantInfo',
  payload: tenantInfo,
});

export const updateTenantInfo = ({ propertyKey, propertyValue }) => ({
  type: 'updateTenantInfo',
  payload: { propertyKey, propertyValue },
});

export const clearUserInfo = () => ({
  type: 'clearUserInfo',
});

export const getAvailableProjects = (projects) => ({
  type: 'availableProjects',
  payload: projects,
});

export const addProjectToTop = (newProject) => ({
  type: 'addProjectToTop',
  payload: newProject,
});

export const updateProjectById = (updatedProject) => ({
  type: 'updateProjectById',
  payload: updatedProject,
});

export const removeProjectById = (deletedProjectId) => ({
  type: 'removeProjectById',
  payload: deletedProjectId,
});

export const getAvailableLanguages = (languages) => ({
  type: 'availableLanguages',
  payload: languages,
});

export const updateAvailableRecableTypes = (types) => ({
  type: 'availableRecableTypes',
  payload: types,
});

export const globalError = (error) => ({
  type: 'globalError',
  payload: error,
});

export const GlobalStateContext = createContext(initialState);
export const GlobalDispatchContext = createContext(() => { });

const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { availableLanguages, user, tenant, currentLangId } = state;

  useEffect(() => {
    if (!user) {
      const userInfo = localStorage.getItem('userInfo');
      const userId = JSON.parse(userInfo)?.user_id;
      userId &&
        userAPI.getUserInfo({ userId }).then((res) => {
          const currentUser = res.data.result.find((u) => u.user_id === userId);
          dispatch(getUserInfo(currentUser));
        });
    }
  }, [dispatch, user]);

  useEffect(() => {
    tenant?.id &&
      !tenant?.tenant_id &&
      getTenant(tenant?.id)
        .then((res) => {
          const tenantInfo = {
            ...res.data.result,
            parameters: res.data.result_parameter.parameter,
          };
          dispatch(getTenantInfo(tenantInfo));
        })
        .catch((error) => {
          console.error(error);
        });
  }, [tenant, dispatch]);

  useEffect(() => {
    if (user && tenant) {
      getProjects({
        langId: currentLangId,
        userId: user.id,
        tenantId: tenant.id,
      })
        .then((res) => {
          dispatch(getAvailableProjects(res.data));
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [dispatch, user, tenant, currentLangId]);

  useEffect(() => {
    if (!availableLanguages.length)
      getLanguages()
        .then((res) => {
          dispatch(getAvailableLanguages(res.data.result));
        })
        .catch((error) => {
          console.error(error);
          dispatch(globalError(error));
        });
  }, [dispatch, availableLanguages]);

  useEffect(() => {
    if (currentLangId !== null)
      getRecableTypes({ langId: currentLangId })
        .then((res) => {
          dispatch(updateAvailableRecableTypes(res.data.result));
        })
        .catch((error) => console.error(error));
  }, [dispatch, currentLangId]);

  return (
    <GlobalDispatchContext.Provider value={dispatch}>
      <GlobalStateContext.Provider value={state}>
        {children}
      </GlobalStateContext.Provider>
    </GlobalDispatchContext.Provider>
  );
};

export default GlobalProvider;
