import { Outlet, Navigate } from 'react-router-dom';
import styled from '@emotion/styled';
import AppBar from '../components/AppBar';
import ProjectProvider from '../providers/ProjectProvider';
import { useContext } from 'react';
import { GlobalStateContext } from '../providers/GlobalProvider';

const ScreenWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: ${({isPhone}) => isPhone ? '32px' : '0'} ${({isPhone}) => isPhone ? '16px' : '6rem'};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  //margin-left: 120px;
`;

const ProtectedLayout = ({ children, showNav }) => {
  const { user } = useContext(GlobalStateContext);
  const savedUserInfo = localStorage.getItem('userInfo');

  if (
    ([undefined, null].includes(user?.id) && !savedUserInfo) ||
    !JSON.parse(savedUserInfo)?.user_id
  ) {
    return <Navigate to="/signin" replace />;
  }

  return children ? (
    children
  ) : (
    <>
      {showNav && <AppBar />}
      <ScreenWrapper isPhone={!showNav}>
        <ProjectProvider>
          {/* <SidebarProvider>
            <Sidebar />
          </SidebarProvider> */}
          <ContentWrapper>
            <Outlet />
          </ContentWrapper>
        </ProjectProvider>
      </ScreenWrapper>
    </>
  );
};

export default ProtectedLayout;
