import { get, post, remove } from './dataSource';

const URL_BASE_PHONEME = 'picture';

export const getPicture = async ({ recableId }) => {
  return await get(URL_BASE_PHONEME, {
    recable_id: recableId,
  });
};

export const createPicture = async ({ userId, picUrl, recableId }) => {
  return await post(
    URL_BASE_PHONEME,
    {},
    {
      user_id: userId,
      pic_url: picUrl,
      recable_id: recableId,
    }
  );
};

export const deletePicture = async ({ picId }) => {
  return await remove(`${URL_BASE_PHONEME}/${picId}`, {}, {});
};
