import styled from '@emotion/styled';
import TopMenuArea from './TopMenuArea';
import MidMenuArea from './MidMenuArea';
import BottomMenuArea from './BottomMenuArea';
import CustomScrollArea from '../../_reusable/CustomScrollArea';

const NavMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  height: 100%;
  overflow: hidden;
  min-width: 250px;
  width: 250px;
  border-radius: 4px;
`;

const NavMenu = ({ onClose, urlpicture = '' }) => {
  // TopMenuArea:
  // - Confirm / Cancel
  // MidMenuArea:
  // - Property Index
  // BottomMenuArea:
  // - Add property
  return (
    <NavMenuWrapper>
      <CustomScrollArea noHorizontal={true}>
        <TopMenuArea onClose={onClose} />
        <MidMenuArea />
        <BottomMenuArea urlpicture={urlpicture} />
      </CustomScrollArea>
    </NavMenuWrapper>
  );
};

export default NavMenu;
