import { PlusIcon } from '@radix-ui/react-icons';
import React from 'react';
import CustomButton from '../../../_reusable/CustomButton';

const CreateArticleDropdownTrigger = React.forwardRef((props, ref) => {
  return (
    <CustomButton ref={ref} {...props}>
      <PlusIcon />
      Add
    </CustomButton>
  );
});

export default CreateArticleDropdownTrigger;
