import React from 'react';
import { GearIcon } from '@radix-ui/react-icons';
import CustomButton from '../../../_reusable/CustomButton';

const Trigger = React.forwardRef(({ a, ...props }, ref) => {
  return (
    <CustomButton {...props} ref={ref}>
      <GearIcon /> Settings {a}
    </CustomButton>
  );
});

export default Trigger;
