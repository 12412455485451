import styled from '@emotion/styled';
import { Cross2Icon } from '@radix-ui/react-icons';
import { useState } from 'react';
import { changeUserPassword } from '../../api/user';
import CustomButton from '../_reusable/CustomButton';
import CustomInput from '../_reusable/CustomInput';
import { WrapperVerticalCenter } from '../_reusable/styles';
import { COLOR, SHAIRE_COLOR, COLOR_LIGHT } from '../_reusable/theme';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 64px;
  gap: 12px;
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: 600;
  color: ${COLOR};
`;

const Message = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  color: burgundy;
  font-size: 14px;
  font-weight: 400;
  height: 24px;
`;

const ControlAreaWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  margin-top: 40px;
`;

const ForgotPassword = ({ setIsForgot }) => {
    const [errorMessageForgot, setErrorMessageForgot] = useState('');
    const [statusForgot, setStatusForgot] = useState('');

    const buttonTextForgot = (() => {
        if (statusForgot === 'loading') {
            return 'Sending email...';
        } else if (statusForgot === 'error') {
            return 'Try again';
        } else if (statusForgot === 'success') {
            return 'Check your email!';
        } else if (statusForgot === '') {
            return 'Email sent';
        } else {
            return 'Status unknown';
        }
    })();

    const handleFormChangeForgot = () => {
        errorMessageForgot && setErrorMessageForgot('');
        statusForgot && setStatusForgot('');
    };

    const handleForgot = (event) => {
        event.preventDefault();
        const { email } = event.target.elements;
        const data = {
            user_email: email.value,
        };
        setStatusForgot('loading');
        changeUserPassword(data)
            .then(() => {
                setStatusForgot('success');
                setErrorMessageForgot('');
            })
            .catch((error) => {
                setStatusForgot('error');
                setErrorMessageForgot(error.response.data.message);
            });
    }

    return (
        <Form onSubmit={handleForgot} onChange={handleFormChangeForgot}>
            <Label htmlFor="email">Email</Label>
            <CustomInput id="email" name="email" type="email" autoFocus />
            <Message>
                {errorMessageForgot && <Cross2Icon color="red" />}
                {errorMessageForgot}
            </Message>
            <ControlAreaWrapper>
                <WrapperVerticalCenter>
                    <CustomButton
                        type="submit"
                        background={SHAIRE_COLOR}
                        color="white"
                        justifyContent="center"
                    >
                        {buttonTextForgot}
                    </CustomButton>
                    <CustomButton
                        background={COLOR_LIGHT}
                        color={SHAIRE_COLOR}
                        justifyContent="center"
                        onClick={() => setIsForgot(false)}
                    >Back</CustomButton>
                </WrapperVerticalCenter>
            </ControlAreaWrapper>
        </Form>
    );
};

export default ForgotPassword;
