import { useState, useContext, useEffect } from 'react';
import Masonry from 'react-masonry-css';
import styled from '@emotion/styled';

import { getRecable } from '../../api/recable';
import { clearUserInfo, GlobalDispatchContext, GlobalStateContext } from '../../providers/GlobalProvider';
import { getPicture } from '../../api/picture';
import './style.css'
import Loader from '../_reusable/Loader';
import { PhoneRecableCard } from '../_reusable/PhoneRecable';
import { useNavigate } from 'react-router-dom';
import { INTERACT_BACKGROUND, INTERACT_COLOR, SHAIRE_COLOR } from '../_reusable/theme';
import LanguageSelector from '../AppBar/LanguageSelector';
import CustomButton from '../_reusable/CustomButton';
import { ExitIcon } from '@radix-ui/react-icons';

const Title = styled.h3`
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 16px;
  margin-top: 60px;
`

const Navigation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  z-index: 1000;
`

const NavBackground = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: ${INTERACT_BACKGROUND};

`

const Menu = styled.div`
  position: relative;
  z-index: 99;
  display: flex; 
  flex-direction: row;
  align-items: center;
`


export const PhoneRecableList = () => {
  const navigate = useNavigate();
  const [recables, setRecables] = useState([])
  const { user, currentLangId, tenant } = useContext(GlobalStateContext);
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null);
  const globalDispatch = useContext(GlobalDispatchContext);

  const handleSignout = () => {
    localStorage.removeItem('userInfo');
    globalDispatch(clearUserInfo());
    navigate('/signin');
  };

  const loadRecables = async () => {
    try {
      setIsLoading(true)
      setError(null)
      const res =  await getRecable({
        langId: currentLangId,
        userId: user.id,
        tenantId: tenant.tenant_id,
      })
      const fetchedRecables = res.data.result
      for (let i = 0; i < fetchedRecables.length; ++i) {
        fetchedRecables[i].training = (await getPicture({
          recableId: fetchedRecables[i].recable_id,
        })).data
      }
      setRecables(fetchedRecables)
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      setError(err);
      console.log(err)
    }
  }

  useEffect(() => {
    if (recables.length === 0) {
      if (user?.id && currentLangId && tenant?.tenant_id) {
        loadRecables();
      }
    }
  }, [currentLangId, recables.length, tenant, user])

  if (isLoading) {
    return <Loader/>
  }

  if (!user || !user?.id || !tenant?.tenant_id) {
    return <Loader/>
  }

  return <div>
    <Navigation>
      <NavBackground/>
        {user?.username}
      <Menu>
        <LanguageSelector />
        <div style={{marginRight: 5}}/>
        <CustomButton
          background="white"
          color={SHAIRE_COLOR}
          onClick={handleSignout}
        >
          Sign out
          <ExitIcon />
        </CustomButton>
      </Menu>
    </Navigation>
    <Title>Kunstwerk</Title>
    <Masonry breakpointCols={{default: 2, 450: 1}} className={'my-masonry-grid'} columnClassName={'my-masonry-grid_column'}>
      {recables.map(recable => <PhoneRecableCard key={recable.recable_id} recable={recable} onClick={() => navigate(`recable/${recable.recable_id}`, {recable})}/>)}
    </Masonry>
  </div>
}
