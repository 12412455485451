import styled from '@emotion/styled';
import { CheckIcon, Cross2Icon, SymbolIcon } from '@radix-ui/react-icons';
import { useContext, useState } from 'react';
import { translateArticle } from '../../../api/translate';
import {
  ArticleDispatchContext,
  ArticleStateContext,
  clearStatus,
  updateNewToOld,
} from '../../../providers/ArticleProvider';
import { GlobalStateContext } from '../../../providers/GlobalProvider';
import CustomButton from '../../_reusable/CustomButton';
import CustomDialog from '../../_reusable/CustomDialog';
import { TextSection } from '../../_reusable/styles';
import { COLOR } from '../../_reusable/theme';

const MessageContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${COLOR}
  font-size: 14px;
  gap: 16px;
`;

const TextArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  gap: 16px;
  line-height: 1.5;
`;

const WrapperHorizontal = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: center;
`;

const MessageContent = ({
  message,
  status,
  translateStatus,
  isNew,
  onClose,
  onTranslate,
}) => {
  const title = (() => {
    switch (status) {
      case 'created':
        return 'Article successfully created';
      case 'success':
        return 'Success';
      case 'error':
        return null;
    }
  })();

  const translateButtonText = (() => {
    switch (translateStatus) {
      case 'loading':
        return (
          <>
            <SymbolIcon /> Translating
          </>
        );
      case 'success':
        return (
          <>
            <CheckIcon /> Translated, close
          </>
        );
      case 'error':
        return (
          <>
            <Cross2Icon /> Error
          </>
        );
      default:
        return 'Translate';
    }
  })();

  return (
    <MessageContentWrapper>
      <TextArea>
        <TextSection>{title}</TextSection>
        <>{message}</>
      </TextArea>
      <WrapperHorizontal>
        {isNew ? (
          <>
            <span>
              <CustomButton onClick={onTranslate}>
                {translateButtonText}
              </CustomButton>
            </span>
            {translateStatus !== 'success' && (
              <span>
                <CustomButton onClick={onClose}>Skip</CustomButton>
              </span>
            )}
          </>
        ) : (
          <span>
            <CustomButton onClick={onClose}>Close</CustomButton>
          </span>
        )}
      </WrapperHorizontal>
    </MessageContentWrapper>
  );
};

const MessageDialog = () => {
  const dispatch = useContext(ArticleDispatchContext);
  const { message, status, isNew, article } = useContext(ArticleStateContext);
  const { user, currentLangId } = useContext(GlobalStateContext);

  const [translateStatus, setTranslateStatus] = useState('initial');

  const handleCloseMessageDialog = () => {
    dispatch(clearStatus());
    dispatch(updateNewToOld());
    setTranslateStatus(null);
  };

  const handleTranslateArticle = () => {
    if (translateStatus === 'success') {
      handleCloseMessageDialog();
    } else {
      setTranslateStatus('loading');
      translateArticle({
        projectId: article.project_id,
        recableId: article.recable_id,
        langId: currentLangId,
        userId: user.id,
      })
        .then(() => {
          setTranslateStatus('success');
        })
        .catch((error) => {
          setTranslateStatus('error');
          console.error(error);
        });
    }
  };

  const open = (() =>
    [null, 'saving', 'unsaved'].includes(status) ? false : true)();

  return (
    <CustomDialog
      open={open}
      onClose={handleCloseMessageDialog}
      contentElement={
        <MessageContent
          message={message}
          status={status}
          translateStatus={translateStatus}
          isNew={isNew}
          onTranslate={handleTranslateArticle}
          onClose={handleCloseMessageDialog}
        />
      }
    />
  );
};

export default MessageDialog;
