import CustomDialog from '../../../_reusable/CustomDialog';
import SynthButton from './SynthButton';
import SynthDialog from './SynthDialog';

const Synthesization = ({ buttonStyle }) => {
  return (
    <CustomDialog
      triggerElement={<SynthButton style={buttonStyle} />}
      contentElement={<SynthDialog />}
      size="100"
    />
  );
};

export default Synthesization;
