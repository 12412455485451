import { get, post, put } from './dataSource';

const URL_BASE_USER = 'user';

export const signIn = async ({ data }) => {
  return await post(URL_BASE_USER, data);
};

export const getUserInfo = async ({ userId }) => {
  return await get(URL_BASE_USER);
};

export const changeUserPassword = async (data) => {
  return await put(URL_BASE_USER, data);
};
