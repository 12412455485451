import styled from '@emotion/styled';
import {
  Cross2Icon,
  MixerHorizontalIcon,
} from '@radix-ui/react-icons';
import { useContext } from 'react';
import {
  ProjectDispatchContext,
  ProjectStateContext,
  updateEditMode,
} from '../../../../providers/ProjectProvider';
import Synthesization from '../../../Article/NavMenu/Synthesization';
import Translation from '../../../Article/NavMenu/Translation';
import CustomButton from '../../../_reusable/CustomButton';
import { INTERACT_COLOR } from '../../../_reusable/theme';
import DeleteArticles from './DeleteArticles';

const EditModeActionArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const EditMode = () => {
  const { editMode, editModeGetRecableIds } =
    useContext(ProjectStateContext);
  const dispatch = useContext(ProjectDispatchContext);

  const buttonStyle = (() => {
    if (editMode)
      return {
        color: INTERACT_COLOR,
        background: 'rgba(46, 170, 220, 0.1)',
        hoverBackground: 'rgba(46, 170, 220, 0.2)',
        hoverColor: INTERACT_COLOR,
      };
    return {};
  })();

  const buttonContent = (() => {
    if (editMode)
      return (
        <>
          <Cross2Icon /> Cancel
        </>
      );

    return (
      <>
        <MixerHorizontalIcon /> Actions
      </>
    );
  })();

  const handleToggleEditMode = () => {
    dispatch(updateEditMode(!editMode));
  };

  return (
    <EditModeActionArea>
      {editMode && Boolean(editModeGetRecableIds.length) && (
        <>
          <DeleteArticles />
          <Translation buttonStyle={buttonStyle} />
          <Synthesization buttonStyle={buttonStyle} />
        </>
      )}
      <CustomButton {...buttonStyle} onClick={handleToggleEditMode}>
        {buttonContent}
      </CustomButton>
    </EditModeActionArea>
  );
};

export default EditMode;
