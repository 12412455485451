import { post, remove } from './dataSource';

const URL_BASE_UPLOAD_BLOB = 'upload_blob';

export const uploadBlob = async ({ type, userId, fileName, data }) => {
  return await post(
    URL_BASE_UPLOAD_BLOB,
    data,
    {
      content_type_id: type,
      name_file: fileName || undefined,
      user_id: userId,
    },
    {
      'Content-Type': data.type,
    }
  );
};

export const deleteBlob = async ({ urlBlob, userId }) => {

  return await remove(
    URL_BASE_UPLOAD_BLOB,
    {
      url_blob: urlBlob,
      user_id: userId,
    }
  );
};
