import React from 'react';
import {
  CustomSelectItem,
  SelectViewport,
} from '../../../../_reusable/CustomSelect';

const ArticleLinkSelect = React.forwardRef(({ options, ...props }, ref) => {
  return (
    <SelectViewport ref={ref} {...props}>
      {options.length ? (
        options.map((option) => (
          <CustomSelectItem
            key={option.recable_id}
            value={option.recable_id}
            label={option.name}
          />
        ))
      ) : (
        <CustomSelectItem label="No linkable article." value={null} disabled />
      )}
    </SelectViewport>
  );
});

export default ArticleLinkSelect;
