import React from 'react';
import { ListBulletIcon } from '@radix-ui/react-icons';
import CustomButton from '../../../_reusable/CustomButton';

const FilterButton = React.forwardRef((props, ref) => {
  return (
    <CustomButton ref={ref} {...props}>
      <ListBulletIcon />
      Filters
    </CustomButton>
  );
});

export default FilterButton;
