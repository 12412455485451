import { MobileIcon } from '@radix-ui/react-icons';
import CustomButton from './CustomButton';

const LINK_STYLE = {
    textDecoration: 'none',
    display: 'flex-row',
    justifyContent: 'left',
    alignItems: 'center',
    gap: '4px',
}

const CustomWebappLink = ({ webAppLink }) => {
    return (
        <CustomButton>
            <a target="_blank" rel="noreferrer" href={webAppLink} style={LINK_STYLE}>
                <MobileIcon /> View in web app
            </a>
        </CustomButton>
    );
};

export default CustomWebappLink;
