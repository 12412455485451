import styled from '@emotion/styled';
import { Cross1Icon, PlusIcon } from '@radix-ui/react-icons';
import { useContext } from 'react';
import { uploadBlob } from '../../../../api/uploadBlob';
import {
  ArticleDispatchContext,
  articleError,
  updateProperty,
} from '../../../../providers/ArticleProvider';
import { GlobalStateContext } from '../../../../providers/GlobalProvider';
import CustomButton from '../../../_reusable/CustomButton';
import {
  BACKGROUND,
  SIZE_FONT_MEDIUM,
  SIZE_FONT_WEIGHT_MEDIUM,
  COLOR,
  HOVER_BACKGROUND,
} from '../../../_reusable/theme';

const FileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FileInput = styled.input`
  display: none;
`;

const UploadFileLabel = styled.label`
  all: unset;
  display: inline-flex;
  width: 120px;
  background: ${(props) => props.background || BACKGROUND};
  gap: 4px;
  padding-left: 12px;
  transition: all 0.06s;
  cursor: pointer;
  align-items: center;
  justify-content: left;
  color: ${(props) => props.color || COLOR};
  font-family: inherit;
  font-weight: ${SIZE_FONT_WEIGHT_MEDIUM};
  font-size: ${SIZE_FONT_MEDIUM};
  border-radius: 4px;
  height: 32px;
  min-width: 32px;
  width: ${(props) => props.width || null};
  &:hover {
    background: ${HOVER_BACKGROUND};
  }
`;

const RemoveButtonWrapper = styled.div`
  display: flex;
  width: 160px;
`;

const Image = styled.img`
  width: 240px;
  height: 240px;
  object-fit: cover;
`;

const Video = styled.video`
  max-height: 240px;
  width: 360px;
  object-fit: cover;
`;

const Audio = styled.audio`
  width: 360px;
`;

const UploadButton = ({ type, typeText, onChange }) => {
  const typeAccept = (() => {
    switch (type) {
      case 'file_video':
        return 'video/*';
      case 'file_audio':
        return 'audio/*';
      case 'file_picture':
        return 'image/*';
      default:
        return 'image/*';
    }
  })();

  return (
    <UploadFileLabel htmlFor={type} style={{ 'width': '100%' }}>
      <PlusIcon />
      Upload {typeText}
      <FileInput
        type="file"
        id={type}
        hidden
        onChange={onChange}
        accept={typeAccept}
      />
    </UploadFileLabel>
  );
};

const RemoveButton = ({ typeText, onClick }) => {
  const handleClick = (event) => {
    event.preventDefault();
    onClick();
  };

  return (
    <RemoveButtonWrapper>
      <CustomButton onClick={handleClick}>
        <Cross1Icon />
        Remove {typeText}
      </CustomButton>
    </RemoveButtonWrapper>
  );
};

const MediaDisplay = ({ media, typeText }) => {
  if (!media) return null;

  const component = (() => {
    if (typeText === 'image') {
      return <Image src={media} alt={`Uploaded ${typeText}`} />;
    } else if (typeText === 'video') {
      return <Video src={media} alt={`Uploaded ${typeText}`} controls />;
    } else if (typeText === 'audio') {
      return (
        <Audio controls>
          <source src={media} alt={`Uploaded ${typeText}`} />
        </Audio>
      );
    }
    return null;
  })();

  return component;
};

const File = ({ property }) => {
  const { user } = useContext(GlobalStateContext);
  const dispatch = useContext(ArticleDispatchContext);

  const typeText = (() => {
    switch (property.recable_attribute_codename) {
      case 'urlvideo':
        return 'video';
      case 'urlaudio':
        return 'audio';
      case 'urlpicture':
        return 'image';
      default:
        return 'image';
    }
  })();

  const handleFileChange = (event) => {
    const [file] = event.target.files;
    uploadBlob({
      type: typeText,
      data: file,
      userId: user.id,
    })
      .then((res) => {
        dispatch(
          updateProperty({
            ...property,
            recable_info_text: res.data.url_blob,
          })
        );
      })
      .catch((error) => {
        dispatch(articleError(error.message));
      });
  };

  const handleFileRemove = () => {
    dispatch(
      updateProperty({
        ...property,
        recable_info_text: '',
      })
    );
  };

  return (
    <FileWrapper>
      {property.recable_info_text ? (
        <RemoveButton typeText={typeText} onClick={handleFileRemove} />
      ) : (
        <UploadButton typeText={typeText} onChange={handleFileChange} />
      )}
      <MediaDisplay media={property.recable_info_text} typeText={typeText} />
    </FileWrapper>
  );
};

export default File;
