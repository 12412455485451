import React, { useContext, useEffect, useState } from 'react';
import { ReloadIcon, PlusIcon, ArrowUpIcon, ArrowDownIcon, UpdateIcon } from '@radix-ui/react-icons';
import ModelCard from './ModelCard';

import CustomButton from '../../_reusable/CustomButton';
import ModelTesting from './ModelTesting';
import { GlobalStateContext } from '../../../providers/GlobalProvider';
import { createModel, getModels } from '../../../api/model';
import { INTERACT_BACKGROUND, INTERACT_COLOR, BACKGROUND_GREEN, COLOR_GREEN, COLOR_LIGHT, BACKGROUND_LIGHT } from '../../_reusable/theme';
import { PageArea, TextNormal, TextSubSection, WrapperHorizontalCustom } from '../../_reusable/styles';

const ModelBoard = () => {

  const { tenant, user } = useContext(GlobalStateContext)

  const [isVisible, setIsVisible] = useState(false);
  const [isShowOnlyModelsActiveAndAfter, setIsShowOnlyModelsActiveAndAfter] = useState(true);
  const [status, setStatus] = useState('init');
  const [models, setModels] = useState([]);

  useEffect(() => {
    ['init', 'loading'].includes(status) && tenant?.tenant_id &&
      getModels({ tenantId: tenant.tenant_id, })
        .then((res) => {
          setModels(res.data.result);
          setStatus('loaded');
        })
        .catch((error) => {
          console.error(error);
          setStatus('error');
        });
  }, [status, tenant]);

  const handleCreateModelSpeed = () => {
    tenant?.tenant_id && createModel({
      tenantId: tenant.tenant_id,
      userId: user.id,
      data: {}
    }).then((res) => {
      setStatus('loading');
    });
  };

  const handleCreateModelQuality = () => {
    tenant?.tenant_id && createModel({
      tenantId: tenant.tenant_id,
      userId: user.id,
      data: {
        model_epochs: 25,
        model_max_pictures_per_category: 1000,
        model_no_backgr: 3,
        model_no_crop: 3,
        model_no_pca: 3,
        model_no_rot: 3,
        model_no_shear: 3,
        model_no_squares: 3,
        model_steps_per_epochs: 25,
      }
    }).then((res) => {
      setStatus('loading');
    });
  };

  return (
    <PageArea>
      <TextSubSection>Model Board</TextSubSection>
      <WrapperHorizontalCustom>
        <CustomButton
          background={isVisible ? BACKGROUND_GREEN : BACKGROUND_LIGHT}
          color={isVisible ? COLOR_GREEN : COLOR_LIGHT}
          onClick={() => { if (tenant && models) { setIsVisible(!isVisible) } }}>
          {(tenant && models) ? (isVisible ? <ArrowUpIcon /> : <ArrowDownIcon />) : <UpdateIcon />}
          {(tenant && models) ? ('Toggle Model Board') : 'Loading Model Board'}
        </CustomButton>
        {isVisible &&
          <CustomButton
            background={isShowOnlyModelsActiveAndAfter ? BACKGROUND_GREEN : BACKGROUND_LIGHT}
            color={isShowOnlyModelsActiveAndAfter ? COLOR_GREEN : COLOR_LIGHT}
            onClick={() => { if (tenant && models) { setIsShowOnlyModelsActiveAndAfter(!isShowOnlyModelsActiveAndAfter) } }}>
            {isShowOnlyModelsActiveAndAfter ? <ArrowUpIcon /> : <ArrowDownIcon />}
            {'Only show active model and newer'}
          </CustomButton>
        }
      </WrapperHorizontalCustom>
      {tenant && models && isVisible && <>
        <TextNormal>Below is a list of all models and their current status.</TextNormal>
        <WrapperHorizontalCustom>
          <CustomButton
            onClick={() => setStatus('loading')}
            width='75px'
            background={INTERACT_BACKGROUND}
            color={INTERACT_COLOR}
          >
            <ReloadIcon />Refresh
          </CustomButton>
          <CustomButton
            onClick={handleCreateModelSpeed}
            width='250px'
            background={BACKGROUND_GREEN}
            color={COLOR_GREEN}
          >
            <PlusIcon /> New Model (Fast Training)
          </CustomButton>
          <CustomButton
            onClick={handleCreateModelQuality}
            width='250px'
            background={BACKGROUND_GREEN}
            color={COLOR_GREEN}
          >
            <PlusIcon /> New Model (Quality Training)
          </CustomButton>
        </WrapperHorizontalCustom>
        {models && models.filter((model) => { return (!isShowOnlyModelsActiveAndAfter) || (model.model_id >= models.filter((meta_model) => { return meta_model.model_is_active })[0].model_id) }).sort((a, b) => { return b.model_id - a.model_id }).map((model) => {
          return (<ModelCard key={model.model_base} model={model} setStatus={setStatus} />);
        })}
        {false && <ModelTesting />}
      </>}
    </PageArea>
  );
};

export default ModelBoard;
