import styled from '@emotion/styled';
import { useContext, useState } from 'react';
import { createProject, getProject, updateProject } from '../../../api/project';
import {
  GlobalStateContext,
  GlobalDispatchContext,
  addProjectToTop,
} from '../../../providers/GlobalProvider';
import MessageDialogProject from './MessageDialogProject';
import EditArea from './EditArea';
import NavMenu from './NavMenu';

const DialogWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  height: 100%;
`;

// has project when it is an existing project
const EditProjectCard = ({ project, onProjectUpdate, onClose }) => {
  const [status, setStatus] = useState(null);
  const [isShowMessageDialog, setIsShowMessageDialog] = useState(false);
  const [projectCreatedId, setProjectCreatedId] = useState(-1);

  const { user, tenant, currentLangId } = useContext(GlobalStateContext);
  const globalDispatch = useContext(GlobalDispatchContext);

  const [currentChanges, setCurrentChanges] = useState({
    project_pic_url: project?.project_pic_url || '',
    project_name: project?.project_name || '',
    project_description: project?.project_description || '',
    project_note: project?.project_note || '',
    project_type_id: project?.project_project_type_id || 1,
  });

  const handleChange = (event) => {
    setStatus('unsaved');
    setCurrentChanges((prev) => ({
      ...prev,
      [event.target.id]: event.target.value,
    }));
  };

  const handleClose = () => {
    onClose();
  };

  const handleSave = () => {
    setStatus('saving');
    if (project) {
      updateProject({
        userId: user.id || user.user_id,
        projectId: project.project_id,
        data: currentChanges,
      })
        .then(() => {
          setStatus(null);
          const updatedProject = {
            ...project,
            ...currentChanges,
          };
          onProjectUpdate(updatedProject);
        })
        .catch((error) => {
          setStatus('error');
          console.error(error);
        });
    } else {
      createProject({
        data: currentChanges,
        langId: currentLangId,
        userId: user.id || user.user_id,
        tenantId: tenant.id || tenant.tenant_id,
      })
        .then((res) => {
          onProjectUpdate(res.data);
          const { project_id: projectId } = res.data;
          setStatus('created');
          setProjectCreatedId(projectId);
          return getProject({
            projectId,
            userId: user.id || user.user_id,
            tenantId: tenant.id || tenant.tenant_id,
          });
        })
        .then((res) => {
          let newProject = { ...res.data.result, parameters: res.data.result.parameters?.parameter }
          globalDispatch(addProjectToTop(newProject));
          setIsShowMessageDialog(true);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  return (
    <DialogWrapper>
      <NavMenu
        project={project}
        status={status}
        onClose={handleClose}
        onSave={handleSave}
      />
      <EditArea
        currentChanges={currentChanges}
        onChange={handleChange}
        descriptionAudio={project?.project_audio}
        noteAudio={project?.project_audio_note}
      />
      <MessageDialogProject isShowMessageDialog={isShowMessageDialog} setIsShowMessageDialog={setIsShowMessageDialog} projectId={projectCreatedId} />
    </DialogWrapper>
  );
};

export default EditProjectCard;
