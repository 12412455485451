import { useContext } from 'react';
import {
  ArticleDispatchContext,
  updateProperty,
} from '../../../../providers/ArticleProvider';
import CustomInput from '../../../_reusable/CustomInput';

const StandardText = ({ property }) => {
  const dispatch = useContext(ArticleDispatchContext);
  const handleInputChange = (event) => {
    dispatch(
      updateProperty({
        ...property,
        recable_info_text: event.target.value,
      })
    );
  };

  return (
    <CustomInput
      value={property.recable_info_text || ''}
      onChange={handleInputChange}
    />
  );
};

export default StandardText;
