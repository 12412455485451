import React, { useContext, } from 'react';

import { COLOR } from '../_reusable/theme';
import { TextNormal, TextPageHeaderColor, WrapperVerticalLeft } from '../_reusable/styles.js';
import { GlobalStateContext } from '../../providers/GlobalProvider';
import ChangePassword from './ChangePassword';
import Phoneme from './Phoneme';
import TenantSettings from './TenantSettings';
import ModelBoard from './ModelBoard';
import TrainingSampleBoard from './TrainingSampleBoard';
import QRCodeTenant from './QRCodeTenant';

const TenantAdmin = () => {
  const { tenant } = useContext(GlobalStateContext);

  if (!(tenant)) {
    return <TextNormal>Loading</TextNormal>
  }

  return (
    <WrapperVerticalLeft>
      <TextPageHeaderColor color={COLOR}>{tenant?.tenant_name} Admin Panel</TextPageHeaderColor>
      <ChangePassword />
      <QRCodeTenant />
      <TenantSettings />
      <Phoneme />
      <ModelBoard />
      <TrainingSampleBoard />
    </WrapperVerticalLeft>
  );
};

export default TenantAdmin;
