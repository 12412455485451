import { useCallback, useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import { ArticleStateContext, ArticleDispatchContext, articleSuccess, updateRecable, toggleProperty } from '../../../providers/ArticleProvider';
import RichText from './RichText';
import File from './File';
import CustomScrollArea from '../../_reusable/CustomScrollArea';
import Interview from './Interview';
import StandardText from './StandardText';
import AudioButton from '../../ProjectBoard/EditProjectCard/EditArea/AudioButton';
import CustomButton from '../../_reusable/CustomButton';
import { TrashIcon } from '@radix-ui/react-icons';
import { TextSubSection, WrapperHorizontal } from '../../_reusable/styles';
import { deleteRecableInfo, getRecable } from '../../../api/recable';

const EditAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const PropertiesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const PropertyRowLayoutWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const PropertyRowContentWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 16px;
`;

const PropertyRowLayout = ({ property, contentElement }) => {

  const dispatch = useContext(ArticleDispatchContext);

  const { recable_attribute_lang_name: title, recable_info_enhancement_audio: media } =
    property;

  const handleDeleteRecableInfo = () => {
    dispatch(toggleProperty(property.recable_attribute_lang_name));
    deleteRecableInfo({
      recableId: property.recable_info_recable_id,
      projectId: property.recable_info_project_id,
      recableAttributeId: property.recable_info_recable_attribute_id,
    }).then(
      getRecable({
        recableId: property.recable_info_recable_id,
        langId: property.recable_info_lang_id,
        projectId: property.recable_info_project_id,
      }).then(
        (res) => dispatch(updateRecable(res.data.result))
      ).catch(
        (err) => console.error('Caught error: ', err)
      )
    ).then(
      () => dispatch(articleSuccess('Successfully removed the chosen attribute.'))
    ).catch(
      (err) => console.error('Caught error: ', err)
    )
  }


  return (
    <div>
      <TextSubSection>
        {title}
      </TextSubSection>
      <PropertyRowLayoutWrapper>
        <WrapperHorizontal style={{ width: '200px' }}>
          <CustomButton onClick={handleDeleteRecableInfo}><TrashIcon /></CustomButton>
          {media && <AudioButton media={media} />}
        </WrapperHorizontal>
        <PropertyRowContentWrapper>{contentElement}</PropertyRowContentWrapper>
      </PropertyRowLayoutWrapper>
    </div>
  );
};

const EditArea = () => {
  const { currentProperties } = useContext(ArticleStateContext);
  const propertyElementFactory = useCallback((property) => {
    const { recable_attribute_field_type } = property;

    switch (recable_attribute_field_type) {
      case 'text_interview':
        return (
          <PropertyRowLayout
            key={property.recable_attribute_id}
            property={property}
            contentElement={<Interview property={property} />}
          />
        );
      case 'text_standard':
        return (
          <PropertyRowLayout
            key={property.recable_attribute_id}
            property={property}
            contentElement={<StandardText property={property} />}
          />
        );
      case 'text_rich':
        return (
          <PropertyRowLayout
            key={property.recable_attribute_id}
            property={property}
            contentElement={<RichText property={property} />}
          />
        );
      case 'file_audio':
      case 'file_video':
      case 'file_picture':
        return (
          <PropertyRowLayout
            key={property.recable_attribute_id}
            property={property}
            contentElement={<File property={property} />}
          />
        );
      default:
        console.error(
          `Undefined recable_attribute_field_type: ${recable_attribute_field_type}`
        );
        return null;
    }
  }, []);

  const propertyComponents = useMemo(
    () => currentProperties.map((property) => propertyElementFactory(property)),
    [currentProperties, propertyElementFactory]
  );

  return (
    <EditAreaWrapper>
      <CustomScrollArea>
        <PropertiesWrapper>{propertyComponents}</PropertiesWrapper>
      </CustomScrollArea>
    </EditAreaWrapper>
  );
};

export default EditArea;
