import styled from '@emotion/styled';
import { ExitIcon, HomeIcon } from '@radix-ui/react-icons';
import { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  clearUserInfo,
  GlobalDispatchContext,
  GlobalStateContext,
} from '../../providers/GlobalProvider';
import CustomButton from '../_reusable/CustomButton';
import { SHAIRE_COLOR } from '../_reusable/theme';
import LanguageSelector from './LanguageSelector';

const AppBarWrapper = styled.div`
  position: sticky;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${SHAIRE_COLOR};
  height: 48px;
  padding-left: 20px;
`;

const Title = styled.div`
  color: white;
  font-size: 24px;
  font-weight: 800;
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

const AvatarWrapper = styled.div`
  position: fixed;
  top: 8px;
  right: 8px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  margin-left: auto;
  font-weight: 800;
  color: white;
  font-size: 14px;
`;

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  color: white;
  font-weight: 600;
  font-size: 16px;
  align-items: center;

  a {
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 4px;
    color: inherit;
  }
`;

const NavLinks = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const AppBar = () => {
  const navigate = useNavigate();
  const { tenant, user } = useContext(GlobalStateContext);
  const globalDispatch = useContext(GlobalDispatchContext);

  const handleSignout = () => {
    localStorage.removeItem('userInfo');
    globalDispatch(clearUserInfo());
    navigate('/signin');
  };

  return (
    <AppBarWrapper>
      <NavLinks>
        <LinkWrapper>
          <Link to="/">Home</Link>
        </LinkWrapper>
        <LinkWrapper>
          <Link to="/tenant" disabled={tenant ? false : true}>Admin</Link>
        </LinkWrapper>
      </NavLinks>

      <Title>Liara.</Title>
      <AvatarWrapper>
        {user?.username}
        <LanguageSelector />
        <CustomButton
          background="white"
          color={SHAIRE_COLOR}
          onClick={handleSignout}
        >
          Sign out
          <ExitIcon />
        </CustomButton>
      </AvatarWrapper>
    </AppBarWrapper>
  );
};

export default AppBar;
