import { useRef } from 'react';
import { QRCode } from 'react-qrcode-logo'
import styled from '@emotion/styled';

import { WrapperVerticalLeft } from './styles';
import { BACKGROUND_GREEN, COLOR_GREEN, HOVER_BACKGROUND_GREEN } from './theme';
import CustomButton from './CustomButton';
import { DownloadIcon } from '@radix-ui/react-icons';

const QRCodeContainer = styled.div`
  height: auto;
  max-width: 200px;
  width: 100%;
`

const exportAsImage = async (element, imageFileName) => {
  const image = element.canvas.current.toDataURL("image/png", 1.0);
  downloadImage(image, imageFileName);
};

const downloadImage = (blob, fileName) => {
  const fakeLink = window.document.createElement("a");
  fakeLink.style = "display:none;";
  fakeLink.download = fileName;

  fakeLink.href = blob;

  document.body.appendChild(fakeLink);
  fakeLink.click();
  document.body.removeChild(fakeLink);

  fakeLink.remove();
};

const QRCodeView = ({webAppLink = '', urlpicture=''}) => {
  const qrCodeRef = useRef();
  
  return (
    <WrapperVerticalLeft>
      <QRCodeContainer >
        <QRCode
          enableCORS={true}
          qrStyle={'dots'}
          size={200}
          value={webAppLink}
          viewBox={`0 0 100 100`}
          id={webAppLink}
        />
      </QRCodeContainer>
      <QRCodeContainer style={{display: 'none'}}>
        <QRCode
          enableCORS={true}
          ref={qrCodeRef}
          qrStyle={'dots'}
          size={1000}
          value={webAppLink}
          id={webAppLink}
        />
      </QRCodeContainer>
      <CustomButton
        color={COLOR_GREEN}
        hoverColor={COLOR_GREEN}
        background={BACKGROUND_GREEN}
        hoverBackground={HOVER_BACKGROUND_GREEN}
        onClick={() => exportAsImage(qrCodeRef.current, "Qrcode")}><DownloadIcon/>QRCode as image  
      </CustomButton>
    </WrapperVerticalLeft>
  );
};

export default QRCodeView;
