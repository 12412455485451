import axios from 'axios';

export const AZURE_STAGE = (() => {
  if (
    ['localhost', 'liara-cockpit-dev.shaire-group.com'].includes(
      window.location.hostname
    )
  ) {
    return 'dev';
  }
  return 'prod';
})();
if (AZURE_STAGE === 'dev') console.log('Development Environment')

export const AZURE_HOST_PROTOCOL = 'https';
export const AZURE_HOST_STAGES = {
  dev: 'fa-ss-dev.azurewebsites.net',
  prod: 'fa-ss-prod.azurewebsites.net',
};

export const AZURE_CODE_STAGES = {
  dev: '6ebUI36VV8/n4gu077LKeCcQoxgNWH02XuP07OTphbKSjMuRLYkV9g==',
  prod: 'SR4EpAk01/4DgzeV00p4fbVWuWz1P7196CcHPRoGTFSacl4cjGFrMg==',
};

export const AZURE_HOST = AZURE_HOST_STAGES[AZURE_STAGE];
export const BASE_AZURE_URL = `${AZURE_HOST_PROTOCOL}://${AZURE_HOST}/`;
export const API_SUFFIX = 'api/';
export const BASE_API_URL = `${BASE_AZURE_URL}${API_SUFFIX}`;

const AZURE_CODE = AZURE_CODE_STAGES[AZURE_STAGE];

const instance = axios.create({
  baseURL: BASE_API_URL,
});

export const AZURE_SPEECH_SERVICE_KEYS = {
  prod: {
    key1: '36031a9b116b48f28ea38675facd14c5',
    key2: 'defd2b94c1104c5987e95ddb12d6869d',
    location: 'switzerlandnorth',
    endpoint:
      'https://switzerlandnorth.api.cognitive.microsoft.com/sts/v1.0/issuetoken',
  },
  dev: {
    key1: 'f91918fe1642460486859831e8a852c2',
    key2: '04697147ce5c464d83642bbb0292bffd',
    location: 'switzerlandnorth',
    endpoint:
      'https://switzerlandnorth.api.cognitive.microsoft.com/sts/v1.0/issuetoken',
  },
};

instance.defaults.headers.get['Content-Type'] = 'application/json';
instance.defaults.headers.post['Content-Type'] = 'application/json';
instance.defaults.headers.put['Content-Type'] = 'application/json';
instance.defaults.headers.delete['Content-Type'] = 'application/json';
instance.defaults.headers.get['x-functions-key'] = AZURE_CODE;
instance.defaults.headers.post['x-functions-key'] = AZURE_CODE;
instance.defaults.headers.put['x-functions-key'] = AZURE_CODE;
instance.defaults.headers.delete['x-functions-key'] = AZURE_CODE;

export const get = async (api, params) => {
  return await instance.get(api, {
    params: { ...params },
  });
};

export const post = async (api, data, params, headers) => {
  return await instance.post(api, data, {
    params: { ...params },
    headers,
  });
};

export const put = async (api, data, params, headers) => {
  return await instance.put(api, data, {
    params: { ...params },
    headers,
  });
};

export const remove = async (api, params) => {
  return await instance.delete(api, {
    params: { ...params },
  });
};
