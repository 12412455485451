import CustomDialog from '../../../_reusable/CustomDialog';
import Content from './Content';
import Trigger from './Trigger';

const Settings = () => {
  return (
    <CustomDialog
      triggerElement={<Trigger />}
      contentElement={<Content />}
    />
  );
};

export default Settings;
