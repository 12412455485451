import React from 'react';
import styled from '@emotion/styled';
import moment from 'moment';
import { COLOR } from '../../../_reusable/theme';

const CustomChip = styled.span`
  font-size: 14px;
  margin-top: 2px;
  margin-right: 2px;
  border-radius: 4px;
  transition: background 100ms, color 100ms;
  font-weight: 500;
  color: ${COLOR};
  background: none;
`;

const Date = ({ text }) => {
  const label = text.toUpperCase();
  return <CustomChip>{label}</CustomChip>;
};

export default function DateCell({ cell }) {
  if (!cell.value) return null;

  const date = moment(cell.value, moment.ISO_8601, true).format(
    'DD.MM.YYYY hh:ss'
  );

  return <Date key={`${cell.row.id}-${cell.value}`} text={date} />;
}
