import React from 'react';
import styled from '@emotion/styled';
import {
  BACKGROUND_GREEN,
  COLOR,
  COLOR_GREEN,
  HOVER_BACKGROUND_GREEN,
} from '../../_reusable/theme';
import CustomInput from '../../_reusable/CustomInput';
import { useState } from 'react';
import { Cross2Icon } from '@radix-ui/react-icons';
import CustomButton from '../../_reusable/CustomButton';
import { user as userAPI } from '../../../api';
import { useContext } from 'react';
import { GlobalStateContext } from '../../../providers/GlobalProvider';

const Title = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: ${COLOR};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 64px;
  gap: 12px;
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: 600;
  color: ${COLOR};
`;

const Message = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  color: burgundy;
  font-size: 14px;
  font-weight: 400;
  height: 24px;
  margin-top: 10px;
`;

const ControlAreaWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  margin-top: 40px;
`;

// regex password minimum 10 characters
const passwordRegex = /^.{10,}$/;

const Content = React.forwardRef((props, ref) => {
  const { user } = useContext(GlobalStateContext);
  const [errorMessage, setErrorMessage] = useState('');
  const [status, setStatus] = useState('');

  const buttonText = (() => {
    if (status === 'loading') {
      return 'Saving';
    } else if (status === 'error') {
      return 'Try again';
    } else if (status === 'success') {
      return 'New password successfully set.';
    }
    return 'Submit new password';
  })();

  const handleFormChange = () => {
    errorMessage && setErrorMessage('');
    status && setStatus('');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { user_password_old, user_password_new, user_password_new_confirm } = event.target.elements;
    if (user_password_old === '') {
      setErrorMessage('Old password is required.');
      return;
    }
    if (!passwordRegex.test(user_password_new.value)) {
      setErrorMessage('New password must be at least 10 characters long.');
      return;
    }
    if (!(user_password_new.value === user_password_new_confirm.value)) {
      setErrorMessage('New password entries do not match.');
      return;
    }
    const data = {
      user_email: user.user_email,
      user_password_old: user_password_old.value,
      user_password_new: user_password_new.value,
    };
    setStatus('loading');
    userAPI
      .changeUserPassword(data)
      .then(() => {
        setStatus('success');
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message);
        setStatus('error');
      });
  };

  return (
    <div {...props} ref={ref}>
      <Title>Set a new password</Title>
      <Form onSubmit={handleSubmit} onChange={handleFormChange}>
        <Label htmlFor="email">Old password</Label>
        <CustomInput
          id="user_password_old"
          name="old_password_old"
          type="password"
          autoFocus
        />
        <Label htmlFor="password">New password</Label>
        <CustomInput
          id="user_password_new"
          name="user_password_new"
          type="password"
        />
        <Label htmlFor="password_confirm">Confirm new password</Label>
        <CustomInput
          id="user_password_new_confirm"
          name="user_password_new_confirm"
          type="password"
        />
        <Message>
          {errorMessage && <Cross2Icon color="red" />}
          {errorMessage}
        </Message>
        <ControlAreaWrapper>
          <CustomButton
            type="submit"
            background={BACKGROUND_GREEN}
            hoverBackground={HOVER_BACKGROUND_GREEN}
            color={COLOR_GREEN}
            hoverColor={COLOR_GREEN}
            justifyContent="center"
          >
            {buttonText}
          </CustomButton>
        </ControlAreaWrapper>
      </Form>
    </div>
  );
});

export default Content;
