import { useMemo, useCallback } from 'react';
import { createEditor } from 'slate';
import { Slate, Editable, withReact } from 'slate-react';
import convertJsonStringToRichTextObject from '../../utils/convertJsonStringToRichTextObject';
import { Element, Leaf } from '../Article/EditArea/RichText';

const ReadOnlyRichText = ({ jsonString }) => {
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const richText = convertJsonStringToRichTextObject(jsonString);
  const editor = useMemo(() => withReact(createEditor()), []);
  // even if props already changed.
  // necessary to prevent editor rendering old text

  editor.children = richText;
  try {
    return (
      <Slate editor={editor} value={richText}>
        <Editable
          readOnly
          placeholder="Click Edit to add content"
          renderElement={renderElement}
          renderLeaf={renderLeaf}
        />
      </Slate>
    );
  } catch (error) {
    console.error(error);
    return <div>Error</div>;
  }
};

export default ReadOnlyRichText;
