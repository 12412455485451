import React, { useContext, } from 'react';

import { GlobalStateContext } from '../../../providers/GlobalProvider';
import QRCodeView from '../../_reusable/CustomQRCode';
import { PageArea, TextNormal, TextSubSection, WrapperVerticalLeft } from '../../_reusable/styles';

const QRCodeTenant = () => {
    const { tenant, currentLangId } = useContext(GlobalStateContext);

    if (!(tenant && currentLangId)) {
        return <TextNormal>Loading</TextNormal>
    }

    const webappLink = `${tenant.tenant_domain}/${currentLangId}`

    return (
        <PageArea>
            <TextSubSection>QR Code Webapp</TextSubSection>
            <WrapperVerticalLeft style={{ width: '250px' }}>
                <QRCodeView webAppLink={webappLink} />
            </WrapperVerticalLeft>
        </PageArea>
    );
};

export default QRCodeTenant;
