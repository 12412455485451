import React, { useCallback, useMemo, useState } from 'react';
import { useBlockLayout, useResizeColumns, useTable } from 'react-table';
import styled from '@emotion/styled';
import { COLOR } from '../../_reusable/theme';

const CustomTable = styled.div`
  display: table;
  table-layout: auto;
  margin-top: 48px;
  border-bottom: 0;
  width: 100%;
  flex: 1 1 600px;
  background: white;
  font-size: 14px;
  font-weight: 500;
  color: ${COLOR};
`;

const CustomTableRowGroup = styled.div`
`;

const CustomTableHeaderGroup = styled.div`
`;

const CustomHeader = styled.div`
  min-height: 32px;
  max-width: 100%;
  cursor: pointer;
  color: rgba(55, 53, 47, 0.8);
  background: white;
  border-bottom: 1px solid rgba(55, 53, 47, 0.1);
  font-weight: 600;
  padding: 0.2rem;
`;

const CustomRow = styled.div`
  display: table-row;
  cursor: pointer;
  overflow: hidden;
  &.hover {
    .cell {
      background: rgba(55, 53, 47, 0.04);
    }
  }
`;

const CustomCell = styled.div`
  display: table-row;
  border-left: 1px solid rgba(55, 53, 47, 0);
  border-right: 1px solid rgba(55, 53, 47, 0);
  border-bottom: 1px solid rgba(55, 53, 47, 0.1);
  margin: 0;
  padding: 0.4rem 0.2rem;
`;

const Resizer = styled.div`
  display: inline-block;
  background: rgba(55, 53, 47, 0.1);
  width: 2px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  ${'' /* prevents from scrolling while dragging on touch devices */}
  touch-action:none;
  &.resizing {
    width: 8px;
  }
`;

const Header = ({ column, ...props }) => {
  const content = column.render('Header');
  return (
    <CustomHeader {...props}>
      {content}
      <Resizer
        {...column.getResizerProps()}
        className={`${column.isResizing ? 'resizing' : ''}`}
      />
    </CustomHeader>
  );
};

const Cell = ({ cell, onCellClick, ...props }) => {
  const defaultCellContent = cell.render('Cell');
  let content = {
    ...defaultCellContent,
    props: {
      ...defaultCellContent.props,
      onClick: onCellClick,
    },
  };

  return (
    <CustomCell id={cell.getCellProps().key} className="cell" {...props}>
      {content}
    </CustomCell>
  );
};

const Row = ({ row, onRowClick, ...props }) => {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setHovered(true);
  }, [setHovered]);

  const handleMouseLeave = useCallback(() => {
    setHovered(false);
  }, [setHovered]);

  const handleRowClick = (event) => {
    onRowClick({ event, rowValue: row.original });
  };

  return (
    <CustomRow
      {...props}
      key={row.original.recable_id}
      className={`${hovered ? 'hover' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleRowClick}
    >
      {row.cells.map((cell) => (
        <Cell
          className={`cell ${hovered ? 'row-hover' : ''}`}
          {...cell.getCellProps()}
          cell={cell}
          onCellClick={handleRowClick}
        />
      ))}
    </CustomRow>
  );
};

export default function Table({ columns, data, onRowEvent }) {
  const defaultColumn = useMemo(
    () => ({
      minWidth: 40,
      width: 300,
      maxWidth: 600,
    }),
    []
  );

  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
      },
      useBlockLayout,
      useResizeColumns
    );

  const tableProps = useMemo(() => getTableProps(), [getTableProps]);
  const tableBodyProps = useMemo(
    () => getTableBodyProps(),
    [getTableBodyProps]
  );

  return (
    <CustomTable {...tableProps}>
      <CustomTableHeaderGroup>
        {headers.map((column) => (
          <Header {...column.getHeaderProps()} column={column} />
        ))}
      </CustomTableHeaderGroup>

      <CustomTableRowGroup {...tableBodyProps}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <Row {...row.getRowProps()} row={row} onRowClick={onRowEvent} />
          );
        })}
      </CustomTableRowGroup>
    </CustomTable>
  );
}
