import styled from '@emotion/styled';
import { GearIcon } from '@radix-ui/react-icons';
import React, { useContext } from 'react';
import { useCallback } from 'react';
import { useMemo } from 'react';
import { getProject, updateProject } from '../../../api/project';
import {
  GlobalDispatchContext,
  GlobalStateContext,
  updateProjectById,
} from '../../../providers/GlobalProvider';
import CustomButton from '../../_reusable/CustomButton';
import CustomCheckbox from '../../_reusable/CustomCheckbox';
import CustomPopover from '../../_reusable/CustomPopover';
import CustomTooltip from '../../_reusable/CustomTooltip';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Tooltip = styled.div`
border - radius: 4px;
padding: 12px;
background: rgba(0, 0, 0, 0.6);
color: white;
font - size: 12px;
font - weight: 500;
width: 240px;
`;

const serializeParamToComponent = (param, onParamChange) => {
  switch (param.type) {
    case 'boolean':
      return (
        <CustomTooltip
          key={param.key}
          triggerElement={
            <CustomCheckbox
              key={param.key}
              id={param.key}
              isChecked={param.value === 'True'}
              label={param.label}
              onCheckChange={onParamChange}
            />
          }
          contentElement={<Tooltip>{param.description}</Tooltip>}
        />
      );
    default:
      console.error(`Undefined project parameter type: ${param.type}`);
      return null;
  }
};

const Trigger = React.forwardRef((props, ref) => {
  return (
    <span>
      <CustomButton {...props} ref={ref}>
        <GearIcon />
        Settings
      </CustomButton>
    </span>
  );
});

const Content = React.forwardRef(({ project, ...props }, ref) => {
  const { parameters: projectParams, project_id: projectId } = project;
  const { user, tenant } = useContext(GlobalStateContext);
  const globalDispatch = useContext(GlobalDispatchContext);

  const handleBooleanParamChange = useCallback(
    ({ key, value }) => {
      updateProject({ userId: user.id, projectId, data: { [key]: value } })
        .then(() =>
          getProject({ projectId, userId: user.id, tenantId: tenant.id })
        )
        .then((res) => {
          globalDispatch(updateProjectById(res.data));
        })
        .catch((error) => console.error(error));
    },
    [user, projectId, tenant, globalDispatch]
  );

  const paramComponents = useMemo(
    () =>
      projectParams.map((param) =>
        serializeParamToComponent(param, handleBooleanParamChange)
      ),
    [projectParams, handleBooleanParamChange]
  );

  return (
    <ContentWrapper {...props} ref={ref}>
      {paramComponents}
    </ContentWrapper>
  );
});

const Settings = ({ project }) => {
  return (
    <>
      <CustomPopover
        triggerElement={<Trigger />}
        contentElement={<Content project={project} />}
        hasCloseButton
        align="start"
      />
    </>
  );
};

export default Settings;
