import styled from '@emotion/styled';
import { customAlphabet } from 'nanoid';
import {
  CheckIcon,
  Cross2Icon,
  Pencil1Icon,
  TrashIcon,
} from '@radix-ui/react-icons';
import moment from 'moment';
import { useState } from 'react';
import { deleteProject } from '../../api/project';
import CustomButton from '../_reusable/CustomButton';
import {
  BACKGROUND,
  COLOR,
  COLOR_LIGHT,
  SHAIRE_COLOR,
} from '../_reusable/theme';
import ReadOnlyRichText from '../_reusable/ReadOnlyRichText';
import { useNavigate } from 'react-router-dom';
import Settings from './Settings';
import CustomDialog from '../_reusable/CustomDialog';
import EditProjectCard from './EditProjectCard';
import WebAppLinkProject from './WebAppLinkProject';

const nanoid = customAlphabet('1234567890abcdef', 10);

const ProjectCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 12px;
  background: white;
  overflow: hidden;
  font-size: 14px;

  &:hover {
    background: ${BACKGROUND};
    border-radius: 4px;
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 12px;
`;

const ContentArea = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 24px;
`;

const Image = styled.img`
  height: 80px;
  width: 80px;
  object-fit: cover;
  cursor: pointer;
`;

const TextArea = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: ${(props) => (props.editMode ? '12px' : '0')};
`;

const InfoArea = styled.div`
  display: flex;
  flex-direction: column;
  text-align: justify; /* For Edge */
  text-align: right;
  align-items: right;
  color: ${COLOR_LIGHT};
  gap: 8px;
`;

const Title = styled.div`
  display: flex;
  flex-grow: 1;
  line-height: 20px;
  color: ${COLOR};
  font-weight: ${(props) => (props.contentEditable ? '400' : '600')};
  background: ${(props) => (props.contentEditable ? BACKGROUND : 'inherit')};
  padding: ${(props) => (props.contentEditable ? '8px' : 'none')};
  border-radius: 4px;
  padding-bottom: 8px;

  a {
    text-decoration: none;
    font-size: 16px;
    color: ${SHAIRE_COLOR};
  }
`;

const Signature = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 40px;
  font-size: 10px;
  font-style: italic;
  color: ${COLOR_LIGHT};
  text-align: right;
`;

const SettingArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: end;
  gap: 8px;
  min-height: 40px;
`;

const DeleteConfirmArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const ProjectCard = ({ project, onProjectDeleted, onProjectUpdated }) => {
  const navigate = useNavigate();
  const prettifiedMoment = moment(
    project.createdat,
    moment.ISO_8601,
    true
  ).format('D.MM.YY h:mm A');

  const handleGoToProjectDetail = () => {
    navigate(`/project/${project.project_id}`);
  };

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const handleToggleDeleteConfirm = () => {
    setShowDeleteConfirm((prev) => !prev);
  };

  const [editMode, setEditMode] = useState(false);
  const [key, setKey] = useState(nanoid());
  const handleToggleEditMode = () => {
    if (editMode) setKey(nanoid());
    setEditMode((prev) => !prev);
  };

  const handleDeleteProject = () => {
    deleteProject({ projectId: project.project_id })
      .then(() => {
        onProjectDeleted(project.project_id);
      })
      .catch((error) => console.error(error));
  };

  const handleStopClickEffect = (event) => {
    event.stopPropagation();
  };

  return (
    <ProjectCardWrapper id={project.project_id}>
      <Main key={key}>
        <ContentArea>
          <Image src={project.project_pic_url} onClick={handleGoToProjectDetail} />

          <TextArea editMode={editMode}>
            <span>
              <Title onClick={handleGoToProjectDetail}>{project.project_name}</Title>
            </span>

            <span>
              <ReadOnlyRichText jsonString={project.project_description} />
            </span>
            <span>
              <ReadOnlyRichText jsonString={project.project_note} />
            </span>
          </TextArea>
          <Signature>
            <div>{project.createdby_user_name}</div>
            <div>{prettifiedMoment}</div>
          </Signature>
        </ContentArea>

        <InfoArea>
          <SettingArea onClick={handleStopClickEffect}>

            <span>
              <WebAppLinkProject projectId={project.project_id} />
            </span>

            <span>
              <CustomButton id="edit" onClick={handleToggleEditMode}>
                <Pencil1Icon /> Edit
              </CustomButton>
            </span>

            <span>
              <Settings project={project} />
            </span>

            {!showDeleteConfirm ? (
              <span>
                <CustomButton id="delete" onClick={handleToggleDeleteConfirm}>
                  <TrashIcon /> Delete
                </CustomButton>
              </span>
            ) : (
              <DeleteConfirmArea>
                <span>
                  <CustomButton onClick={handleDeleteProject}>
                    <CheckIcon />
                    Confirm
                  </CustomButton>
                </span>
                <span>
                  <CustomButton onClick={handleToggleDeleteConfirm}>
                    <Cross2Icon />
                    Cancel
                  </CustomButton>
                </span>
              </DeleteConfirmArea>
            )}
          </SettingArea>
        </InfoArea>
      </Main>

      <CustomDialog
        open={editMode}
        onClose={handleToggleEditMode}
        size="100"
        contentElement={
          <EditProjectCard
            project={project}
            onProjectUpdate={onProjectUpdated}
            onClose={handleToggleEditMode}
          />
        }
      />
    </ProjectCardWrapper>
  );
};

export default ProjectCard;
