import React, { useState } from 'react';
import styled from '@emotion/styled';
import { COLOR, COLOR_LIGHT } from '../../../_reusable/theme';
// import CustomButton from '../../../_reusable/CustomButton';
// import { PlusIcon } from '@radix-ui/react-icons';
import FilterCriterion from './FilterCriterion';

const PopoverWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: ${COLOR};
`;

const Subtitle = styled.div`
  font-size: 12px;
  color: ${COLOR_LIGHT};
  width: 100%;
`;

const FilterAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 16px;
  padding-bottom: 16px;
`;

const FilterPopover = React.forwardRef(({ options, ...props }, ref) => {
  // const handleAddFilter = () => {
  //   setFilters((prev) => [...prev, DEFAULT_FILTER]);
  // };

  return (
    <PopoverWrapper ref={ref} {...props}>
      <Title>Filters</Title>
      <Subtitle>
        Show articles that match with filter criteria.
        {/* Multiple filters will add up in effect. */}
      </Subtitle>
      <FilterAreaWrapper>
        <FilterCriterion options={options} />
      </FilterAreaWrapper>
      {/* <CustomButton width="88px" onClick={handleAddFilter}>
        <PlusIcon />
        New filter
      </CustomButton> */}
    </PopoverWrapper>
  );
});

export default FilterPopover;
