import React from 'react';
import { Link1Icon } from '@radix-ui/react-icons';
import CustomButton from '../../../../_reusable/CustomButton';
import { SelectValue } from '../../../../_reusable/CustomSelect';
import { INTERACT_COLOR } from '../../../../_reusable/theme';

const ArticleLinkButton = React.forwardRef(
  ({ value, option, options, ...props }, ref) => {
    const buttonStyle = (() => {
      if (![undefined, null, -1].includes(value)) {
        return {
          color: INTERACT_COLOR,
          background: 'rgba(46, 170, 220, 0.1)',
          hoverBackground: 'rgba(46, 170, 220, 0.2)',
          hoverColor: INTERACT_COLOR,
        };
      }
      return {};
    })();

    return (
      <CustomButton ref={ref} {...props} {...buttonStyle}>
        <Link1Icon />
        {option.label}
        {Boolean(value >= 0) ? (
          <>
            {': '}
            <SelectValue />
          </>
        ) : (
          <>
            {options.length ? (
              <>
                {': '}
                {options.length} available
              </>
            ) : null}
          </>
        )}
      </CustomButton>
    );
  }
);

export default ArticleLinkButton;
