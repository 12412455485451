import { get, post, put, remove } from './dataSource';

const URL_BASE_PHONEME = 'phoneme';

export const getPhoneme = async ({ tenantId }) => {
  return await get(URL_BASE_PHONEME, {
    tenant_id: tenantId,
  });
};

export const createPhoneme = async ({ userId, tenantId, data }) => {
  return await post(
    URL_BASE_PHONEME,
    data,
    {
      user_id: userId,
      tenant_id: tenantId,
    }
  );
};

export const updatePhoneme = async ({ userId, phonemeId, data }) => {
  return await put(
    `${URL_BASE_PHONEME}/${phonemeId}`,
    data,
    {
      user_id: userId,
    }
  );
};

export const deletePhoneme = async ({ phonemeId }) => {
  return await remove(`${URL_BASE_PHONEME}/${phonemeId}`, {}, {});
};