import React, { useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { COLOR } from '../../../_reusable/theme';
import RichText from './RichText';
import File from './File';
import CustomScrollArea from '../../../_reusable/CustomScrollArea';
import StandardText from './StandardText';
import AudioButton from './AudioButton';
import CustomTooltip from '../../../_reusable/CustomTooltip';

const EditAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const PropertiesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const PropertyRowLayoutWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const PropertyRowTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 14px;
  font-weight: 500;
  min-width: 200px;
  width: 200px;
  word-break: break-word;
  color: ${COLOR};
`;

const PropertyRowContentWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 16px;
`;

const Tooltip = styled.div`
border - radius: 4px;
padding: 12px;
background: rgba(0, 0, 0, 0.6);
color: white;
font - size: 12px;
font - weight: 500;
width: 240px;
`;

const PropertyRowLayout = ({ propertyKey, contentElement, media }) => {
  const title = (() => {
    switch (propertyKey) {
      case 'project_pic_url':
        return { text: 'Project Picture', tooltip: 'Picture that is shown as the background on the webapp project page.' };
      case 'project_name':
        return { text: 'Project Name', tooltip: 'The project\'s title shown on the top of the webapp project page.' };
      case 'project_description':
        return { text: 'Project Description', tooltip: 'The project\'s description shown (depending on settings) on the project page. An audio can be generated, that is shown (depening on settings) just underneath.' };
      case 'project_note':
        return { text: 'Project Detail Description', tooltip: 'A way to convey a second text next to the project\'s description, that is shown (depending on settings) below the description section. An audio can be generated, that is shown (depening on settings) just underneath.' };
      default:
        return { text: propertyKey, tooltip: 'No further description available. Contact support for more questions.' };
    }
  })();

  return (
    <PropertyRowLayoutWrapper>
      <PropertyRowTitle>
        <CustomTooltip
          triggerElement={<p>{title.text}</p>}
          contentElement={<Tooltip>{title.tooltip}</Tooltip>}
        />
        {media && <AudioButton media={media} />}
      </PropertyRowTitle>
      <PropertyRowContentWrapper>{contentElement}</PropertyRowContentWrapper>
    </PropertyRowLayoutWrapper>
  );
};

const EditArea = ({
  currentChanges,
  onChange,
  descriptionAudio,
  noteAudio,
}) => {
  const propertyElementFactory = useCallback((property) => {
    const [key, value] = property;
    switch (key) {
      case 'project_name':
        return (
          <PropertyRowLayout
            key={key}
            propertyKey={key}
            contentElement={
              <StandardText
                id={key}
                propertyKey={key}
                value={value}
                onChange={onChange}
              />
            }
          />
        );
      case 'project_description':
        return (
          <PropertyRowLayout
            key={property.recable_attribute_lang_name}
            propertyKey={key}
            media={descriptionAudio}
            contentElement={
              <RichText
                id={key}
                editorId={key}
                value={value}
                onChange={onChange}
              />
            }
          />
        );
      case 'project_note':
        return (
          <PropertyRowLayout
            key={property.recable_attribute_lang_name}
            propertyKey={key}
            media={noteAudio}
            contentElement={
              <RichText
                id={key}
                editorId={key}
                value={value}
                onChange={onChange}
              />
            }
          />
        );
      case 'project_pic_url':
        return (
          <PropertyRowLayout
            key={key}
            propertyKey={key}
            contentElement={
              <File
                id={key}
                propertyKey={key}
                value={value}
                onChange={onChange}
              />
            }
          />
        );
      default:
        console.error(`Undefined project property key: ${key}`);
        return null;
    }
  }, []);

  const entries = useMemo(
    () => Object.entries(currentChanges),
    [currentChanges]
  );

  const propertyComponents = useMemo(
    () =>
      entries.map((property) => (
        <React.Fragment key={property[0]}>
          {propertyElementFactory(property)}
        </React.Fragment>
      )),
    [currentChanges, propertyElementFactory]
  );

  return (
    <EditAreaWrapper>
      <CustomScrollArea>
        <PropertiesWrapper>{propertyComponents}</PropertiesWrapper>
      </CustomScrollArea>
    </EditAreaWrapper>
  );
};

export default EditArea;
