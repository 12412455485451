import React, { useContext } from 'react';
import styled from '@emotion/styled';
import {
  GlobalDispatchContext,
  GlobalStateContext,
  updateCurrentLangId,
} from '../../providers/GlobalProvider';
import CustomButton from '../_reusable/CustomButton';
import CustomSelect, {
  SelectIcon,
  SelectValue,
  SelectViewport,
  CustomSelectItem,
} from '../_reusable/CustomSelect';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { SHAIRE_COLOR } from '../_reusable/theme';

const SelectIconWrapper = styled.span`
  margin-left: auto;
`;

const TriggerElement = React.forwardRef(({ value, ...props }, ref) => {
  return (
    <CustomButton background="white" color={SHAIRE_COLOR} ref={ref} {...props}>
      <SelectValue />
      <SelectIcon asChild>
        <SelectIconWrapper>
          <ChevronDownIcon />
        </SelectIconWrapper>
      </SelectIcon>
    </CustomButton>
  );
});

const ContentElement = React.forwardRef(
  ({ availableLanguages, currentLangId, ...props }, ref) => {
    return (
      <SelectViewport ref={ref} {...props}>
        {availableLanguages.sort((a, b) => {return (a.lang_name_en > b.lang_name_en) ? 1 : -1;}).map((lang) => (
          <CustomSelectItem
            key={`${lang.lang_id}-${lang.lang_azure_abbrev}`}
            label={lang.lang_name_en}
            value={lang.lang_id}
          />
        ))}
      </SelectViewport>
    );
  }
);

const LanguageSelector = () => {
  const { availableLanguages, currentLangId } = useContext(GlobalStateContext);
  const globalDispatch = useContext(GlobalDispatchContext);
  const handleLangIdChange = (newLangId) => {
    newLangId !== currentLangId &&
      globalDispatch(updateCurrentLangId(newLangId));
  };

  return (
    <CustomSelect
      value={currentLangId}
      onSelect={handleLangIdChange}
      triggerElement={<TriggerElement />}
      contentElement={
        <ContentElement availableLanguages={availableLanguages.sort((lang0, lang1) => {return (lang0.lang_name_en < lang1.lang_name_en);})} />
      }
    />
  );
};

export default LanguageSelector;
